import React from 'react';
import { environment } from 'utils/environment';
import { UserInfo } from '@customer-frontend/auth';

import { menuRoutes } from '../constants';

import { ProfileIcon } from '../profile-icon';
import { KlaviyoSignupForm } from './klaviyo-signup-form';

import { ReactComponent as KinLogo } from 'assets/kin-logo-v2.svg';
import { ReactComponent as InstaIcon } from '../assets/insta-icon-white.svg';
import { ReactComponent as FacebookIcon } from '../assets/fb-icon-white.svg';
import { routes } from '../../../utils/routes';
import { Link } from 'react-router-dom';
export interface FooterViewProps {
  user?: UserInfo;
  isSignedUp: boolean;
  onKlaviyoSubmit: () => void;
}

export const FooterView = ({
  user,
  isSignedUp,
  onKlaviyoSubmit,
}: FooterViewProps): React.ReactElement => {
  return (
    <footer className="bg-primary-900 text-white py-10 px-6 md:py-16">
      <div className="flex flex-col md:px-8">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col md:pr-4 md:w-2/6 md:ml-auto">
            <Link
              className="block w-12"
              to={routes.profile}
              aria-label="Navigate home"
            >
              <KinLogo
                aria-label="Kin logo"
                className="fill-current text-white w-14"
              />
            </Link>
            <div className="mt-11 mr-auto mb-4">
              {isSignedUp ? (
                <p>Thanks for signing up!</p>
              ) : (
                <div className="flex flex-col space-y-4">
                  <p>Good info, in your inbox.</p>
                  <KlaviyoSignupForm
                    confirmOptIn
                    placeholder="Email"
                    buttonText="Subscribe"
                    klaviyoListId="LrWRHk"
                    trackingName="Signup"
                    onSuccess={onKlaviyoSubmit}
                    trackingData={{ lead_type: 'Newsletter' }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="md:w-3/5">
            <div className="flex flex-col md:flex-row w-3/4">
              <div className="w-1/2">
                <ul className="space-y-4">
                  {Object.keys(menuRoutes).map((menuRoute) => {
                    const link =
                      menuRoute.toLowerCase() === 'contraception'
                        ? `${environment.landingPageUrl}/the-pill`
                        : `${
                            environment.landingPageUrl
                          }/${menuRoute.toLowerCase()}`;
                    return (
                      <li key={menuRoute} className="my-2 pb-1 font-semibold">
                        <a className="hover:underline" href={link}>
                          {menuRoute}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="pt-4 md:pt-0 md:w-1/2">
                <ul className="space-y-4">
                  <li className="my-2 pb-1">
                    <a
                      className="hover:underline"
                      href={`${environment.landingPageUrl}/about`}
                    >
                      About
                    </a>
                  </li>
                  <li className="my-2 pb-1">
                    <a
                      className="hover:underline"
                      href={`${environment.landingPageUrl}/contact`}
                    >
                      Contact
                    </a>
                  </li>
                  <li className="my-2 pb-1">
                    <a
                      className="hover:underline"
                      href="https://help.kinfertility.com.au"
                    >
                      Help Center
                    </a>
                  </li>
                  <li className="flex md:w-24 pt-6">
                    {user?.firstName && <ProfileIcon variant="dark" />}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-6 md:flex-row md:mt-12 md:w-3/5 md:ml-auto">
          <div className="flex flex-col md:flex-row md:w-3/4">
            <ul className="w-1/2 space-y-4 py-4 text-sm">
              <li>
                <a href={`${environment.landingPageUrl}/privacy`}>
                  {' '}
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href={`${environment.landingPageUrl}/terms-and-conditions`}>
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
            <ul className="w-1/2 flex space-x-4 py-4">
              <li>
                <a
                  href="https://instagram.com/kin.fertility"
                  rel="noopener noreferrer"
                  aria-label="Follow us on Instagram"
                  target="_blank"
                >
                  <InstaIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://facebook.com/kinfertility"
                  rel="noopener noreferrer"
                  aria-label="Find us on Facebook"
                  target="_blank"
                >
                  <FacebookIcon />
                </a>
              </li>
            </ul>
          </div>
          <div className="w-1/4">
            <div className="mt-8 w-24">
              <a
                href="http://legitscript.com/pharmacy/kinfertility.com.au"
                target="_blank"
                title="Verify LegitScript Approval"
                rel="noreferrer"
              >
                <img
                  className="lazyload"
                  src="https://static.legitscript.com/seals/4598364.png"
                  alt="LegitScript approved"
                  width="140"
                  height="100"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
