import { StripeElementStyle } from '@stripe/stripe-js';

import {
  Maybe,
  PaymentGateway,
  BillingDetailsFragment,
} from '@customer-frontend/graphql-types';
import { Brand } from '@customer-frontend/types';
import { neutral900 } from '@eucalyptusvc/design-system/src/theme/pilot/palette';

export const getStripePaymentMethod = (
  methods: Maybe<
    Maybe<{
      cardMeta?: Maybe<{
        brand?: Maybe<string>;
        expiry?: Maybe<string>;
        id?: Maybe<string>;
        mask?: Maybe<string>;
      }>;
      gateway?: Maybe<PaymentGateway>;
    }>[]
  >,
): Maybe<{
  cardMeta?: Maybe<{
    brand?: Maybe<string>;
    expiry?: Maybe<string>;
    id?: Maybe<string>;
    mask?: Maybe<string>;
  }>;
  gateway?: Maybe<PaymentGateway>;
}> => {
  if (!methods) {
    return;
  }
  return methods.find((method) => method?.gateway === 'STRIPE');
};

export const getDefaultPaymentMethod = (
  methods: BillingDetailsFragment['savedPaymentMethods'],
): Maybe<
  NonNullable<BillingDetailsFragment['savedPaymentMethods']>[number]
> => {
  if (!methods) {
    return;
  }
  return methods.find((method) => method?.default);
};

const juniperStyles: StripeElementStyle = {
  base: {
    fontSize: '16px',
    color: '#133F26',
    fontWeight: '300',
    '::placeholder': {
      color: '#133F26',
    },
  },
  invalid: {
    color: '#BA0C0C',
    backgroundColor: '#FFEAEA',
  },
};

const pilotStyles: StripeElementStyle = {
  base: {
    fontSize: '16px',
    fontWeight: '300',
    '::placeholder': {
      color: '#9ca3af',
    },
  },
  invalid: {
    color: neutral900,
  },
};

const kinStyles: StripeElementStyle = {
  base: {
    fontSize: '16px',
    fontWeight: '300',
    '::placeholder': {
      color: '#9ca3af',
    },
  },
  invalid: {
    color: 'inherit',
  },
};

const defaultStyles: StripeElementStyle = {
  base: {
    fontSize: '17px',
    color: '#000',
    backgroundColor: '#FFF',
    '::placeholder': {
      color: '#cbd5e0',
    },
  },
  invalid: {
    color: '#c53030',
  },
};

export const getStripeElementStyle = (brand: Brand): StripeElementStyle => {
  switch (brand) {
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
      return juniperStyles;
    case 'pilot':
      return pilotStyles;
    case 'kin':
      return kinStyles;
    default:
      return defaultStyles;
  }
};
