import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Button,
  TextInput,
  Typography,
  Checkbox,
} from '@eucalyptusvc/design-system';
import { useLogin } from '@customer-frontend/services';
import {
  useEmailValidation,
  useRequiredValidation,
} from '@customer-frontend/utils';
import { LoginTemplateProps } from './types';
import { useAuth } from '@customer-frontend/auth';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

const fieldMessages = defineMessages({
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
});

export const LoginTemplate = ({
  title,
  buttonPalette,
  onComplete,
  consentRequired = false,
  linkToForgotPassword = '/forgot-password',
  linkToSignup = '/signup',
}: LoginTemplateProps): ReactElement => {
  const { login, loading } = useLogin();
  const { switchStorageType } = useAuth();
  const { formatMessage } = useIntl();
  const disableSignup = useFeatureFlagBoolean('DISABLE_SIGNUP');

  const { register, handleSubmit, errors } = useForm<{
    email: string;
    password: string;
    rememberMe: boolean;
  }>();

  const onSubmit = React.useMemo(
    () =>
      handleSubmit(async (data) => {
        try {
          if (consentRequired && !data.rememberMe) {
            switchStorageType('session_storage');
          }
          await login(data);
          onComplete();
        } catch {
          // error has been displayed already
        }
      }),
    [handleSubmit, onComplete, login, switchStorageType, consentRequired],
  );

  const emailValidation = {
    ...useEmailValidation(),
    ...useRequiredValidation(formatMessage(fieldMessages.email)),
  };
  const passwordValidation = useRequiredValidation(
    formatMessage(fieldMessages.password),
  );

  return (
    <div>
      {title && (
        <div className="text-center mb-2">
          <Typography size="xl" element="h1" isBold>
            {title}
          </Typography>
        </div>
      )}
      <form onSubmit={onSubmit} noValidate>
        <div className="mb-3 md:mb-6">
          <TextInput
            required
            autoFocus
            ref={register(emailValidation)}
            label={formatMessage(fieldMessages.email)}
            type="email"
            name="email"
            errorMessage={errors?.email?.message}
            data-cy="email-input"
            palette={buttonPalette}
          />
        </div>
        <div>
          <TextInput
            required
            ref={register(passwordValidation)}
            label={formatMessage(fieldMessages.password)}
            name="password"
            type="password"
            errorMessage={errors?.password?.message}
            data-cy="password-input"
            palette={buttonPalette}
          />
        </div>
        {consentRequired && (
          <div className="mt-6">
            <Checkbox
              name="rememberMe"
              ref={register}
              label={formatMessage({
                defaultMessage: 'Keep me logged in',
                description:
                  'Checkbox label for the browser to remember the user on next session',
              })}
              isChecked={false}
            />
          </div>
        )}
        <div className="mt-8">
          <Button
            level="primary"
            palette={buttonPalette}
            isLoading={loading}
            isDisabled={loading}
            data-cy="login"
            isFullWidth
            isSubmit
          >
            <FormattedMessage defaultMessage="Log in" />
          </Button>
        </div>
        <div className="mt-6 text-center space-y-2">
          <Typography size="paragraph">
            <Link className="text-link font-semibold" to={linkToForgotPassword}>
              <FormattedMessage defaultMessage="Forgot your password" />
            </Link>
          </Typography>
          {!disableSignup && (
            <Typography size="paragraph">
              <FormattedMessage
                defaultMessage="Don't have an account? <b>Sign up</b>"
                values={{
                  b: (chunks) => (
                    <Link className="text-link font-semibold" to={linkToSignup}>
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </Typography>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginTemplate;
