import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  MutationInitiateZipCheckoutArgs,
  ZipCheckoutResponse,
  MutationInitiateZipConsultationPaymentArgs,
} from '@customer-frontend/graphql-types';

const initiateZipCheckoutMutation = gql`
  mutation InitiateZipCheckout(
    $redirectUrl: String!
    $address: AddressCreateWithoutUserInput!
    $orderId: String!
    $couponCode: String
    $phone: String
  ) {
    initiateZipCheckout(
      redirectUrl: $redirectUrl
      address: $address
      orderId: $orderId
      couponCode: $couponCode
      phone: $phone
    ) {
      redirectUrl
    }
  }
`;

const InitiateZipConsultationPaymentMutation = gql`
  mutation InitiateZipConsultationPayment(
    $consultationId: String!
    $redirectUrl: String!
    $couponCode: String
  ) {
    initiateZipConsultationPayment(
      consultationId: $consultationId
      redirectUrl: $redirectUrl
      couponCode: $couponCode
    ) {
      redirectUrl
    }
  }
`;

export function useZipCheckout(): MutationTuple<
  {
    initiateZipCheckout: ZipCheckoutResponse;
  },
  MutationInitiateZipCheckoutArgs
> {
  return useMutation<
    {
      initiateZipCheckout: ZipCheckoutResponse;
    },
    MutationInitiateZipCheckoutArgs
  >(initiateZipCheckoutMutation);
}

export function useZipConsultationPayment(): MutationTuple<
  {
    initiateZipConsultationPayment: ZipCheckoutResponse;
  },
  MutationInitiateZipConsultationPaymentArgs
> {
  return useMutation<
    {
      initiateZipConsultationPayment: ZipCheckoutResponse;
    },
    MutationInitiateZipConsultationPaymentArgs
  >(InitiateZipConsultationPaymentMutation);
}
