import { sharedColors } from '../shared/palette';

let theme;

switch (process.env.REACT_APP_BRAND) {
  case 'juniper-jp':
    theme = {
      primary100: '#F1F6E8',
      primary200: '#CBDAAC',
      primary300: '#A7C1A0',
      primary400: '#81996e',
      primary500: '#133F26',
      lime100: '#E7FCAA',
      lime200: '#C5FF47',
      lime300: '#99E000',
      purple100: '#ECEBFF',
      purple200: '#C6C2FF',
      purple300: '#8279FF',
      cream: '#fdfcf4',
    };
    break;
  default:
    theme = {
      primary100: '#F1F5E9',
      primary200: '#D0D8BC',
      primary300: '#ABBAA7',
      primary400: '#829473',
      primary500: '#133F26',
      lime100: '#f2ffca',
      lime200: '#dff49f',
      lime300: '#c6db89',
      purple100: '#E4DCEB',
      purple200: '#D5BBE9',
      purple300: '#BA82E6',
      cream: '#FDFCF4',
    };
}

export const {
  primary100,
  primary200,
  primary300,
  primary400,
  primary500,
  lime100,
  lime200,
  lime300,
  purple100,
  purple200,
  purple300,
  cream,
} = theme;

// secondary (Juniper AU only)
export const burgundy100 = '#C77A7A';
export const burgundy200 = '#8E1948';
export const burgundy300 = '#671435';

// tertiary
export const olive = '#7A7F2F';

export const colors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
  },
  secondary: {
    lime: {
      100: lime100,
      200: lime200,
      300: lime300,
    },
    purple: {
      100: purple100,
      200: purple200,
      300: purple300,
    },
    burgundy: {
      100: burgundy100,
      200: burgundy200,
      300: burgundy300,
    },
  },
  tertiary: {
    cream,
    olive,
  },
  reserved: {},
};
