import { createLogger } from '@customer-frontend/logger';
import { getConfig } from '@customer-frontend/config';
import { environment } from 'utils/environment';
import { ApolloError } from '@apollo/client';

const config = getConfig();

export const logger = createLogger({
  appEnv: environment.appEnv,
  brand: config.brand,
  datadogToken: environment.datadogToken,
});

export const onError = (e: ApolloError): void => {
  if (!environment.isProd) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
