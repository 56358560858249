import { useState } from 'react';
import {
  useForm,
  DeepMap,
  FieldError,
  UseFormMethods,
  FormState,
  Control,
} from 'react-hook-form';
import { notificationService } from '@customer-frontend/notifications';
import { useAddPatientQuestionToConsultationMutation } from '@customer-frontend/graphql-types';

export interface AddPatientQuestionFormState {
  question: string;
}

interface AddPatientQuestionFormReturn {
  loading: boolean;
  submit: () => Promise<void>;
  register: UseFormMethods['register'];
  errors: DeepMap<AddPatientQuestionFormState, FieldError>;
  formState: FormState<AddPatientQuestionFormState>;
  control: Control<AddPatientQuestionFormState>;
}

export const useAddPatientQuestion = (
  consultationId: string,
  onSubmit: () => Promise<unknown>,
): AddPatientQuestionFormReturn => {
  const [loading, setLoading] = useState<boolean>(false);

  const [addPatientQuestionToConsultationMutation] =
    useAddPatientQuestionToConsultationMutation();

  const form = useForm<AddPatientQuestionFormState>();

  const submit = form.handleSubmit(async ({ question }) => {
    try {
      setLoading(true);
      await addPatientQuestionToConsultationMutation({
        variables: {
          consultationId: consultationId,
          question,
          trigger: 'open message',
        },
      });
      await onSubmit();

      form.reset();

      notificationService.show({
        type: 'success',
        message: 'Message sent',
      });
    } catch (err) {
      notificationService.show({
        type: 'error',
        message: 'Unable to send message.',
      });
      throw new Error('Unable to send message');
    } finally {
      setLoading(false);
    }
  });

  return { loading, submit, ...form };
};
