import { useDiscountCodeLazyQuery } from '@customer-frontend/graphql-types';
import { isDiscountValid } from '@customer-frontend/utils';
import { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useEffectOnce } from 'react-use';
import { isRecurringDiscountCode } from '../../logic/discounts';
import {
  UseDiscountCodeFormParams,
  UseDiscountCodeFormResponse,
} from './types';

export const useDiscountCodeForm = ({
  code,
  stage,
  onChange,
  defaultCode,
  products,
  skipProductValidation = false,
}: UseDiscountCodeFormParams): UseDiscountCodeFormResponse => {
  const [inputText, setInputText] = useState<string>(defaultCode ?? '');
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const { formatMessage } = useIntl();

  const [validateDiscountCode, { loading: validateDiscountCodeLoading }] =
    useDiscountCodeLazyQuery({
      onCompleted: (data) => {
        if (
          data.discountCode &&
          isDiscountValid(
            data.discountCode,
            stage,
            products,
            skipProductValidation && data.discountCode.products.length === 1,
          )
        ) {
          onChange(data.discountCode);
        } else {
          setErrorMessage(
            formatMessage({
              defaultMessage: 'Invalid discount code. Please try again.',
            }),
          );
        }
      },
      onError: () => {
        setErrorMessage(
          formatMessage({
            defaultMessage: 'Invalid discount code. Please try again.',
          }),
        );
        onChange();
      },
      fetchPolicy: 'no-cache',
    });

  const handleDiscountApply = useCallback((): Promise<void> | void => {
    if (inputText.length <= 0) {
      return;
    }

    if (code && inputText !== code?.code) {
      onChange();
      setErrorMessage('');
    }

    validateDiscountCode({
      variables: {
        code: inputText,
        checkoutStage: stage,
        productIds: products.map((p) => p.id),
        skipProductValidation,
      },
    });
  }, [
    inputText,
    code,
    validateDiscountCode,
    stage,
    products,
    skipProductValidation,
    onChange,
  ]);

  const handleInputPress: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleDiscountApply();
        }
      },
      [handleDiscountApply],
    );

  const handleDiscountChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event): void => {
      setInputText(event.target.value);
      setErrorMessage(null);
    }, []);

  useEffectOnce(() => {
    if (!defaultCode?.length) {
      return;
    }

    setInputText(defaultCode);
    handleDiscountApply();
  });

  const isRecurringDiscount = code && isRecurringDiscountCode(code);

  return useMemo(
    () => ({
      inputText,
      setInputText,
      errorMessage,
      setErrorMessage,
      validateDiscountCodeLoading,
      handleDiscountApply,
      handleInputPress,
      handleDiscountChange,
      isRecurringDiscount,
    }),
    [
      inputText,
      setInputText,
      errorMessage,
      setErrorMessage,
      validateDiscountCodeLoading,
      handleDiscountApply,
      handleInputPress,
      handleDiscountChange,
      isRecurringDiscount,
    ],
  );
};
