
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ChatThreadParticipant": [
      "CustomerChatThreadParticipant",
      "HealthCoachChatThreadParticipant"
    ],
    "ConsultationPrescriberAction": [
      "ConsultationPrescriberContinueExistingSequenceAction",
      "ConsultationPrescriberDismissAction",
      "ConsultationPrescriberPrescribeInitialSequenceAction",
      "ConsultationPrescriberPrescribeNewSequenceAction",
      "ConsultationPrescriberRejectAction",
      "ConsultationPrescriberReprescribeExistingSequenceAction"
    ],
    "ContentItem": [
      "FaqContentItem",
      "ReadingContentItem",
      "RecipeContentItem",
      "VideoContentItem",
      "VideoWithReadingContentItem"
    ],
    "CreateWearableIntegrationPayload": [
      "CreateNativeWearableIntegrationPayload",
      "CreateWebApiWearableIntegrationPayload"
    ],
    "HeartRateVariabilitySample": [
      "RMSSDHeartRateVariabilitySample",
      "SDNNHeartRateVariabilitySample"
    ],
    "LevelTreeGoalTemplate": [
      "DefaultLevelTreeGoalTemplate",
      "VariantLevelTreeGoalTemplate"
    ],
    "MobileAppFeature": [
      "HealthCoachChat"
    ],
    "Note": [
      "ConsultationNote",
      "CustomerNote",
      "MetaNote"
    ],
    "Offering": [
      "AddOnOffering",
      "OtcOffering",
      "RxOffering"
    ],
    "PaginationPayload": [
      "FilteredChatThreadsPayload"
    ],
    "PhoneCallProviderDetails": [
      "LinePhoneCallDetails",
      "ZoomPhoneCallDetails"
    ],
    "PlugOffering": [
      "OtcOffering"
    ],
    "PrescribeDisclaimer": [
      "GeneralPrescribeDisclaimer"
    ],
    "PurchaseAction": [
      "PurchaseResumeAction"
    ],
    "PurchasePrompt": [
      "ConfirmPurchasePrompt",
      "FurPurchasePrompt",
      "InitialPurchasePrompt",
      "RestartFlowPrompt",
      "SubstitutePurchasePrompt"
    ],
    "PurchaseSyncGroupAction": [
      "DelayPurchaseSyncGroupAction"
    ],
    "QuizOutcomeV2": [
      "ArticleQuizOutcome",
      "BuyOtcQuizOutcome",
      "ConsultationQuizOutcome",
      "IncreaseUsageQuizOutcome",
      "NotEligibleQuizOutcome",
      "OtcQuizOutcome",
      "ReviewQuizOutcome",
      "SideEffectProactiveActionQuizOutcome",
      "ZendeskQuizOutcome"
    ],
    "QuizOutcomeV2Result": [
      "ArticleQuizOutcome",
      "BuyOtcQuizOutcome",
      "ConsultationQuizOutcome",
      "IncreaseUsageQuizOutcome",
      "NotEligibleQuizOutcome",
      "OtcQuizOutcome",
      "ReviewQuizOutcome",
      "SideEffectProactiveActionQuizOutcome",
      "ZendeskQuizOutcome"
    ],
    "SavedPaymentMethod": [
      "SavedCardPaymentMethod",
      "SavedPayPalPaymentMethod",
      "SavedSofortPaymentMethod",
      "SavedZipPaymentMethod"
    ],
    "Sequence": [
      "OtcAddOnSequence",
      "OtcSequence",
      "PrescribableSequence"
    ],
    "SequenceNode": [
      "EventSequenceNode",
      "InternalTriggerSequenceNode",
      "SmsSequenceNode",
      "VariantSequenceNode",
      "WaitSequenceNode"
    ],
    "ShopSection": [
      "ItemListShopSection"
    ]
  }
};
      export default result;
    