import {
  useMinLengthValidation,
  useRequiredValidation,
} from '@customer-frontend/utils';
import {
  Avatar,
  Button,
  LinkButton,
  TextArea,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { addPatientQuestionToConsultationMutation } from '@customer-frontend/services';
import { Maybe } from '@customer-frontend/graphql-types';

type FormFields = { faqDoctorQuestion: string };

export type FloatingMessageFormProps = {
  consultationId: string;
  onHideClick: () => void;
  doctor: {
    shortClinicianName: string;
    avatar?: Maybe<{
      url: string;
    }>;
  };
  onSubmit: () => void;
};

export const FloatingMessageForm: FunctionComponent<
  FloatingMessageFormProps
> = ({ consultationId, onHideClick, doctor, onSubmit }) => {
  const { register, handleSubmit, errors } = useForm<FormFields>();

  const notify = useNotification();
  const { formatMessage } = useIntl();

  const questionValidationMessage = formatMessage({
    defaultMessage: 'Question or message',
    description: 'Label for the question field when sending a doctor a message',
  });
  const questionValidation = {
    ...useRequiredValidation(questionValidationMessage),
    ...useMinLengthValidation(questionValidationMessage, 8),
  };

  const [createQuestion, { loading }] = useMutation(
    addPatientQuestionToConsultationMutation,
    {
      onCompleted: () => {
        notify.success({
          message: formatMessage({
            defaultMessage:
              'Thanks! Your practitioner will answer your question shortly.',
            description:
              'Message shown after successfully sending a doctor a message',
          }),
        });

        onSubmit();
      },

      onError: () => {
        notify.error({
          message: formatMessage({
            defaultMessage:
              'We couldn’t send your message to the practitioner. Please try again.',
            description:
              'Error message indicating that a form could not be submitted',
          }),
        });
      },
    },
  );

  const submitQuestion = async (data: FormFields): Promise<void> => {
    await createQuestion({
      variables: {
        question: data.faqDoctorQuestion,
        consultationId,
        trigger: 'question',
      },
    });
  };

  return (
    <div className="flex flex-col p-3 bg-primary-100 rounded text-neutral-black max-w-xs shadow-2xl">
      <div className="flex mb-2 justify-between">
        <div className="flex items-center">
          <span className="mr-3.5 ml-0.5 mb-2">
            <Avatar src={doctor.avatar?.url} size="sm" />
          </span>

          {doctor.shortClinicianName && (
            <Typography size="paragraph" isBold>
              <FormattedMessage
                defaultMessage="Ask {doctorName}"
                description="Heading on the form used to message a doctor"
                values={{ doctorName: doctor.shortClinicianName }}
              />
            </Typography>
          )}
        </div>

        <LinkButton onClick={onHideClick}>
          <FormattedMessage
            defaultMessage="Hide"
            description="Link to hide the form to message a doctor"
          />
        </LinkButton>
      </div>

      <form className="flex flex-col" onSubmit={handleSubmit(submitQuestion)}>
        <Typography size="small-text">
          <FormattedMessage
            defaultMessage="* Please read through your treatment plan before asking a question."
            description="Disclaimer on the form used to message a doctor"
          />
        </Typography>

        <div className="pb-1 mt-4">
          <TextArea
            name="faqDoctorQuestion"
            ref={register(questionValidation)}
            errorMessage={errors?.faqDoctorQuestion?.message}
            placeholder={'Ask a question'}
          />

          <div className="flex justify-end mt-2">
            <Button isLoading={loading} isSubmit>
              <FormattedMessage
                defaultMessage="Send"
                description="Submit button on the form used to message a doctor"
              />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
