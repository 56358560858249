import { useNotification, LoadingSpinner } from '@eucalyptusvc/design-system';
import { gql, useQuery } from '@apollo/client';
import { useStripe } from '@stripe/react-stripe-js';
import { useUrlQuery, purgeUrlParams } from '@customer-frontend/utils';
import React, { useCallback, useEffect } from 'react';
import {
  usePayConsultation,
  StripeProvider,
} from '@customer-frontend/services';
import {
  IConsultationPaidEvent,
  useEventService,
} from '@customer-frontend/events';
import { getConfig } from '@customer-frontend/config';
import { convertCurrencyBaseUnit } from '@customer-frontend/order';
import {
  ConsultStripePaymentResultHandlerQuery,
  ConsultStripePaymentResultHandlerQueryVariables,
} from '@customer-frontend/graphql-types';
import { defineMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import type { Logger } from '@customer-frontend/logger';

const paidConsultations = new Set<string>();

type ConsultStripePaymentResultHandlerProps = {
  consultationId: string;
  onComplete: () => void;
  onError: (couponCode: string | null) => void;
  logger: Logger;
};

export function ConsultStripePaymentResultHandlerWithProvider({
  consultationId,
  onComplete,
  onError,
  logger,
}: ConsultStripePaymentResultHandlerProps): React.ReactElement {
  const handleComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const handleError = useCallback(
    (couponCode: string | null) => {
      onError(couponCode);
    },
    [onError],
  );

  return (
    <StripeProvider stripeSdkOnly api="paymentIntents" logger={logger}>
      <ConsultStripePaymentResultHandler
        consultationId={consultationId}
        onComplete={handleComplete}
        onError={handleError}
        logger={logger}
      />
    </StripeProvider>
  );
}

function ConsultStripePaymentResultHandler({
  consultationId,
  onComplete,
  onError,
  logger,
}: ConsultStripePaymentResultHandlerProps): React.ReactElement {
  const config = getConfig();
  const notification = useNotification();
  const { formatMessage } = useIntl();
  const stripe = useStripe();
  const query = useUrlQuery();
  const clientSecret = query.get('setup_intent_client_secret');
  const couponCode = query.get('couponCode');
  const redirectStatus = query.get('redirect_status');
  const [payConsultation] = usePayConsultation();
  const event = useEventService();

  const handleComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const handleError = useCallback(
    (couponCode: string | null) => {
      onError(couponCode);
    },
    [onError],
  );

  const { data: consultationData, loading: consultationDataLoading } = useQuery<
    ConsultStripePaymentResultHandlerQuery,
    ConsultStripePaymentResultHandlerQueryVariables
  >(
    gql`
      query ConsultStripePaymentResultHandler($consultationId: String!) {
        consultation(id: $consultationId) {
          id
          type
          price {
            total
          }
          customer {
            id
            firstName
            lastName
            phone
          }
        }
      }
    `,
    { variables: { consultationId } },
  );

  useEffect(() => {
    (async () => {
      if (consultationDataLoading) {
        return;
      }

      if (paidConsultations.has(consultationId)) {
        handleComplete();
        return;
      }

      if (redirectStatus === 'failed') {
        notification.error({
          message: formatMessage({
            defaultMessage:
              'Failed to confirm your order payment. Please try again',
          }),
          duration: 3000,
        });
        handleError(null);
        return;
      }

      if (stripe && clientSecret && consultationData) {
        purgeUrlParams();
        try {
          const data = await stripe.retrieveSetupIntent(clientSecret);
          const { consultation } = consultationData;
          if (data && data.setupIntent && consultation) {
            const paymentMethod = data.setupIntent.payment_method;
            if (paymentMethod) {
              await payConsultation({
                variables: {
                  id: consultationId,
                  stripePaymentMethodId: `${paymentMethod}`,
                  couponCode,
                },
              });
              paidConsultations.add(consultationId);
              if (consultation.customer.id && consultation.price) {
                const consultationPaidEvent: IConsultationPaidEvent = {
                  currency: config.currency,
                  consultationId,
                  country: config.countryCode,
                  firstName: consultation.customer.firstName,
                  lastName: consultation.customer.lastName,
                  phoneNumber: consultation.customer.phone,
                  problemType: consultation.type,
                  userId: consultation.customer.id,
                  value: convertCurrencyBaseUnit(consultation.price.total),
                };
                event.consultation.paid(consultationPaidEvent);
              }
              notification.success({
                message: formatMessage({
                  defaultMessage: 'Your payment has been made successfully!',
                  description: 'Success message for successful payment',
                }),
              });
              handleComplete();
            }
          }
        } catch (e) {
          logger.error('Failed to handle stripe return page for customer', {
            error: e,
          });
          const descriptions = getErrorMessageDescriptorsFromError(
            e,
            defineMessage({
              defaultMessage:
                'Failed to confirm your consultation payment. Please refresh your page and try again',
              description: 'Error message for when payment has failed',
            }),
          );
          descriptions.forEach((descriptor) =>
            notification.error({
              message: formatMessage(descriptor),
              duration: 10000,
            }),
          );
          handleError(couponCode);
        }
      }
    })();
  }, [
    stripe,
    notification,
    clientSecret,
    payConsultation,
    consultationId,
    couponCode,
    formatMessage,
    consultationDataLoading,
    consultationData,
    config.currency,
    config.countryCode,
    event.consultation,
    logger,
    handleComplete,
    handleError,
    redirectStatus,
  ]);
  return (
    <div className="flex justify-center mt-6">
      <LoadingSpinner />
    </div>
  );
}
