import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { addDays, addWeeks, format, isAfter, isBefore } from 'date-fns';
import {
  Button,
  Modal,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { gql, useMutation } from '@apollo/client';
import {
  DelayPurchaseSyncGroupMutation,
  DelayPurchaseSyncGroupMutationVariables,
} from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';

const delayPurchaseSyncGroupMutation = gql`
  mutation DelayPurchaseSyncGroup($input: DelayPurchaseSyncGroupInput!) {
    delayPurchaseSyncGroup(input: $input) {
      purchaseSyncGroup {
        id
        status
        sequenceContexts {
          id
          status
        }
      }
    }
  }
`;

type DelayModalProps = {
  onClose: () => void;
  upcomingOrder: { date: string; syncGroupId: string };
  onDelaySuccess: () => void;
};

export const DelayModal = ({
  onClose,
  upcomingOrder,
  onDelaySuccess,
}: DelayModalProps): React.ReactElement => {
  const config = getConfig();
  const { formatMessage } = useIntl();
  const notify = useNotification();

  const [delayPurchaseSyncGroup, { loading: delayPurchaseSyncGroupLoading }] =
    useMutation<
      DelayPurchaseSyncGroupMutation,
      DelayPurchaseSyncGroupMutationVariables
    >(delayPurchaseSyncGroupMutation, {
      context: {
        skipErrorNotification: true,
      },
    });

  const minDate = format(
    addDays(new Date(upcomingOrder.date), 1),
    'yyyy-MM-dd',
  );
  const maxDate = format(
    addWeeks(new Date(upcomingOrder.date), 2),
    'yyyy-MM-dd',
  );

  const [dateToDelayTo, setDateToDelayTo] = useState<string>(minDate);

  const isInvalidDateToDelayTo =
    isNaN(new Date(dateToDelayTo).getTime()) ||
    isBefore(new Date(dateToDelayTo), new Date(minDate)) ||
    isAfter(new Date(dateToDelayTo), new Date(maxDate));

  return (
    <Modal
      isOpen
      onClose={onClose}
      title={
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Delay your next order"
            description="Modal title for delaying upcoming order from plan"
          />
        </Typography>
      }
    >
      <div className="space-y-4">
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="You can delay your next order for up to two weeks."
            description="Text on delay next order modal explaining the maximum amount of time an order can be delayed by"
          />
        </Typography>

        <div className="p-4 bg-primary-100 rounded">
          <Typography size="paragraph">
            <FormattedMessage
              defaultMessage="<strong>Please note:</strong> Delaying changes the date of your next order and all subsequent orders."
              description="Text on delay next order modal warning about the side effects of delaying the next order"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </div>

        <input
          type="date"
          min={minDate}
          max={maxDate}
          value={dateToDelayTo}
          onChange={(e) => setDateToDelayTo(e.target.value)}
          className="border rounded p-2"
        />

        {isInvalidDateToDelayTo && (
          <Typography size="paragraph" color="red">
            <FormattedMessage
              defaultMessage="Please select a date between {minDate} and {maxDate}."
              description="validation message for delay upcoming order"
              values={{
                minDate: formatDate(
                  mapBrandToAdaptersBrand(config.brand),
                  minDate,
                  {
                    dateStyle: 'medium',
                  },
                ),
                maxDate: formatDate(
                  mapBrandToAdaptersBrand(config.brand),
                  maxDate,
                  { dateStyle: 'medium' },
                ),
              }}
            />
          </Typography>
        )}

        <Button
          isSubmit
          isFullWidth
          isDisabled={isInvalidDateToDelayTo}
          isLoading={delayPurchaseSyncGroupLoading}
          onClick={async () => {
            try {
              await delayPurchaseSyncGroup({
                variables: {
                  input: {
                    purchaseSyncGroupId: upcomingOrder.syncGroupId,
                    targetTime: new Date(dateToDelayTo),
                  },
                },
              });
              notify.success({
                message: formatMessage({
                  defaultMessage: 'Your upcoming order has been delayed',
                  description:
                    'Success message after the user delays their upcoming order',
                }),
              });
              onDelaySuccess();
              onClose();
            } catch {
              notify.error({
                message: formatMessage({
                  defaultMessage:
                    'Your request to delay your upcoming order has failed. Please try again.',
                  description:
                    'Error message when user attempts to delay their upcoming order and fails',
                }),
              });
            }
          }}
        >
          <FormattedMessage
            defaultMessage="Confirm"
            description="Button text to confirm delaying upcoming order"
          />
        </Button>
      </div>
    </Modal>
  );
};
