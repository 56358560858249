import sharedColors from '../shared/palette';

// primary
export const primary100 = '#E8F1FA';
export const primary200 = '#BAD5F0';
export const primary300 = '#75ACE2';
export const primary400 = '#1974CD';
export const primary500 = '#0D5194';
export const primary600 = '#0B345D';

// secondary
export const sky100 = '#E3F7FA';
export const sky200 = '#ABE8EF';
export const sky300 = '#84EDFB';
export const navy100 = '#D1D9DE';
export const navy200 = '#768E9B';
export const navy300 = '#1A4359';
export const cream100 = '#FBF9F3';
export const cream200 = '#F1ECDF';
export const cream300 = '#E5DECC';

// tertiary
export const cloudBlue = '#E7F9FB';
export const neutral900 = '#1a1a1a';

// reserved (for conditions/products/etc)
export const ed = '#A5CA97';
export const skin = '#FDEBAC';
export const pe = '#F5BE8B';
export const mental = '#8BBFB3';
export const sleep = '#BABCED';
export const weight = '#D7EA92';
export const hair = '#EC9A88';
export const herpes = '#D5B8E2';

export const brandColors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
    600: primary600,
  },
  secondary: {
    sky: {
      100: sky100,
      200: sky200,
      300: sky300,
    },
    navy: {
      100: navy100,
      200: navy200,
      300: navy300,
    },
    cream: {
      100: cream100,
      200: cream200,
      300: cream300,
    },
  },
  tertiary: {
    cloudBlue,
  },
  reserved: {
    ed,
    skin,
    pe,
    mental,
    sleep,
    weight,
    hair,
    herpes,
  },
};

export default brandColors;
