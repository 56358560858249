import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  AccordionPalette,
  Button,
  ButtonPalette,
  Card,
  CardPalette,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { UpfrontPaymentConsultPaymentForm } from './payment-form';
import { UpfrontConsultPayFAQs } from './faqs';
import {
  UpfrontConsultPayPageQuery,
  UpfrontConsultPayPageQueryVariables,
} from '@customer-frontend/graphql-types';
import {
  getMedicationPreferenceProduct,
  useShowZendeskWidget,
} from '../../utils';
import { TreatmentDetails } from './treatment-details';
import {
  getConfig,
  useConsultationFlowConfig,
} from '@customer-frontend/config';
import { isSyncConsultation } from '@customer-frontend/consultation';
import { ReactComponent as Dollar } from '../../assets/icons/dollar.svg';
import { ReactComponent as Stop } from '../../assets/icons/stop.svg';
import { ReactComponent as ThirtyDay } from '../../assets/icons/30-day-money-back.svg';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';

const UPFRONT_PAYMENT_CONSULT_PAGE_QUERY = gql`
  fragment UpfrontPaymentConsultProduct on Product {
    id
    name
    friendlyName
    erxMedicines {
      id
      genericName
    }
    faqs {
      id
    }
    variants {
      id
      price
      isRefill
      inventory {
        id
        sku
      }
    }
    photo {
      id
      url
    }
    plan {
      id
      name
      interval
      intervalCount
    }
  }
  query UpfrontConsultPayPage($consultationId: String!) {
    profile {
      id
      firstName
      lastName
      legalFirstName
      legalLastName
      email
      phone
      address {
        id
        line1
        line2
        city
        postalCode
        state
        deliveryInstructions
      }
      defaultPaymentGateway
      paymentMethods {
        id
        cardMeta {
          brand
          expiry
          id
          mask
        }
        gateway
      }
      zip {
        valid
      }
    }
    consultation(id: $consultationId) {
      id
      type
      order {
        id
      }
      isUpfrontPayment
      requiresPrescriberCall
      medicationPreferenceProduct {
        id
        preferredProduct {
          ...UpfrontPaymentConsultProduct
        }
        defaultProduct {
          ...UpfrontPaymentConsultProduct
        }
      }
    }
  }
`;

interface UpfrontConsultPayPageProps {
  onPaymentSuccess: () => void;
  consultationId: string;
  styles?: {
    faqAccordionPalette?: AccordionPalette;
    messageUsButtonPalette?: ButtonPalette;
    treatmentCardPalette?: CardPalette;
    paymentIconColor: string;
    messageUsCardPalette?: CardPalette;
  };
  paymentIntentReturnUrl?: string;
}

export const UpfrontConsultPayPage: React.FC<UpfrontConsultPayPageProps> = ({
  consultationId,
  onPaymentSuccess,
  styles,
  paymentIntentReturnUrl,
}) => {
  const config = getConfig();
  const { data, loading } = useQuery<
    UpfrontConsultPayPageQuery,
    UpfrontConsultPayPageQueryVariables
  >(UPFRONT_PAYMENT_CONSULT_PAGE_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      consultationId,
    },
  });

  const consultationConfig = useConsultationFlowConfig(
    data?.consultation?.type,
  );

  const isSyncConsult = isSyncConsultation(
    consultationConfig?.isSyncExperience,
    data?.consultation?.requiresPrescriberCall,
  );

  useShowZendeskWidget({ problemType: data?.consultation?.type });

  const productBeingPurchased = getMedicationPreferenceProduct(
    data?.consultation?.medicationPreferenceProduct,
  );

  const ffClient = useFeatureFlagClient();
  const lowDoseMedicationShortageSku = ffClient.getCustom<string[]>(
    'medication_shortage_new_skus',
    {
      defaultValue: [],
    },
  );

  const showLowDosageFaq = productBeingPurchased?.variants.some(
    ({ inventory }) =>
      inventory?.sku && lowDoseMedicationShortageSku.includes(inventory.sku),
  );

  if (
    !data?.profile ||
    !data?.consultation ||
    !productBeingPurchased ||
    loading
  ) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 max-w-xl mx-auto py-10 md:py-12 px-5">
      <Typography size="lg" isBold>
        <FormattedMessage defaultMessage="Complete your payment" />
      </Typography>
      <TreatmentDetails
        medicationPreferenceProduct={productBeingPurchased}
        palette={styles?.treatmentCardPalette}
        isMultipleMedication={consultationConfig?.isMultipleMedication}
        problemType={data.consultation.type}
        isSyncConsult={isSyncConsult}
        isUpfrontPayment={data.consultation.isUpfrontPayment}
      />
      <div className="flex text-center justify-between">
        <div className="flex flex-col items-center space-y-2">
          <Dollar stroke={styles?.paymentIconColor} />
          <Typography size="paragraph">
            <FormattedMessage defaultMessage="Full refund if unsuitable" />
          </Typography>
        </div>

        <div className="flex flex-col items-center space-y-2">
          <Stop stroke={styles?.paymentIconColor} />
          <Typography size="paragraph">
            <FormattedMessage defaultMessage="No lock in contract" />
          </Typography>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <ThirtyDay stroke={styles?.paymentIconColor} />
          <Typography size="paragraph">
            <FormattedMessage defaultMessage="30-day money-back guarantee" />
          </Typography>
        </div>
      </div>
      <UpfrontPaymentConsultPaymentForm
        consultation={data.consultation}
        user={data.profile}
        // TODO: WEB-1483 check if product requires cold storage
        validateShippingAddress={
          config.coldStorageAddressValidation &&
          data?.consultation?.type === 'WEIGHT_LOSS'
        }
        onPaymentSuccess={onPaymentSuccess}
        paymentIntentReturnUrl={paymentIntentReturnUrl}
      />
      <div className="flex flex-col space-y-4 mt-2">
        <Typography isBold size="md">
          <FormattedMessage defaultMessage="FAQs" />
        </Typography>
        <UpfrontConsultPayFAQs
          palette={styles?.faqAccordionPalette}
          showLowDosageFaq={showLowDosageFaq}
        />
      </div>
      <Card palette={styles?.messageUsCardPalette}>
        <div className="flex flex-col space-y-6">
          <div className="flex items-center space-x-4">
            {config.localisedAssets?.zendeskMessageUsLogo && (
              <img src={config.localisedAssets?.zendeskMessageUsLogo} />
            )}
            <Typography isBold size="md">
              <FormattedMessage
                defaultMessage="Still have questions?"
                description="upfront payment screen to open zendesk"
              />
            </Typography>
          </div>

          <Button
            onClick={() => window?.zE?.('messenger', 'open')}
            isFullWidth
            palette={styles?.messageUsButtonPalette}
          >
            <FormattedMessage defaultMessage="Message us" />
          </Button>
        </div>
      </Card>
    </div>
  );
};
