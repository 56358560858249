import { gql } from '@apollo/client';

export const userInfoFragment = gql`
  fragment UserInfo on User {
    id
    email
    firstName
    lastName
    shortAddressableName
    fullName
    phone
    role
    isPasswordSet
    defaultPaymentGateway
    birthday
    preferredUnitSystem
    residentialAddress {
      id
      line1
      line2
      city
      postalCode
      state
      country
      company
      building
    }
    address {
      id
      line1
      line2
      city
      postalCode
      state
      country
      company
      building
    }
    paymentMethods {
      id
      gateway
      cardMeta {
        id
        brand
        mask
        expiry
      }
    }
    zip {
      valid
    }
  }
`;
