import { Brand as AdaptersBrand } from '@eucalyptusvc/lib-adapters';

export type Brand =
  | 'kin'
  | 'pilot'
  | 'software'
  | 'juniper'
  | 'juniper-uk'
  | 'juniper-de'
  | 'juniper-jp';

export const mapBrandToAdaptersBrand = (brand: Brand): AdaptersBrand => {
  switch (brand) {
    case 'pilot':
      return 'PILOT';
    case 'kin':
      return 'KIN';
    case 'software':
      return 'SOFTWARE';
    case 'juniper':
      return 'JUNIPER';
    case 'juniper-uk':
      return 'JUNIPER_UK';
    case 'juniper-de':
      return 'JUNIPER_DE';
    case 'juniper-jp':
      return 'JUNIPER_JP';
    default:
      throw new Error(`unable to convert brand value ${brand}`);
  }
};
