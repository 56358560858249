export const isPng = (fileName: string): boolean => {
  return fileName.split('.').pop() === 'png';
};

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator?.userAgent ?? '',
  );

export const convertPngToJpeg = (
  pngBlob: string,
  onConvert: (fileUrl: string) => void,
): void => {
  const canvas = document.createElement('canvas');
  const pngImage = new Image();
  pngImage.src = pngBlob;
  pngImage.onload = (): void => {
    const context = canvas?.getContext('2d');
    if (context) {
      canvas.height = canvas.width * (pngImage.height / pngImage.width);
      context.fillStyle = '#fff';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(pngImage, 0, 0, canvas.width, canvas.height);
      onConvert(canvas.toDataURL('image/jpeg', 0.8));
    }
  };
};
