import { isValid, addHours } from 'date-fns';
import {
  Button,
  LoadingSpinner,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  UpsertPractitionerBookingMutation,
  UpsertPractitionerBookingMutationVariables,
  PhoneCallPageTemplateQuery,
  PhoneCallPageTemplateQueryVariables,
  ConsultationStatus,
  CheckSuggestedTimeQuery,
  CheckSuggestedTimeQueryVariables,
  ScheduleCallPhoneNumberModalFragment,
  ScheduleCallPractitionerBookingWindowFragment,
} from '@customer-frontend/graphql-types';
import { useParams } from 'react-router-dom';
import { CannotBookErrorMessage } from '../schedule-phone-call';
import {
  phoneNumberModalFragment,
  ScheduleCallPhoneNumberModal,
} from '../schedule-phone-call/phone-number-modal';
import { useConsultationType } from '@customer-frontend/services';
import { useEventService } from '@customer-frontend/events';
import { validateIntlMobileNumberAgainstCountryCodes } from '@customer-frontend/utils';
import { CallSummaryCard } from '@customer-frontend/consultation';

type PractitionerPhoneCallProps = {
  onConfirm: () => void;
  onSchedule: () => void;
  goBackToProfile: () => void;
};

type PractitionerPhoneCallViewExposedProps = {
  image: React.ReactElement;
};

type PractitionerPhoneCallViewProps = PractitionerPhoneCallViewExposedProps & {
  isCurrentWindowAvailable: boolean;
  onPrimaryCtaClick: () => void;
  onSecondaryCtaClick: () => void;
  isLoading: boolean;
  canSchedulePractitionerBooking: boolean | undefined;
  consultationStatus: ConsultationStatus | undefined;
  clinicianName: string | undefined;
  goBackToProfile: () => void;
  phoneNumber?: string | null;
  validPhoneRegions: ScheduleCallPhoneNumberModalFragment['validPhoneRegions'];
  bookingWindow: Pick<
    ScheduleCallPractitionerBookingWindowFragment,
    'startAt' | 'endAt'
  >;
};

export const PractitionerPhoneCall: React.FC<
  PractitionerPhoneCallProps & PractitionerPhoneCallViewExposedProps
> = ({ onConfirm, onSchedule, goBackToProfile, ...viewProps }) => {
  const { formatMessage } = useIntl();
  const notification = useNotification();
  const { consultationId } = useParams<{ consultationId: string }>();
  const { data, loading, refetch } = useQuery<
    PhoneCallPageTemplateQuery,
    PhoneCallPageTemplateQueryVariables
  >(
    gql`
      ${phoneNumberModalFragment}
      query PhoneCallPageTemplate($consultationId: String!) {
        ...ScheduleCallPhoneNumberModal
        consultation(id: $consultationId) {
          id
          customer {
            id
            phone
          }
          latestPractitionerBooking {
            id
          }
          stage
          status
          canSchedulePractitionerBooking
          doctor {
            id
            shortClinicianName
          }
          nextAvailableCallWindowRange {
            id
            startWindow {
              id
              startAt
              endAt
            }
            endWindow {
              id
              startAt
              endAt
            }
          }
        }
      }
    `,
    {
      variables: {
        consultationId,
      },
      pollInterval: 30000, // 5 minutes
    },
  );

  const [upsertPractitionerBookingMutation, { loading: isMutationLoading }] =
    useMutation<
      UpsertPractitionerBookingMutation,
      UpsertPractitionerBookingMutationVariables
    >(gql`
      mutation UpsertPractitionerBooking(
        $input: UpsertPractitionerBookingInput!
      ) {
        upsertPractitionerBooking(input: $input) {
          consultation {
            id
            chatThread {
              id
            }
            latestPractitionerBooking {
              id
              windowStartAt
              windowEndAt
            }
          }
        }
      }
    `);

  const [checkSuggestedTime, { loading: checkSuggestedTimeLoading }] =
    useLazyQuery<CheckSuggestedTimeQuery, CheckSuggestedTimeQueryVariables>(gql`
      query CheckSuggestedTime($consultationId: String!) {
        consultation(id: $consultationId) {
          id
          practitionerBookingWindows {
            id
            available
          }
        }
      }
    `);

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const startWindow =
    data?.consultation?.nextAvailableCallWindowRange?.startWindow;
  const endWindow = data?.consultation?.nextAvailableCallWindowRange?.endWindow;

  const startDate = isValid(new Date(startWindow?.startAt))
    ? new Date(startWindow?.startAt)
    : new Date();
  const endDate = isValid(new Date(endWindow?.endAt))
    ? new Date(endWindow?.endAt)
    : addHours(startDate, 1);

  const currentTime = new Date();
  const isCurrentWindowAvailable =
    startDate <= currentTime && currentTime < endDate;

  const acceptSuggestedTime = async (): Promise<void> => {
    try {
      const { data: checkSuggestedTimeData } = await checkSuggestedTime({
        variables: {
          consultationId,
        },
      });
      if (
        !checkSuggestedTimeData?.consultation?.practitionerBookingWindows?.some(
          (b) => b.id === startWindow?.id && b.available,
        )
      ) {
        refetch().then(() => {
          notification.warning({
            message: formatMessage({
              defaultMessage:
                'The time you selected is no longer available. Please try again.',
              description:
                'Error message when a user tried to make a booking but the selected time is no longer available',
            }),
          });
        });
        return;
      }
      await upsertPractitionerBookingMutation({
        variables: {
          input: {
            consultationId,
            bookingId: data?.consultation?.latestPractitionerBooking?.id,
            startWindowId: startWindow?.id || '',
            endWindowId: endWindow?.id || '',
          },
        },
      });
      onConfirm();
    } catch (e) {
      // Gql errors handled by middleware
    }
  };

  return (
    <div className="max-w-screen-sm py-10 md:py-12 px-4 mx-auto max-h-full flex flex-grow">
      <PhoneCallView
        {...viewProps}
        phoneNumber={data?.consultation?.customer.phone}
        isCurrentWindowAvailable={isCurrentWindowAvailable}
        canSchedulePractitionerBooking={
          data?.consultation?.canSchedulePractitionerBooking ?? undefined
        }
        clinicianName={
          data?.consultation?.doctor?.shortClinicianName ?? undefined
        }
        consultationStatus={data?.consultation?.status ?? undefined}
        goBackToProfile={goBackToProfile}
        onPrimaryCtaClick={acceptSuggestedTime}
        onSecondaryCtaClick={onSchedule}
        isLoading={isMutationLoading || checkSuggestedTimeLoading || loading}
        validPhoneRegions={data?.validPhoneRegions}
        bookingWindow={{
          endAt: endDate,
          startAt: startDate,
        }}
      />
    </div>
  );
};

const getContent = ({
  isCurrentWindowAvailable,
}: {
  isCurrentWindowAvailable: boolean;
}): {
  title: React.ReactElement;
  content: React.ReactElement;
  primaryCta: React.ReactElement;
  secondaryCta: React.ReactElement;
} => {
  if (isCurrentWindowAvailable) {
    return {
      title: <FormattedMessage defaultMessage="Select medical appointment" />,
      content: (
        <FormattedMessage
          defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} will review your information and call you within the selected window to discuss your treatment options."
          values={{ isGb: getConfig().countryCode === 'GB' }}
        />
      ),
      primaryCta: (
        <>
          <FormattedMessage defaultMessage="Confirm appointment" />
        </>
      ),
      secondaryCta: (
        <>
          <FormattedMessage defaultMessage="Choose another time" />
        </>
      ),
    };
  }
  return {
    title: (
      <>
        <FormattedMessage defaultMessage="Select medical appointment" />
      </>
    ),
    content: (
      <>
        <FormattedMessage defaultMessage="Your practitioner will review your information and call you within the selected window to discuss your treatment options." />
      </>
    ),
    primaryCta: (
      <>
        <FormattedMessage defaultMessage="Confirm appointment" />
      </>
    ),
    secondaryCta: (
      <>
        <FormattedMessage defaultMessage="Choose another time" />
      </>
    ),
  };
};

export const PhoneCallView = ({
  phoneNumber,
  isCurrentWindowAvailable,
  canSchedulePractitionerBooking,
  consultationStatus,
  clinicianName,
  goBackToProfile,
  onPrimaryCtaClick: onPrimaryCtaClickWithoutTracking,
  onSecondaryCtaClick,
  isLoading,
  validPhoneRegions,
  bookingWindow,
}: PractitionerPhoneCallViewProps): React.ReactElement => {
  const { consultationId } = useParams<{ consultationId: string }>();
  const { data, loading } = useConsultationType(consultationId);
  const eventService = useEventService();
  const [showPhoneNumberModal, setShowPhoneNumberModal] =
    useState<boolean>(false);
  const { brand, countryCode } = getConfig();

  useEffect(() => {
    if (validPhoneRegions) {
      setShowPhoneNumberModal(
        !phoneNumber ||
          !validateIntlMobileNumberAgainstCountryCodes(
            phoneNumber,
            validPhoneRegions.map((v) => v.countryCode),
          ),
      );
    }
  }, [phoneNumber, validPhoneRegions]);

  const onPrimaryCtaClick = (): void => {
    if (data?.consultation?.type) {
      eventService.consultation.callRecordingConsentApproved({
        consultationId,
        problemType: data.consultation.type,
      });
    }
    onPrimaryCtaClickWithoutTracking();
  };

  const handleModalClose = (): void => {
    setShowPhoneNumberModal(false);
  };

  const { title, content, primaryCta, secondaryCta } = getContent({
    isCurrentWindowAvailable,
  });

  return (
    <>
      <div className="flex flex-col gap-y-6 items-center text-center max-h-full flex-grow">
        <ScheduleCallPhoneNumberModal
          isOpen={showPhoneNumberModal}
          onClose={handleModalClose}
          validPhoneRegions={validPhoneRegions}
        />

        {canSchedulePractitionerBooking ? (
          <>
            <div className="flex flex-col gap-y-6 items-center text-center max-h-full">
              <div className="space-y-4 flex flex-col">
                <Typography size="lg" isBold>
                  {title}
                </Typography>
                <div className="space-y-3 pb-12">
                  <Typography size="paragraph" textAlign="center" inheritColor>
                    {content}
                  </Typography>
                </div>
                <CallSummaryCard
                  brand={brand}
                  bookingWindow={bookingWindow}
                  countryCodes={[countryCode]}
                  customerHasCallScheduled={!canSchedulePractitionerBooking}
                  userPhoneNumber={phoneNumber ?? ''} // TODO validate that phone number is always defined at this stage
                />
              </div>
              <div className="space-y-4 w-full flex flex-col">
                <Button
                  palette={getPrimaryButtonPalette(brand)}
                  isFullWidth
                  onClick={onPrimaryCtaClick}
                  eventElementName="schedulePracBookingImReady"
                  eventElementAttributes={{ consultationId }}
                  isLoading={isLoading || loading}
                  isDisabled={isLoading}
                >
                  {primaryCta}
                </Button>
                <Button
                  level="secondary"
                  palette={getSecondaryButtonPalette(brand)}
                  isFullWidth
                  onClick={onSecondaryCtaClick}
                >
                  {secondaryCta}
                </Button>
              </div>
            </div>
            <div className="flex flex-col">
              <Typography size="small-text">
                <FormattedMessage defaultMessage="All clinical appointments are recorded for quality and clinical audit purposes." />
              </Typography>
            </div>
          </>
        ) : (
          <>
            <Typography size="lg" isBold>
              <FormattedMessage defaultMessage="Call cannot be rescheduled" />
            </Typography>
            <CannotBookErrorMessage
              status={consultationStatus}
              clinicianName={clinicianName}
              goBackToProfile={goBackToProfile}
            />
          </>
        )}
      </div>
    </>
  );
};
