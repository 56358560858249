import React, { useEffect } from 'react';
import Cleave from 'cleave.js';
import { CleaveOptions } from 'cleave.js/options';
import { NativeInputType, TextInputType, UseCleaveProps } from './types';

const CLEAVE_OPTIONS: Record<string, CleaveOptions> = {
  'masked-date': {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y'],
  },
  'masked-date-period': {
    date: true,
    delimiter: '.',
    datePattern: ['d', 'm', 'Y'],
  },
  'masked-date-reversed': {
    date: true,
    delimiter: '/',
    datePattern: ['Y', 'm', 'd'],
  },
  postcode: {
    numericOnly: true,
    blocks: [4],
  },
  'medicare-number': {
    numericOnly: true,
    blocks: [4, 5, 1],
  },
  'medicare-expiry': {
    date: true,
    delimiter: '/',
    datePattern: ['m', 'Y'],
  },
  'medicare-irn': {
    numericOnly: true,
    blocks: [1],
  },
};

export const getNativeInputType = (
  inputType: TextInputType,
): NativeInputType => {
  let nativeInputType: NativeInputType;
  switch (inputType) {
    case 'masked-date':
    case 'masked-date-reversed':
    case 'masked-date-period':
    case 'postcode':
    case 'medicare-number':
    case 'medicare-expiry':
    case 'medicare-irn':
      nativeInputType = 'tel';
      break;
    default:
      nativeInputType = inputType;
  }

  return nativeInputType;
};

export const useCleave = ({
  ref,
  onChange,
  inputType,
}: UseCleaveProps<HTMLElement>): void => {
  useEffect(() => {
    const elementRef = ref.current;
    const cleaveOptions = CLEAVE_OPTIONS[inputType];

    if (!(elementRef && cleaveOptions)) {
      return (): void => {
        // Ignore
      };
    }

    const cleave = new Cleave(elementRef, {
      ...cleaveOptions,
      onValueChanged: onChange,
    });

    return (): void => {
      if (elementRef) {
        cleave.destroy();
      }
    };
  }, [ref, inputType, onChange]);
};

type Mutable<T> = {
  -readonly [k in keyof T]: T[k];
};
export const mergeRefs =
  <T>(...refs: React.Ref<T>[]) =>
  (value: T): void => {
    for (let i = 0; i < refs.length; i += 1) {
      const ref = refs[i];

      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        (ref as Mutable<React.RefObject<T>>).current = value;
      }
    }
  };
