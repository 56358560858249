import clsx from 'clsx';
import React, { useMemo, useState, useCallback } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { Brand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import { ApolloError, gql } from '@apollo/client';
import {
  Typography,
  Button,
  ButtonPalette,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  useRefreshPaymentDataQuery,
  useRemoveZip,
  useUpdateDefaultPaymentGateway,
  useUpdatePayment,
  useEnablePaymentIntent,
} from '@customer-frontend/services';
import {
  Maybe,
  PaymentGateway,
  BillingDetailsFragment,
} from '@customer-frontend/graphql-types';

import { PaymentCardForm } from './stripe-payment-form/payment-card';
import { PaymentIcons } from './payment-icons';
import { getDefaultPaymentMethod } from '../logic/payment/stripe';
import { ZipLogo, ZipModalLauncher } from './zip-marketing';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import {
  DefaultCardPaymentBanner,
  DefaultPaypalPaymentBanner,
  DefaultSofortPaymentBanner,
} from '@customer-frontend/page-templates';

interface BillingDetailsProps {
  hasZip: Maybe<boolean>;
  savedPaymentMethods: BillingDetailsFragment['savedPaymentMethods'];
  defaultPaymentGateway: Maybe<PaymentGateway>;
  hidePaymentMethodLabel?: boolean;
  cancelButtonVisibility?: 'default' | 'always';
  onCancel?: () => void;
  returnUrl?: string;
  enableEditing?: boolean;
}

// NOTE: This needs to be improved (temporary solution)
const getPaymentOptionStyles = (brand: Brand): string => {
  const common = 'p-4';

  switch (brand) {
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
    case 'pilot':
      return `${common} rounded border border-primary-300`;
    case 'kin':
      return 'px-4 py-3 rounded border border-primary-800';
    case 'software':
      return `${common} rounded-lg bg-gray-100`;
    default:
      return '';
  }
};

const getPaymentOptionEditStyles = (brand: Brand): string => {
  const common = 'p-4';

  switch (brand) {
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
    case 'pilot':
      return `${common} rounded border border-primary-300`;
    case 'software':
      return `${common} rounded-lg bg-gray-100`;
    default:
      return '';
  }
};

const getSecondaryButtonPalette = (brand: Brand): ButtonPalette => {
  switch (brand) {
    case 'pilot': {
      return 'white';
    }

    case 'kin': {
      return 'alternate';
    }

    default: {
      return 'default';
    }
  }
};

const getPrimaryButtonPalette = (brand: Brand): ButtonPalette => {
  switch (brand) {
    case 'pilot':
    case 'kin': {
      return 'alternate';
    }

    default: {
      return 'default';
    }
  }
};

export const BillingDetails = ({
  hasZip,
  savedPaymentMethods,
  defaultPaymentGateway,
  hidePaymentMethodLabel = false,
  cancelButtonVisibility = 'default',
  returnUrl,
  onCancel,
  enableEditing = false,
}: BillingDetailsProps): React.ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(
    !defaultPaymentGateway || enableEditing,
  );
  const notify = useNotification();
  const enablePaymentIntent = useEnablePaymentIntent();

  const config = getConfig();
  const environment = useEnvironment();

  const stripe = useStripe();
  const stripeElements = useElements();

  const defaultPaymentMethod = getDefaultPaymentMethod(savedPaymentMethods);

  const hasSavedStripePaymentMethod = savedPaymentMethods?.find(
    (pm) => pm?.gateway === 'STRIPE',
  );
  // only allow customers to move between payment methods, guaranteeing
  // at least one is set at all times
  const canEditPaymentMethods = hasZip && hasSavedStripePaymentMethod;

  const paymentOptionStyles = getPaymentOptionStyles(config.brand);
  const paymentOptionEditStyles = getPaymentOptionEditStyles(config.brand);

  const [refreshUserPaymentData] = useRefreshPaymentDataQuery({
    fetchPolicy: 'network-only',
  });
  const { formatMessage } = useIntl();
  const [removeZip] = useRemoveZip();
  const [updatePayment] = useUpdatePayment({
    onCompleted: () => {
      setIsEditing(false);
    },
  });
  const [updateDefaultPaymentMethod] = useUpdateDefaultPaymentGateway();

  const handleSetDefaultPaymentMethod = useCallback(
    async (gateway: PaymentGateway): Promise<void> => {
      setLoading(true);
      try {
        await updateDefaultPaymentMethod({
          variables: {
            gateway,
          },
        });
        refreshUserPaymentData();
      } catch (err) {
        const descriptions = getErrorMessageDescriptorsFromError(err);
        descriptions.forEach((descriptor) =>
          notify.error({ message: formatMessage(descriptor) }),
        );
      } finally {
        setLoading(false);
      }
    },
    [updateDefaultPaymentMethod, refreshUserPaymentData, notify, formatMessage],
  );

  const handleSave = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      if (!stripeElements || !stripe) {
        return;
      }

      if (!enablePaymentIntent) {
        const cardElement = stripeElements.getElement(CardElement);

        if (!cardElement) {
          return;
        }

        const { token, error } = await stripe.createToken(cardElement);

        if (error || !token?.card) {
          throw new Error(
            error?.message ??
              formatMessage({
                defaultMessage: 'Payment method is invalid.',
                description: 'Error message for invalid payment method',
              }),
          );
        }

        await updatePayment({
          variables: {
            stripeTokenId: token.id,
          },
        });
      } else {
        if (returnUrl) {
          const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements: stripeElements,
            confirmParams: {
              return_url: returnUrl,
            },
          });
          if (error) {
            throw error;
          }
        } else {
          throw new Error(
            formatMessage({
              defaultMessage: 'Missing returnUrl',
              description: 'Error message for missing url',
            }),
          );
        }
      }
      await handleSetDefaultPaymentMethod('STRIPE');
    } catch (err) {
      if (err instanceof ApolloError) {
        const descriptions = getErrorMessageDescriptorsFromError(err);
        descriptions.forEach((descriptor) =>
          notify.error({ message: formatMessage(descriptor) }),
        );
      } else {
        notify.error({ message: err.message });
      }
    } finally {
      setLoading(false);
    }
  }, [
    stripeElements,
    stripe,
    enablePaymentIntent,
    handleSetDefaultPaymentMethod,
    updatePayment,
    formatMessage,
    returnUrl,
    notify,
  ]);

  const handleRemoveZip = async (): Promise<void> => {
    setLoading(true);
    try {
      await removeZip();
      await handleSetDefaultPaymentMethod('STRIPE');
      refreshUserPaymentData();
    } catch (err) {
      notify.error({
        message: formatMessage({
          defaultMessage: 'Failed to remove Zip. Please Try again',
          description: 'Error message for failing to remove Zip payment method',
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const stripeInputComponent = useMemo(() => {
    return isEditing ? (
      <div className={clsx('w-full', paymentOptionEditStyles)}>
        <PaymentCardForm />
        <div className="flex justify-between pt-4 space-x-4">
          {(defaultPaymentGateway ||
            hasSavedStripePaymentMethod ||
            cancelButtonVisibility === 'always') && (
            <Button
              eventElementName="accountPageBillingDetailsCancelButton"
              isFullWidth
              level="secondary"
              palette={getSecondaryButtonPalette(config.brand)}
              isDisabled={isLoading}
              onClick={() => {
                setIsEditing(false);
                onCancel?.();
              }}
            >
              <FormattedMessage
                defaultMessage="Cancel"
                description="Button that cancels action"
              />
            </Button>
          )}
          <Button
            eventElementName="accountPageBillingDetailsSaveButton"
            isSubmit
            isFullWidth
            palette={getPrimaryButtonPalette(config.brand)}
            isLoading={isLoading}
            onClick={handleSave}
          >
            <FormattedMessage
              defaultMessage="Save"
              description="Button to save billing details"
            />
          </Button>
        </div>
      </div>
    ) : (
      <div
        className={clsx('items-center justify-between', {
          flex: canEditPaymentMethods,
        })}
      >
        {defaultPaymentMethod?.__typename === 'SavedCardPaymentMethod' && (
          <DefaultCardPaymentBanner
            setEditing={setIsEditing}
            defaultPaymentMethod={defaultPaymentMethod}
          />
        )}
        {defaultPaymentMethod?.__typename === 'SavedPayPalPaymentMethod' && (
          <DefaultPaypalPaymentBanner
            setEditing={setIsEditing}
            defaultPaymentMethod={defaultPaymentMethod}
          />
        )}
        {defaultPaymentMethod?.__typename === 'SavedSofortPaymentMethod' && (
          <DefaultSofortPaymentBanner setEditing={setIsEditing} />
        )}
        {canEditPaymentMethods && (
          <div className="flex items-center space-x-4">
            {defaultPaymentGateway !== 'STRIPE' && (
              <button
                className="underline cursor-pointer"
                onClick={() => handleSetDefaultPaymentMethod('STRIPE')}
              >
                <FormattedMessage
                  defaultMessage="Set default"
                  description="Button to set default payment method"
                />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }, [
    isEditing,
    hasSavedStripePaymentMethod,
    defaultPaymentMethod,
    handleSave,
    isLoading,
    handleSetDefaultPaymentMethod,
    defaultPaymentGateway,
    canEditPaymentMethods,
    config.brand,
    paymentOptionEditStyles,
    cancelButtonVisibility,
    onCancel,
  ]);

  return (
    <div
      className={clsx({
        'cursor-not-allowed opacity-50 pointer-events-none': isLoading,
      })}
    >
      <div className="space-y-4 mb-2">
        {defaultPaymentGateway && (
          <div>
            {!hidePaymentMethodLabel && (
              <div className="uppercase mb-3">
                <Typography element="span" size="paragraph">
                  <FormattedMessage
                    defaultMessage="Default payment method"
                    description="Label for a users default payment method"
                  />
                </Typography>
              </div>
            )}
            {defaultPaymentGateway === 'ZIP' ? (
              <div className={paymentOptionStyles}>
                <div className="flex items-center justify-between space-x-4">
                  {environment.zipMerchantId && (
                    <ZipModalLauncher merchantId={environment.zipMerchantId}>
                      <ZipLogo />
                    </ZipModalLauncher>
                  )}
                  {canEditPaymentMethods && (
                    <button
                      className="underline cursor-pointer"
                      onClick={() => handleRemoveZip()}
                    >
                      <FormattedMessage
                        defaultMessage="Remove"
                        description="Button for removing payment method"
                      />
                    </button>
                  )}
                </div>
              </div>
            ) : (
              stripeInputComponent
            )}
          </div>
        )}
        {hasZip && (
          <div>
            {!hidePaymentMethodLabel && (
              <div className="uppercase mb-3">
                <Typography element="span" size="paragraph">
                  <FormattedMessage
                    defaultMessage="Other payment options"
                    description="Prompt for the user to look into other payment options"
                  />
                </Typography>
              </div>
            )}
            {defaultPaymentGateway === 'ZIP' ? (
              <>
                {isEditing || hasSavedStripePaymentMethod ? (
                  stripeInputComponent
                ) : (
                  <div
                    className={clsx(
                      'flex justify-between items-center',
                      paymentOptionStyles,
                    )}
                  >
                    <div className="flex items-center">
                      {/* eslint-disable-next-line react/jsx-no-literals,formatjs/no-literal-string-in-jsx */}
                      <PaymentIcons renderSeal={false} />{' '}
                      <span className="ml-4">
                        <FormattedMessage
                          defaultMessage="Credit / Debit card"
                          description="Label for credit / debit card"
                        />
                      </span>
                    </div>
                    <button
                      className="underline cursor-pointer"
                      onClick={() => setIsEditing(true)}
                    >
                      <FormattedMessage
                        defaultMessage="Add"
                        description="Button to add card details"
                      />
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div
                className={clsx(
                  'flex justify-between items-center',
                  paymentOptionStyles,
                )}
              >
                {environment.zipMerchantId && (
                  <ZipModalLauncher merchantId={environment.zipMerchantId}>
                    <ZipLogo />
                  </ZipModalLauncher>
                )}
                <div className="flex items-center space-x-4">
                  <button
                    className="underline cursor-pointer"
                    onClick={() => handleSetDefaultPaymentMethod('ZIP')}
                  >
                    <FormattedMessage
                      defaultMessage="Set default"
                      description="Button to set default payment method"
                    />
                  </button>
                  <button
                    className="underline cursor-pointer"
                    onClick={() => handleRemoveZip()}
                  >
                    <FormattedMessage
                      defaultMessage="Remove"
                      description="Button for removing payment method"
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {!defaultPaymentGateway && stripeInputComponent}
      </div>
    </div>
  );
};

BillingDetails.fragment = gql`
  fragment BillingDetails on User {
    id
    defaultPaymentGateway
    savedPaymentMethods {
      id
      gateway
      default
      createdAt
      ... on SavedCardPaymentMethod {
        expiry
        mask
        brand
      }
      ... on SavedZipPaymentMethod {
        valid
      }
      ... on SavedPayPalPaymentMethod {
        email
      }
    }
    zip {
      valid
    }
  }
`;
