import { Brand } from '@customer-frontend/types';
import {
  JuniperAuConfig,
  JuniperDeConfig,
  JuniperJpConfig,
  JuniperUKConfig,
  KinConfig,
  PilotConfig,
  SoftwareConfig,
} from './brands';
import { Config } from './type';

const configByBrand: Record<Brand, Config> = {
  juniper: JuniperAuConfig,
  'juniper-de': JuniperDeConfig,
  'juniper-uk': JuniperUKConfig,
  'juniper-jp': JuniperJpConfig,
  kin: KinConfig,
  pilot: PilotConfig,
  software: SoftwareConfig,
};

/**
 * Use this to access brand specific config variables. Config that is not consistent across environments should live in `@customer-frontend/environment`.
 */
export const getConfig = (): Config => {
  const brand = process.env.REACT_APP_BRAND as Brand;
  const config = configByBrand[brand];

  if (!config) {
    throw new Error(`Config not implemented for brand ${brand}.`);
  }

  return config;
};
