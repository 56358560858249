export const calculateTreatmentCost = (treatment: {
  plan?: { amount?: number | null } | null;
  otcSchedules?: {
    isActive: boolean;
    product: {
      variants: {
        price?: number;
      }[];
    };
    quantity?: number;
  }[];
  nextPaymentAmount?: number | null;
}): number => {
  const amount = treatment.nextPaymentAmount ?? treatment.plan?.amount ?? 0;
  const otcItemsAmount =
    treatment.otcSchedules?.reduce((total, item) => {
      // If no quantity is specified for a product, assume 1, as we have been assuming before.
      // TODO(maggiexu-euc): As fast follow, have all quantities be specified from otcSchedules.
      const quantity = item.quantity ?? 1;
      if (item.isActive) {
        const variantPrice = item.product.variants?.[0]?.price ?? 0;
        return total + variantPrice * quantity;
      }
      return total;
    }, 0) ?? 0;
  return amount + otcItemsAmount;
};

export const getAllProductsFromTreatment = (
  treatment?: {
    product: { id: string };
    plan?: {
      amount: number;
    } | null;
    otcSchedules: {
      isActive: boolean;
      product: { id: string; variants: { price?: number }[] };
      quantity?: number;
    }[];
    nextPaymentAmount?: number | null;
  } | null,
): { id: string; price: number; quantity?: number }[] => {
  if (!treatment) {
    return [];
  }

  const res: { id: string; price: number }[] = [
    {
      id: treatment.product.id,
      price: treatment?.nextPaymentAmount ?? treatment.plan?.amount ?? 0,
    },
  ];

  res.push(
    ...treatment.otcSchedules
      .filter((o) => o.isActive)
      .map((o) => ({
        id: o.product.id,
        price: o.product.variants[0]?.price ?? 0,
        // If no quantity is specified for a product, assume 1, as we have been assuming before.
        // TODO(maggiexu-euc): As fast follow, have all quantities be specified from otcSchedules.
        quantity: o.quantity ?? 1,
      })),
  );

  return res;
};
