// status colours
export const error100 = '#fff2f2';
export const error500 = '#af2828';
export const warning100 = '#ffefd0';
export const warning500 = '#ffc247';
export const success100 = '#f6fdf2';
export const success500 = '#14763e';
export const info100 = '#e8f0f8';
export const info500 = '#24517d';

// neutral shades
export const white = '#ffffff';
export const neutral100 = '#f2f2f2';
export const neutral200 = '#d9d9d9';
export const neutral300 = '#cccccc';
export const neutral400 = '#b3b3b3';
export const neutral500 = '#999999';
export const neutral600 = '#737373';
export const neutral700 = '#595959';
export const neutral800 = '#404040';
export const neutral900 = '#1a1a1a';
export const black = '#000000';

export const sharedColors = {
  status: {
    error: {
      100: error100,
      500: error500,
    },
    warning: {
      100: warning100,
      500: warning500,
    },
    success: {
      100: success100,
      500: success500,
    },
    info: {
      100: info100,
      500: info500,
    },
  },
  neutral: {
    white,
    100: neutral100,
    200: neutral200,
    300: neutral300,
    400: neutral400,
    500: neutral500,
    600: neutral600,
    700: neutral700,
    800: neutral800,
    900: neutral900,
    black,
  },
};

export default sharedColors;
