import {
  CardMeta,
  Maybe,
  PaymentFormFragment,
  PaymentGateway,
} from '@customer-frontend/graphql-types';
import { Typography } from '@eucalyptusvc/design-system';
import { PaymentRequestTokenEvent } from '@stripe/stripe-js';
import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDefaultPaymentMethod, getStripePaymentMethod } from '../../logic';
import { PaymentCardDisplay, PaymentCardForm } from './payment-card';
import { DefaultSofortPaymentBanner } from '@customer-frontend/page-templates';

interface StripePaymentFormProps {
  totalPrice: number;
  paymentMethods: Maybe<
    Maybe<{
      id?: Maybe<string>;
      cardMeta?: Maybe<CardMeta>;
      gateway?: Maybe<PaymentGateway>;
    }>[]
  >;
  savedPaymentMethods?: PaymentFormFragment['savedPaymentMethods'];
  isEditingStripeDetails: boolean;
  PaymentCardDisplayComponent?: typeof PaymentCardDisplay;
  onEdit: () => void;
  onAlternativePaymentMethod: (event: PaymentRequestTokenEvent) => void;
  shouldShowFormLabel?: boolean;
  errorTextColor?: string;
  solePaymentProvider?: boolean;
}

export const StripePaymentForm = ({
  totalPrice,
  paymentMethods,
  savedPaymentMethods,
  isEditingStripeDetails,
  PaymentCardDisplayComponent = PaymentCardDisplay,
  onEdit,
  onAlternativePaymentMethod,
  shouldShowFormLabel = false,
  errorTextColor,
  solePaymentProvider = false,
}: StripePaymentFormProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  let paymentDisplayComponent;

  if (savedPaymentMethods) {
    const defaultPaymentMethod = getDefaultPaymentMethod(savedPaymentMethods);
    switch (defaultPaymentMethod?.__typename) {
      case 'SavedCardPaymentMethod': {
        paymentDisplayComponent = (
          <PaymentCardDisplayComponent
            brand={defaultPaymentMethod.brand}
            mask={defaultPaymentMethod.mask}
            expiry={defaultPaymentMethod.expiry}
            onClickEdit={onEdit}
          />
        );
        break;
      }
      case 'SavedPayPalPaymentMethod': {
        paymentDisplayComponent = (
          <div className="rounded border border-primary-400 w-full p-4">
            <div className="flex justify-between items-center">
              <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" />
              <div className="md:items-start">
                {defaultPaymentMethod.email && (
                  <Typography size="paragraph">
                    <FormattedMessage
                      defaultMessage="Authorised by {email}"
                      description="Paypal authorisation status with email"
                      values={{
                        email: defaultPaymentMethod.email,
                      }}
                    />
                  </Typography>
                )}
              </div>
              <button
                className="text-primary-darker p-2 leading-tight"
                aria-label={formatMessage({
                  defaultMessage: 'Edit',
                  description: 'Aria label for a button to edit payment method',
                })}
                onClick={onEdit}
              >
                <FaPencilAlt />
              </button>
            </div>
          </div>
        );
        break;
      }
      // This looks rubbish, but it is superior to the prior behaviour, and
      // Sofort is soon to be deprecated.
      case 'SavedSofortPaymentMethod': {
        paymentDisplayComponent = (
          <DefaultSofortPaymentBanner setEditing={onEdit} />
        );
        break;
      }
      // This looks rubbish, but it is better than displaying nothing and is identical
      // to the prior behaviour!
      default: {
        paymentDisplayComponent = (
          <PaymentCardDisplayComponent
            brand=""
            mask=""
            expiry=""
            onClickEdit={onEdit}
          />
        );
        break;
      }
    }
  } else {
    const cardMeta = getStripePaymentMethod(paymentMethods)?.cardMeta;

    paymentDisplayComponent = (
      <PaymentCardDisplayComponent
        brand={cardMeta?.brand ?? ''}
        mask={cardMeta?.mask ?? ''}
        expiry={cardMeta?.expiry ?? ''}
        onClickEdit={onEdit}
      />
    );
  }

  return (
    <>
      {isEditingStripeDetails ? (
        <div className="space-y-1">
          {shouldShowFormLabel && (
            <Typography isBold size="paragraph">
              <FormattedMessage
                defaultMessage="Card details"
                description="Title for the card details form"
              />
            </Typography>
          )}
          <PaymentCardForm
            paymentRequest={{ totalPrice, onAlternativePaymentMethod }}
            errorTextColor={errorTextColor}
            solePaymentProvider={solePaymentProvider}
          />
        </div>
      ) : (
        paymentDisplayComponent
      )}
    </>
  );
};
