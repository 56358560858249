import React from 'react';
import { useApolloClient, Reference } from '@apollo/client';
import {
  useOtcShoppingCartQuery,
  useUpdateOtcShoppingCartMutation,
  useRemoveOtcShoppingCartItemMutation,
  usePurchaseOtcShoppingCartMutation,
  OtcShoppingCartDocument,
  ShoppingCartFragment,
} from '@customer-frontend/graphql-types';
import { OTCCartContextState } from './types';

const defaultValues = {
  isOTCCartOpen: false,
  setIsOTCCartOpen: () => {
    return false;
  },
  cartItems: [],
  buyNow: () => Promise.resolve(),
  updateItemInCart: () => Promise.resolve(),
  removeItemFromCart: () => Promise.resolve(),
  purchaseCart: () => Promise.resolve(),
  clearCart: () => Promise.resolve(),
  purchaseLoading: false,
  updateLoading: false,
};
export const OTCCartContext =
  React.createContext<OTCCartContextState>(defaultValues);

export const useOTCCart = (): OTCCartContextState =>
  React.useContext(OTCCartContext);

export const OTCCartProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const apolloClient = useApolloClient();
  const updateCacheCart = (
    cart: ShoppingCartFragment | null,
  ): Reference | undefined =>
    apolloClient.writeQuery({
      query: OtcShoppingCartDocument,
      data: {
        otcShoppingCart: cart,
      },
    });

  const [updateOtcShoppingCartMutation, { loading: updateLoading }] =
    useUpdateOtcShoppingCartMutation({
      onCompleted: ({ updateOtcShoppingCart }) =>
        updateOtcShoppingCart && updateCacheCart(updateOtcShoppingCart),
    });
  const [purchaseOtcShoppingCart, { loading: purchaseLoading }] =
    usePurchaseOtcShoppingCartMutation({
      onCompleted: () => updateCacheCart(null),
    });
  const [removeOtcShoppingCartItem] = useRemoveOtcShoppingCartItemMutation();

  const { data } = useOtcShoppingCartQuery({
    fetchPolicy: 'cache-first',
  });
  const shoppingCart = data?.otcShoppingCart;
  const cartId = shoppingCart?.id;
  const cartItems =
    shoppingCart && shoppingCart.items ? shoppingCart.items : [];

  const [isOTCCartOpen, setIsOTCCartOpen] = React.useState<boolean>(false);

  const getQuantityIfInCart = (variantId: string): number | undefined => {
    const item = cartItems.find((item) => item.variant.id === variantId);
    if (item) {
      return item.quantity;
    }

    return undefined;
  };

  const buyNow = async (variantId: string): Promise<void> => {
    const quantity: number | undefined = getQuantityIfInCart(variantId);

    if (quantity) {
      await updateItemInCart(variantId, quantity + 1);
    } else {
      await updateItemInCart(variantId, 1);
    }
  };

  const updateItemInCart = async (
    variantId: string,
    quantity = 1,
  ): Promise<void> => {
    await updateOtcShoppingCartMutation({
      variables: { item: { variantId, quantity } },
    });
    setIsOTCCartOpen(true);
  };

  const removeItemFromCart = async (variantId: string): Promise<void> => {
    if (cartId) {
      await removeOtcShoppingCartItem({
        variables: { variantId, cartId },
      });
    }
  };

  const purchaseCart = async (discountCode?: string): Promise<void> => {
    if (cartId) {
      await purchaseOtcShoppingCart({
        variables: { cartId, discountCode },
      });
    }
  };

  const clearCart = async (): Promise<void> => {
    if (cartId && cartItems) {
      await Promise.all(
        cartItems.map(({ variant }) => {
          return removeOtcShoppingCartItem({
            variables: { variantId: variant.id, cartId },
          });
        }),
      );
    }
  };

  const value = {
    cartItems,
    clearCart,
    purchaseCart,
    isOTCCartOpen,
    updateLoading,
    purchaseLoading,
    setIsOTCCartOpen,
    updateItemInCart,
    buyNow,
    removeItemFromCart,
  };

  return (
    <OTCCartContext.Provider value={value}>{children}</OTCCartContext.Provider>
  );
};
