import { useState, useMemo, type ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';
import {
  FaqMarkdownItem,
  FaqPanel,
  SafetyInfo,
} from '@customer-frontend/consultation';
import { PrescriberType } from '@customer-frontend/doctor';
import { FeatureFlagBoolean } from '@customer-frontend/feature-flags';
import {
  SafetyInformationPageQuery,
  SafetyInformationPageQueryVariables,
  SafetyInformationQuestionMutation,
  SafetyInformationQuestionMutationVariables,
} from '@customer-frontend/graphql-types';
import { useRequiredValidation, useTitle } from '@customer-frontend/utils';
import {
  AccordionPalette,
  Button,
  ButtonPalette,
  Card,
  Divider,
  LoadingSpinner,
  RadioButtonGroup,
  TextArea,
  Typography,
} from '@eucalyptusvc/design-system';
import { ReactComponent as Tick } from '../assets/tick.svg';
import { Logger } from '@customer-frontend/logger';
import { useBackButtonBehaviour } from '@customer-frontend/services';
import { ConsultationNotesTabs } from '../consultation';

const query = gql`
  fragment SafetyInformationSequence on PrescribableSequence {
    safetyInformation {
      id
      cmiUrl
      cmiCopyMarkdown
    }
  }

  fragment FaqSequence on PrescribableSequence {
    faqs {
      id
      rank
      question
      answerMarkdown
    }
  }

  query SafetyInformationPage($consultationId: String!) {
    consultation(id: $consultationId) {
      id
      purchasePrompt {
        id
        ... on InitialPurchasePrompt {
          proposedOfferingSelection {
            id
            sequenceSelections {
              id
              sequence {
                id
                ... on PrescribableSequence {
                  ...FaqSequence
                  ...SafetyInformationSequence
                }
              }
            }
          }
        }
        ... on ConfirmPurchasePrompt {
          purchaseGroup {
            id
            purchases {
              id
              contexts {
                id
                sequence {
                  id
                  ... on PrescribableSequence {
                    ...SafetyInformationSequence
                    ...FaqSequence
                  }
                }
              }
            }
          }
        }
        ... on FurPurchasePrompt {
          proposedOfferingSelection {
            id
            sequenceSelections {
              id
              sequence {
                id
                ... on PrescribableSequence {
                  ...SafetyInformationSequence
                  ...FaqSequence
                }
              }
            }
          }
        }
        ... on SubstitutePurchasePrompt {
          substitutions {
            id
            substitution {
              id
              sequenceSelections {
                id
                sequence {
                  id
                  ... on PrescribableSequence {
                    ...SafetyInformationSequence
                    ...FaqSequence
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type SafetyInformationPageProps = {
  consultationId: string;
  routes: {
    consultation: {
      doctorsNote: string;
      safetyInformation: string;
      reviewSubstitution: string;
      reviewFollowUpOrReview: string;
      upfrontPayment: string;
      purchaseActivationReview: string;
    };
    profile: string;
  };
  palette: {
    backButton: ButtonPalette;
    faqAccordion: AccordionPalette;
  };
  logger: Logger;
};

export function SafetyInformationPage({
  consultationId,
  routes,
  palette,
  logger,
}: SafetyInformationPageProps): ReactElement {
  const config = getConfig();
  const history = useHistory();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Pharmacy safety information',
      description: 'Page title for the Pharmacy safety information page',
    }),
  );

  const questionForm = useForm<{
    contactMethod: 'email' | 'phone' | '';
    question: string;
  }>({
    shouldUnregister: false,
    defaultValues: { contactMethod: '', question: '' },
  });
  const [questionSent, setQuestionSent] = useState(false);
  const questionLabel = formatMessage({
    defaultMessage: 'Question',
    description: 'Safety information page question label',
  });
  const questionValidation = useRequiredValidation(questionLabel);
  const contactMethodLabel = formatMessage({
    defaultMessage: 'Contact method',
    description: 'Safety information page contact method label',
  });
  const contactMethodValidation = useRequiredValidation(contactMethodLabel);

  const [submitQuestionMutation, { loading: submitQuestionLoading }] =
    useMutation<
      SafetyInformationQuestionMutation,
      SafetyInformationQuestionMutationVariables
    >(
      gql`
        mutation SafetyInformationQuestion(
          $consultationId: String!
          $messageText: String!
          $contactMethod: ContactMethod
        ) {
          submitConsultationQuestionToPharmacist(
            consultationId: $consultationId
            messageText: $messageText
            contactMethod: $contactMethod
          ) {
            consultation {
              id
            }
          }
        }
      `,
      {
        onCompleted: () => setQuestionSent(true),
        onError: () => setQuestionSent(false),
      },
    );

  const { data, loading } = useQuery<
    SafetyInformationPageQuery,
    SafetyInformationPageQueryVariables
  >(query, {
    errorPolicy: 'all',
    variables: { consultationId },
  });

  const { safetyMarkdown, safetyUrl, faqs, nextRoute } = useMemo(() => {
    const prompt = data?.consultation?.purchasePrompt;
    let safetyMarkdown: string | undefined;
    let safetyUrl: string | undefined;
    let sequenceFaqs: {
      __typename?: 'SequenceFaq' | undefined;
      id: string;
      rank?: number | null | undefined;
      question: string;
      answerMarkdown: string;
    }[] = [];
    let nextRoute = routes.profile;

    switch (prompt?.__typename) {
      case 'FurPurchasePrompt': {
        prompt.proposedOfferingSelection?.sequenceSelections?.forEach((ss) => {
          if (ss.sequence?.__typename === 'PrescribableSequence') {
            safetyMarkdown = ss.sequence.safetyInformation?.cmiCopyMarkdown;
            safetyUrl = ss.sequence.safetyInformation?.cmiUrl;
            sequenceFaqs = ss.sequence.faqs;
            nextRoute = routes.consultation.reviewFollowUpOrReview;
          }
        });
        break;
      }

      case 'InitialPurchasePrompt': {
        prompt.proposedOfferingSelection?.sequenceSelections?.forEach((ss) => {
          if (ss.sequence?.__typename === 'PrescribableSequence') {
            safetyMarkdown = ss.sequence.safetyInformation?.cmiCopyMarkdown;
            safetyUrl = ss.sequence.safetyInformation?.cmiUrl;
            sequenceFaqs = ss.sequence.faqs;
            nextRoute = routes.consultation.upfrontPayment;
          }
        });
        break;
      }

      case 'SubstitutePurchasePrompt': {
        prompt.substitutions?.forEach((sub) => {
          sub.substitution.sequenceSelections?.forEach((ss) => {
            if (ss.sequence?.__typename === 'PrescribableSequence') {
              safetyMarkdown = ss.sequence.safetyInformation?.cmiCopyMarkdown;
              safetyUrl = ss.sequence.safetyInformation?.cmiUrl;
              sequenceFaqs = ss.sequence.faqs;
              nextRoute = routes.consultation.purchaseActivationReview;
            }
          });
        });
        break;
      }

      case 'ConfirmPurchasePrompt': {
        prompt.purchaseGroup.purchases?.forEach((p) => {
          p.contexts?.forEach((c) => {
            if (c.sequence?.__typename === 'PrescribableSequence') {
              safetyMarkdown = c.sequence.safetyInformation?.cmiCopyMarkdown;
              safetyUrl = c.sequence.safetyInformation?.cmiUrl;
              sequenceFaqs = c.sequence.faqs;
              nextRoute = routes.consultation.purchaseActivationReview;
            }
          });
        });
        break;
      }
    }

    const faqs: FaqMarkdownItem[] =
      sequenceFaqs.map((faq) => ({
        title: faq?.question,
        markdown: faq?.answerMarkdown,
        rank: faq?.rank,
      })) ?? [];

    return {
      safetyMarkdown,
      safetyUrl,
      faqs,
      nextRoute,
    };
  }, [data, routes]);

  const goToNextRoute = (): void => history.push(nextRoute);
  useBackButtonBehaviour(goToNextRoute);

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (!safetyMarkdown && !safetyUrl) {
    logger.error(
      `no safety information found for consultation "${consultationId}"`,
    );

    return <Redirect to={routes.profile} />;
  }

  return (
    <div className="max-w-screen-md px-4 pt-10 md:pt-14 pb-10 md:pb-24 mx-auto flex flex-col items-center gap-8">
      <ConsultationNotesTabs
        routes={{
          doctorsNote: routes.consultation.doctorsNote,
          pharmacyInformation: routes.consultation.safetyInformation,
        }}
        variant="border-around"
      />

      <div className="self-start flex">
        <Typography isBold size="lg">
          <FormattedMessage
            defaultMessage="A note from your partner clinic"
            description="Safety information page title"
          />
        </Typography>
      </div>

      <Card isFullWidth>
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Important safety information"
            description="Safety information page card title"
          />
        </Typography>
        <Divider variant="separator" mt="xs" />
        <div className="space-y-6">
          <SafetyInfo safetyInformation={safetyMarkdown} cmiUrl={safetyUrl} />
        </div>
      </Card>

      {config.clinicianMessagingSupported && (
        <Card isFullWidth>
          {!questionSent && (
            <>
              <Typography isBold size="md">
                <FormattedMessage
                  defaultMessage="Any questions for the pharmacy?"
                  description="Safety information page checkbox copy"
                />
              </Typography>

              <Divider variant="separator" mt="xs" />

              <div className="space-y-4">
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="Please note that your {isPrescriber, select, true {prescriber} other {practitioner}} will receive information about your allergies, medical conditions and medications to help them answer your question."
                    description="Safety information page question card copy"
                    values={{
                      isPrescriber:
                        config.prescriberType === PrescriberType.PRESCRIBER,
                    }}
                  />
                </Typography>

                <FeatureFlagBoolean flagKey="PT_866_PHARMACY_COPY">
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="If you do not have a question to ask, but would like to receive additional counselling via a phone call from our pharmacists, simply type “yes” in the free text field below."
                      description="Safety information page question card copy"
                    />
                  </Typography>
                </FeatureFlagBoolean>

                <TextArea
                  name="question"
                  ref={questionForm.register(questionValidation)}
                  errorMessage={questionForm.errors.question?.message}
                  placeholder={formatMessage({
                    defaultMessage: 'Type your response here',
                    description: 'Safety information page question placeholder',
                  })}
                  rows={4}
                />

                <Controller
                  control={questionForm.control}
                  rules={contactMethodValidation}
                  name="contactMethod"
                  as={
                    <RadioButtonGroup
                      name="contactMethod"
                      errorMessage={questionForm.errors.contactMethod?.message}
                      label={formatMessage({
                        defaultMessage: 'I want to receive a response by',
                        description:
                          'Safety information page question contact method',
                      })}
                      options={[
                        {
                          label: formatMessage({
                            defaultMessage: 'Email',
                            description:
                              'Safety information page question contact method',
                          }),
                          value: 'email',
                        },
                        {
                          label: formatMessage({
                            defaultMessage: 'Phone',
                            description:
                              'Safety information page question contact method',
                          }),
                          value: 'phone',
                        },
                      ]}
                    />
                  }
                />

                <Button
                  isSubmit
                  onClick={questionForm.handleSubmit(async (data) => {
                    await submitQuestionMutation({
                      variables: {
                        consultationId,
                        messageText: data.question,
                        contactMethod:
                          data.contactMethod === 'email' ? 'EMAIL' : 'PHONE',
                      },
                    });
                  })}
                  isFullWidth
                  level="secondary"
                  isLoading={submitQuestionLoading}
                >
                  <FormattedMessage
                    defaultMessage="Send question"
                    description="Safety information page send question button"
                  />
                </Button>
              </div>
            </>
          )}

          {questionSent && (
            <div className="bg-primary-100 p-2 mt-4 flex space-x-2 items-start">
              <span className="text-primary-500">
                <Tick className="fill-current" />
              </span>
              <Typography size="medium-paragraph">
                <FormattedMessage
                  defaultMessage="Thanks! Your message has been sent. You may still continue with payment. The {isPrescriber, select, true {prescriber} other {practitioner}} will respond within 24 hours. If you are experiencing a medical emergency, please call {emergencyPhoneNumber}."
                  values={{
                    isPrescriber:
                      config.prescriberType === PrescriberType.PRESCRIBER,
                    emergencyPhoneNumber: config.emergencyNumber,
                  }}
                />
              </Typography>
            </div>
          )}
        </Card>
      )}

      <Button isFullWidth onClick={goToNextRoute} palette={palette.backButton}>
        <FormattedMessage
          defaultMessage="Back to treatment plan"
          description="Safety information page continue button"
        />
      </Button>

      <Divider variant="separator" mt="sm" mb="sm" />

      <div className="w-full">
        <div className="pb-6">
          <Typography isBold size="lg">
            <FormattedMessage
              defaultMessage="FAQs"
              description="Doctor letter FAQ title"
            />
          </Typography>
        </div>
        <FaqPanel accordionPalette={palette.faqAccordion} faqs={faqs} />
      </div>
    </div>
  );
}
