import sharedColors from '../shared/palette';

// primary
export const primary100 = '#ebeaf2';
export const primary200 = '#CDCBDF';
export const primary300 = '#9c97bf';
export const primary400 = '#6A5DA6';
export const primary500 = '#392F80';
export const primary600 = '#231A66';

// secondary
export const electric100 = '#5254F0';
export const electric200 = '#1214D2';
export const electric300 = '#090b90';
export const cream100 = '#FFF8F0';

// tertiary
export const palePurple = '#F0EFFE';
export const lavender = '#C7BBEB';
export const lilac = '#dadaf0';

// reserved
export const fertility = '#FBBA91';
export const conceiving = '#C6A5AC';
export const pregnancy = '#E28E4E';
export const postpartum = '#D76E6E';

export const colors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
    600: primary600,
  },
  secondary: {
    electric: {
      100: electric100,
      200: electric200,
      300: electric300,
    },
    cream: {
      100: cream100,
    },
  },
  tertiary: {
    palePurple,
    lavender,
    lilac,
  },
  reserved: {
    fertility,
    conceiving,
    pregnancy,
    postpartum,
  },
};
