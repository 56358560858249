import type { ProfileOtcPurchaseDetailsQuery } from '@customer-frontend/graphql-types';
import { ShoppingCartItems } from '@customer-frontend/order';

export const hasShippingDetailsComplete = (
  profile: ProfileOtcPurchaseDetailsQuery['profile'],
): boolean => {
  return !!(
    profile &&
    profile.firstName &&
    profile.lastName &&
    profile.phone &&
    profile.email &&
    profile.address &&
    profile.address.line1 &&
    profile.address.city &&
    profile.address.postalCode &&
    profile.address.state &&
    profile.address.country
  );
};

export const calculateTotalOrderPrice = (items: ShoppingCartItems): number => {
  return items
    ? items.reduce((total, item) => {
        const cost = item.variant.price * item.quantity;
        return cost + total;
      }, 0)
    : 0;
};
