import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
} from '@chakra-ui/react';
import { useResponsive } from '../../hooks';
import { DrawerProps } from './types';

/**
 * The Drawer component is a panel that slides out from the edge of the screen.
 * It can be useful when you need users to complete a task or view some details without leaving the current page.
 */
export const Drawer = ({
  isOpen,
  children,
  onClose,
  size = 'sm',
  onCloseComplete,
}: DrawerProps): JSX.Element => {
  const { isMobile } = useResponsive();

  return (
    <ChakraDrawer
      isOpen={isOpen}
      size={isMobile ? 'xs' : size}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {children}
      </DrawerContent>
    </ChakraDrawer>
  );
};
