import React, { useMemo } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Brand } from '@customer-frontend/types';
import { getBrandTheme } from './theme';
import { ResponsiveProvider } from './hooks/use-responsive/use-responsive';

interface Props {
  brand: Brand;
  resetCSS?: boolean;
  children: React.ReactNode;
}

export const DesignSystemProvider: React.FC<Props> = ({
  children,
  brand,
  resetCSS = false,
}) => {
  const theme = useMemo(() => getBrandTheme(brand), [brand]);

  return (
    <ChakraProvider theme={theme} resetCSS={resetCSS}>
      <ResponsiveProvider>{children}</ResponsiveProvider>
    </ChakraProvider>
  );
};

export default DesignSystemProvider;
