import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Button, LoadingSpinner } from '@eucalyptusvc/design-system';
import {
  useBackButtonBehaviour,
  useConsultation,
} from '@customer-frontend/services';

import { ReviewThread } from './review-thread';
import { ConsultationMessagingProps } from './types';
import { ReviewHeader } from './review-header';
import { FormattedMessage } from 'react-intl';

export const ConsultationMessaging = ({
  chatTheme,
  profileRoute,
  chatRoute,
  imageInstructions,
  requiredAlertIcon,
  removeDrPrefix,
  createObservationInput,
  getPlaceholderImgUrl,
}: ConsultationMessagingProps): JSX.Element => {
  const history = useHistory();

  const { consultationId } = useParams<{ consultationId: string }>();
  const { loading, data, refetch } = useConsultation(consultationId as string);

  const handleBackToProfile = (): void => {
    history.push(profileRoute);
  };

  useBackButtonBehaviour(() => handleBackToProfile());

  if (loading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const consultation = data?.consultation;
  const doctor = consultation?.doctor;

  if (consultation?.chatThread?.id) {
    return <Redirect to={chatRoute(consultationId)} />;
  }

  const wrapperClasses =
    chatTheme.threadWrapperClasses ?? 'max-w-md md:max-w-full mx-auto';

  const canMessage = consultation?.allowPatientToSendMessageToDoctor ?? false;

  return (
    <div className={chatTheme.pageWrapperClasses}>
      {doctor && (
        <div className={chatTheme.headerSectionClasses}>
          <ReviewHeader
            doctor={doctor}
            removeDrPrefix={removeDrPrefix ?? false}
            details={`${doctor.provider?.clinicianTitle} - ${doctor.provider?.qualifications}`}
            chatTheme={chatTheme}
          />
        </div>
      )}

      <div className={wrapperClasses}>
        {consultation?.id && (
          <ReviewThread
            chatTheme={chatTheme}
            consultationId={consultation.id}
            consultationStatus={consultation.status}
            problemType={consultation.type}
            doctor={doctor}
            canMessage={canMessage}
            requiredAlertIcon={requiredAlertIcon}
            doctorQuestions={consultation?.doctorQuestions ?? undefined}
            patientQuestions={consultation?.patientQuestions ?? undefined}
            reminders={consultation?.reminders}
            imageInstructions={imageInstructions}
            onSubmit={refetch}
            createObservationInput={createObservationInput}
            getPlaceholderImgUrl={getPlaceholderImgUrl}
          />
        )}
        {!canMessage && (
          <div className="mt-2">
            <Button
              level="secondary"
              palette={chatTheme.returnButtonPalette}
              isFullWidth
              onClick={handleBackToProfile}
            >
              <FormattedMessage defaultMessage="Back to home" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsultationMessaging;
