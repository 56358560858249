import {
  gql,
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import { MutationUpdatePaymentArgs } from '@customer-frontend/graphql-types';

const updatePaymentDocument = gql`
  mutation UpdatePayment(
    $stripeTokenId: String
    $stripePaymentMethodId: String
  ) {
    updatePayment(
      stripeTokenId: $stripeTokenId
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      id
      paymentMethods {
        id
        gateway
        cardMeta {
          id
          brand
          mask
          expiry
        }
      }
    }
  }
`;

export const useUpdatePayment = (
  options?: MutationHookOptions<Response, MutationUpdatePaymentArgs>,
): MutationTuple<Response, MutationUpdatePaymentArgs> =>
  useMutation<Response, MutationUpdatePaymentArgs>(
    updatePaymentDocument,
    options,
  );
