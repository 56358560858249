import React from 'react';
import { Drawer } from '@eucalyptusvc/design-system';

import { OTCPurchase } from './otc-purchase';
import { OTCPurchaseCompleted } from './otc-purchase-completed';
import type { OTCPurchaseDrawerProps } from './types';
import { OTCEmptyCart } from './otc-cart-empty';
import { useOTCCart } from '../../logic';

export const OTCPurchaseDrawer = ({
  brandLogo,
  emptyCartConfig,
  purchaseCompletedImage,
  enableCart = false,
}: OTCPurchaseDrawerProps): JSX.Element => {
  const { cartItems, clearCart, isOTCCartOpen, setIsOTCCartOpen } =
    useOTCCart();

  const [isPurchaseCompleted, setIsPurchaseCompleted] = React.useState(false);
  const isCartEmpty = cartItems.length === 0;

  React.useEffect(() => {
    // hide/show zendesk button which covers pay button
    if (isOTCCartOpen) {
      try {
        // window?.zE?.('webWidget', 'hide');
      } catch {
        //
      }
    } else {
      try {
        // window?.zE?.('webWidget', 'show');
      } catch {
        //
      }
    }
  }, [isOTCCartOpen]);

  // clears cart for one-click experiences
  const cleanUpCart = (): void => {
    !enableCart && clearCart();
  };

  const onCloseDrawer = React.useCallback((): void => {
    if (isPurchaseCompleted) {
      setIsPurchaseCompleted(false);
    }
    setIsOTCCartOpen(false);
  }, [isPurchaseCompleted, setIsOTCCartOpen, setIsPurchaseCompleted]);

  const drawerContent = React.useMemo(() => {
    if (isPurchaseCompleted) {
      return (
        <OTCPurchaseCompleted
          onBackToProfile={onCloseDrawer}
          image={purchaseCompletedImage}
        />
      );
    }
    if (isCartEmpty) {
      return <OTCEmptyCart onClose={onCloseDrawer} {...emptyCartConfig} />;
    }
    return (
      <OTCPurchase
        className="flex-auto"
        onCompleted={() => setIsPurchaseCompleted(true)}
        enableCart={enableCart}
      />
    );
  }, [
    enableCart,
    isCartEmpty,
    onCloseDrawer,
    emptyCartConfig,
    isPurchaseCompleted,
    purchaseCompletedImage,
  ]);

  return (
    <Drawer
      isOpen={isOTCCartOpen}
      onClose={onCloseDrawer}
      onCloseComplete={cleanUpCart}
    >
      <div className="text-primary-600 flex flex-col flex-auto h-100 min-h-0">
        <div className="p-5 shadow-sm">{brandLogo}</div>
        {drawerContent}
      </div>
    </Drawer>
  );
};
