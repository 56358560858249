import {
  type FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { Avatar, Typography } from '@eucalyptusvc/design-system';
import QuestionIcon from '../../assets/icons/question-icon.svg';
import { Maybe } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';

export type FloatingMessageButtonProps = {
  onClick: () => void;
  doctor: {
    shortClinicianName: string;
    avatar?: Maybe<{
      url: string;
    }>;
  };
};

export const FloatingMessageButton: FunctionComponent<
  FloatingMessageButtonProps
> = ({ onClick, doctor }) => {
  const { formatMessage } = useIntl();

  const [showDoctorText, setShowDoctorText] = useState<boolean>(true);

  const handleScroll = useCallback((): void => {
    setTimeout(() => {
      setShowDoctorText(false);
      window.removeEventListener('scroll', handleScroll);
    }, 2000);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return function cleanupListener(): void {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      onClick={onClick}
      className={clsx(
        'flex rounded-full p-1 h-12 transition-width duration-500 cursor-pointer',
        showDoctorText ? 'w-52' : 'w-12',
        getClassName(),
      )}
    >
      <div className="flex items-center w-max h-max">
        <div className="flex items-center">
          <span className="mr-6 ml-0.5 mb-2">
            <Avatar src={doctor.avatar?.url} size="xs" />
          </span>

          <span className="flex justify-center absolute ml-6 mt-4">
            <img
              width="16px"
              src={QuestionIcon}
              alt={formatMessage({
                defaultMessage: 'Question icon',
                description: 'Description of a question mark icon',
              })}
            />
          </span>

          {showDoctorText && doctor.shortClinicianName && (
            <Typography size="medium-paragraph" isBold>
              <FormattedMessage
                defaultMessage="Ask {doctorName}"
                description="Call to action to open the form to message a doctor"
                values={{ doctorName: doctor.shortClinicianName }}
              />
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

const getClassName = (): string | undefined => {
  const config = getConfig();

  switch (config.brand) {
    case 'pilot':
      return 'bg-secondary-navy-300 text-white';
    case 'software':
      return 'bg-white text-black border border-black';
    default:
      return 'bg-primary-500 text-white';
  }
};
