import {
  ConsultationPaymentPageQuery,
  useConsultationPaymentPageQuery,
  useConsultationPricingQuery,
} from '@customer-frontend/graphql-types';

export const useConsultationPaymentPageWithPricing = ({
  consultationId,
  discountCode,
}: {
  consultationId: string;
  discountCode?: string;
}): {
  data: ConsultationPaymentPageQuery | undefined;
  consultationLoading: boolean;
  discountedPriceLoading: boolean;
} => {
  const { data: consultationData, loading: consultationLoading } =
    useConsultationPaymentPageQuery({
      variables: { id: consultationId },
    });

  const { data: discountedPriceData, loading: discountedPriceLoading } =
    useConsultationPricingQuery({
      variables: {
        id: consultationId,
        discountCode: discountCode,
      },
      skip: !discountCode,
    });

  const consultationPrice =
    discountedPriceData?.consultation?.price ??
    consultationData?.consultation?.price;

  return {
    data: consultationData?.consultation
      ? {
          consultation: {
            ...consultationData.consultation,
            price: consultationPrice,
          },
        }
      : undefined,
    consultationLoading: consultationLoading,
    discountedPriceLoading: discountedPriceLoading,
  };
};
