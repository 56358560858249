import React from 'react';
import { Typography, Button } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';

import { OTCShippingDetailsDisplay } from './otc-shipping-details-display';
import { OTCShippingDetailsForm } from './otc-shipping-details-form';

import type { OTCShippingAddress } from './types';
import { FormattedMessage } from 'react-intl';
import { Maybe } from '@customer-frontend/graphql-types';

export type OTCShippinDetailsProps = {
  defaultIsEditing?: boolean;
  hideCancel?: boolean;
  data: {
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    address?: Maybe<OTCShippingAddress>;
  };
};

export const OTCShippingDetails = ({
  defaultIsEditing = false,
  hideCancel = false,
  data,
}: OTCShippinDetailsProps): JSX.Element => {
  const [isEditing, setIsEditing] = React.useState(defaultIsEditing);
  const config = getConfig();

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancelEdit = (): void => {
    setIsEditing(false);
  };

  if (isEditing) {
    const defaultValues = {
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
      email: data.email ?? '',
      phone: data.phone ?? '',
      address: {
        line1: data.address?.line1 ?? '',
        line2: data.address?.line2 ?? '',
        city: data.address?.city ?? '',
        postalCode: data.address?.postalCode ?? '',
        state: data.address?.state ?? '',
        country: data.address?.country ?? config.country,
      },
    };

    return (
      <OTCShippingDetailsForm
        defaultValues={defaultValues}
        hideCancel={hideCancel}
        onSubmitted={handleCancelEdit}
        onCancel={handleCancelEdit}
      />
    );
  }

  return (
    <div className="space-y-3">
      <Typography element="h3" size="sm" isBold>
        <FormattedMessage defaultMessage="Shipping details" />
      </Typography>
      <div className="flex justify-between gap-4">
        <OTCShippingDetailsDisplay data={data} />
        <Button
          level="secondary"
          palette="alternate"
          size="sm"
          onClick={handleEdit}
        >
          <FormattedMessage
            defaultMessage="Edit"
            description="Button text to edit the order shipping details"
          />
        </Button>
      </div>
    </div>
  );
};
