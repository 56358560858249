import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import {
  TextInput,
  Typography,
  TypographyProps,
  Button,
  Modal,
} from '@eucalyptusvc/design-system';
import { useForm } from 'react-hook-form';
import { formatCurrency } from '@eucalyptusvc/lib-localization';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { OfferingHighlights } from './offering-highlights';
import { ReactComponent as ShoppingTag } from '../../assets/shopping-tag.svg';
import { ReactComponent as DeleteCrossClose } from '../../assets/delete-cross-close.svg';
import { ReactComponent as InfoCircle } from '../../assets/info-circle.svg';

import { usePurchaseActivationFlow } from './provider';
import { useState } from 'react';
import { ReactComponent as AddPlus } from '../../assets/add-plus.svg';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { gql } from '@apollo/client';
import { OrderSummaryCardGippFragment } from '@customer-frontend/graphql-types';

export type OrderSummaryCardStyles = {
  headerSize: TypographyProps['size'];
  headerPadding: string;
  headerColor: string;
  headerBgColor: string;
  dueTodaySize: TypographyProps['size'];
  dueTodayColor: string;
  dueTodayBgColor: string;
  dueTodayBorderTColor: string;
  dividerBorderColor: string;
  highlightsTextColor: string;
};

export type OrderSummaryStyles = {
  desktopHeaderSize: TypographyProps['size'];
  card: OrderSummaryCardStyles & {
    borderColor: string;
  };
};

export function OrderSummaryCard({
  styles,
  orderSummary,
  loadingOrderSummary,
  discountCodeProps,
  confirmed = false,
}: {
  styles: OrderSummaryCardStyles;
  orderSummary: OrderSummaryCardGippFragment | undefined | null;
  loadingOrderSummary?: boolean;
  discountCodeProps?: {
    canRemoveDiscountCodes: boolean;
    canAddDiscountCodes: boolean;
    onDiscountCodeRemoved: (code: string) => void;
    onDiscountCodeAdded: (code: string) => void;
  };
  confirmed?: boolean;
}) {
  const config = getConfig();
  const { formatMessage } = useIntl();
  const offering = orderSummary?.offeringPriceLists[0]?.offering;
  const subtotal = orderSummary?.originalAmount ?? 0;

  const nonRxLineItems =
    orderSummary?.offeringPriceLists[0]?.sequencePriceLists
      ?.filter((s) => s.sequence?.__typename === 'OtcSequence')
      .flatMap((s) =>
        s.pricedItems
          .filter((item) => item.variant?.public)
          .map((item) => ({
            id: item.id,
            unitPrice: item.unitPrice,
            photoUrl: item.variant?.product?.photo?.url,
            productName: item.variant?.product.name,
          })),
      ) ?? [];

  const couponCodes =
    orderSummary?.coupons
      ?.filter((c) => c.outcome === 'SUCCESS')
      .map((c) => c.code) ?? [];

  const discountCodeForm = useForm<{ discountCode: string }>();
  const [showDiscountForm, setShowDiscountForm] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);

  const showLoadingSkeleton = loadingOrderSummary && !orderSummary;

  return (
    <div className={clsx('bg-white flex flex-col w-full')}>
      <Modal
        size="sm"
        isOpen={showRefundModal}
        onClose={() => setShowRefundModal(false)}
        title={
          <Typography size="medium-paragraph" isBold>
            <FormattedMessage defaultMessage="Refunds" />
          </Typography>
        }
      >
        <Typography size="paragraph">
          <FormattedMessage defaultMessage="Please allow 5 - 10 business days for the funds to appear in your account." />
        </Typography>
      </Modal>
      <div
        className={clsx(
          styles.headerBgColor,
          styles.headerColor,
          styles.headerPadding,
        )}
      >
        <Typography size={styles.headerSize} isBold inheritColor>
          <FormattedMessage defaultMessage="Your plan" />
        </Typography>
      </div>
      <div
        className={clsx(
          'bg-white w-full flex flex-row items-center justify-center px-3 py-4 border-b',
          styles.dividerBorderColor,
        )}
      >
        {showLoadingSkeleton ? (
          <div className="flex space-x-4 w-full animate-pulse">
            <div className="bg-neutral-black bg-opacity-10 h-12 w-12 rounded-sm flex-shrink-0" />
            <div className="flex flex-1 flex-col space-y-1 flex-shrink">
              <div className="bg-neutral-black bg-opacity-10 rounded-sm h-4 w-2/3" />
              <div className="bg-neutral-black bg-opacity-10 rounded-sm h-3 w-1/3" />
            </div>
            <div className="flex flex-none flex-col items-end space-y-2 flex-shrink-0">
              <div className="bg-neutral-black bg-opacity-10 rounded-sm h-4 w-16" />
              <div className="bg-neutral-black bg-opacity-10 rounded-sm h-2 w-16" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-y-4 w-full">
            <LineItem
              photoUrl={offering?.photoUrl}
              title={offering?.advertisedName}
              description={formatMessage({
                defaultMessage: 'Includes medication',
              })}
              unitPrice={{
                amount: subtotal,
                description: formatMessage({
                  defaultMessage: 'This order',
                }),
              }}
            />
            {nonRxLineItems.length > 0 && (
              <>
                <div className={styles.headerColor}>
                  <Typography size="small-text" isBold inheritColor>
                    <FormattedMessage defaultMessage="Your plan includes:" />
                  </Typography>
                </div>
                {nonRxLineItems.map((li) => (
                  <LineItem
                    key={li.id}
                    photoUrl={li.photoUrl}
                    title={li.productName}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={clsx(
          'p-4 flex flex-row justify-between w-full text-black items-center border-b space-x-2',
          styles.dividerBorderColor,
        )}
      >
        <Typography size="paragraph" inheritColor>
          <FormattedMessage defaultMessage="Subtotal" />
        </Typography>
        {showLoadingSkeleton ? (
          <div className="animate-pulse">
            <div className="bg-neutral-black bg-opacity-10 rounded-sm h-4 w-16" />
          </div>
        ) : (
          <Typography size="paragraph" inheritColor>
            {formatCurrency(mapBrandToAdaptersBrand(config.brand), subtotal, {
              includeSymbol: true,
              includeDecimals: true,
            })}
          </Typography>
        )}
      </div>
      <div
        className={clsx(
          'p-4 flex flex-row justify-between w-full text-black items-center space-x-2',
          styles.dividerBorderColor,
        )}
      >
        <Typography size="paragraph" inheritColor>
          <FormattedMessage defaultMessage="Amount already paid" />
        </Typography>
        {showLoadingSkeleton ? (
          <div className="animate-pulse">
            <div className="bg-neutral-black bg-opacity-10 rounded-sm h-4 w-16" />
          </div>
        ) : (
          <Typography size="paragraph" inheritColor>
            {formatCurrency(
              mapBrandToAdaptersBrand(config.brand),
              -(orderSummary?.sessionCreditAmount ?? 0),
              {
                includeSymbol: true,
                includeDecimals: true,
              },
            )}
          </Typography>
        )}
      </div>
      {!showLoadingSkeleton && couponCodes.length > 0 && (
        <div
          className={clsx(
            'px-4 pb-4 flex flex-row justify-between w-full text-black items-center',
          )}
        >
          <div className="flex flex-row flex-wrap gap-4">
            {couponCodes.map((couponCode) => (
              <div
                key={couponCode}
                className="bg-neutral-100 rounded flex flex-row items-center px-1"
              >
                <ShoppingTag className="text-neutral-700 mx-1" />
                <Typography size="paragraph" inheritColor>
                  {couponCode}
                </Typography>
                {discountCodeProps?.canRemoveDiscountCodes && (
                  <button
                    onClick={() =>
                      discountCodeProps.onDiscountCodeRemoved(couponCode)
                    }
                    className="p-2 text-neutral-700"
                    disabled={loadingOrderSummary}
                  >
                    <DeleteCrossClose />
                  </button>
                )}
              </div>
            ))}
          </div>
          <Typography size="paragraph" inheritColor>
            {formatCurrency(
              mapBrandToAdaptersBrand(config.brand),
              -(orderSummary?.discountAmount ?? 0),
              {
                includeSymbol: true,
                includeDecimals: true,
              },
            )}
          </Typography>
        </div>
      )}
      {!showLoadingSkeleton &&
        discountCodeProps?.canAddDiscountCodes &&
        couponCodes.length === 0 && (
          <div
            className={clsx(
              'px-4 py-3 w-full border-t',
              styles.dividerBorderColor,
            )}
          >
            {showDiscountForm ? (
              <form
                className={clsx(
                  'flex flex-row items-end space-x-2 text-primary-600',
                  styles.dividerBorderColor,
                )}
                onSubmit={discountCodeForm.handleSubmit((data) => {
                  if (data.discountCode) {
                    discountCodeProps.onDiscountCodeAdded(data.discountCode);
                    discountCodeForm.reset();
                  }
                })}
              >
                <TextInput
                  ref={discountCodeForm.register()}
                  name="discountCode"
                  label={formatMessage({ defaultMessage: 'Discount code' })}
                />
                <div className="mb-1">
                  <Button isSubmit isLoading={loadingOrderSummary}>
                    <FormattedMessage
                      defaultMessage="Apply"
                      description="Add a discount code"
                    />
                  </Button>
                </div>
              </form>
            ) : (
              <button
                onClick={() => setShowDiscountForm(true)}
                className="text-neutral-700 flex flex-row justify-between items-center w-full"
              >
                <Typography size="small-text" inheritColor>
                  <FormattedMessage defaultMessage="Add discount code" />
                </Typography>
                <AddPlus
                  width={12}
                  height={12}
                  fill={sharedColors.neutral[700]}
                />
              </button>
            )}
          </div>
        )}
      <div
        className={clsx(
          'w-full flex flex-row justify-between border-t p-4',
          styles.dueTodayColor,
          styles.dueTodayBgColor,
          styles.dueTodayBorderTColor,
        )}
      >
        <Typography size={styles.dueTodaySize} isBold inheritColor>
          {orderSummary?.amount && orderSummary?.amount < 0 ? (
            <>
              {confirmed ? (
                <FormattedMessage defaultMessage="Refunded" />
              ) : (
                <FormattedMessage defaultMessage="Refund due" />
              )}
              <button
                onClick={() => setShowRefundModal(true)}
                className="text-neutral-700 p-0.5 ml-1"
              >
                <InfoCircle
                  width={14}
                  height={14}
                  fill="currentColor"
                  className="-mb-0.5"
                />
              </button>
            </>
          ) : confirmed ? (
            <FormattedMessage defaultMessage="Total" />
          ) : (
            <FormattedMessage defaultMessage="Due today" />
          )}
        </Typography>
        {showLoadingSkeleton ? (
          <div className="animate-pulse">
            <div className="bg-neutral-black bg-opacity-10 rounded-sm h-6 w-16" />
          </div>
        ) : (
          <Typography size={styles.dueTodaySize} isBold inheritColor>
            {formatCurrency(
              mapBrandToAdaptersBrand(config.brand),
              orderSummary?.amount ?? 0,
              {
                includeSymbol: true,
                includeDecimals: true,
                signDisplay: 'never',
              },
            )}
          </Typography>
        )}
      </div>
    </div>
  );
}

OrderSummaryCard.gippFragment = gql`
  # We are using this fragment for generated types, but we can't import it in the provider file since it'd be a circular dependency
  # eslint-disable-next-line
  fragment OrderSummaryCardGIPP on InitialPurchasePriceList {
    id
    amount
    coupons {
      id
      code
      outcome
    }
    discountAmount
    originalAmount
    sessionCreditAmount
    offeringPriceLists {
      id
      offering {
        id
        advertisedName
        friendlyName
        photoUrl
      }
      sequencePriceLists {
        id
        sequence {
          id
        }
        pricedItems {
          id
          unitPrice
          variant {
            id
            public
            product {
              id
              name
              photo {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

export function OrderSummary({ styles }: { styles: OrderSummaryStyles }) {
  const {
    loadingOrderSummary,
    orderSummary,
    routes,
    consultationId,
    onDiscountCodeAdded,
    onDiscountCodeRemoved,
  } = usePurchaseActivationFlow();
  const location = useLocation();
  const prescribableSequence =
    orderSummary?.offeringPriceLists[0]?.sequencePriceLists.find(
      (s) => s.sequence?.__typename === 'PrescribableSequence',
    )?.sequence;

  const showOfferingHighlights =
    !loadingOrderSummary && location.pathname !== routes.review(consultationId);

  return (
    <aside className="md:pt-40 md:pb-6 md:px-6 flex flex-col md:h-full md:overflow-y-auto">
      <div className="hidden md:block md:mb-4">
        <Typography size={styles.desktopHeaderSize} isBold>
          <FormattedMessage defaultMessage="Order summary" />
        </Typography>
      </div>
      <div
        className={clsx(
          'md:border md:rounded-lg w-full md:max-w-96 flex-shrink-0 overflow-hidden',
          styles.card.borderColor,
        )}
      >
        <OrderSummaryCard
          styles={styles.card}
          orderSummary={orderSummary}
          loadingOrderSummary={loadingOrderSummary}
          discountCodeProps={{
            canRemoveDiscountCodes: true,
            canAddDiscountCodes: (orderSummary?.amount ?? 0) > 0,
            onDiscountCodeAdded: onDiscountCodeAdded,
            onDiscountCodeRemoved: onDiscountCodeRemoved,
          }}
        />
      </div>
      {showOfferingHighlights && prescribableSequence && (
        <div className="py-4 px-4 md:px-3 text-inherit">
          <OfferingHighlights
            sequence={prescribableSequence}
            styles={{
              textSize: 'paragraph',
              iconDimension: 14,
              textClassName: styles.card.highlightsTextColor,
            }}
          />
        </div>
      )}
    </aside>
  );
}

function LineItem(props: {
  photoUrl?: string | null;
  title?: string | null;
  description?: string | null;
  unitPrice?: {
    amount: number;
    description?: string;
  };
}) {
  return (
    <div className="flex space-x-3 items-start">
      {props.photoUrl && (
        <div className="flex-none flex items-center justify-center">
          <img
            src={props.photoUrl}
            className="w-12 h-12 object-cover aspect-square rounded"
          />
        </div>
      )}
      <div className="flex flex-grow flex-col text-primary-500">
        <Typography size="paragraph" isBold>
          {props.title}
        </Typography>
        <div className="text-neutral-700">
          <Typography size="paragraph" inheritColor>
            {props.description}
          </Typography>
        </div>
      </div>
      {props.unitPrice && (
        <div className="flex flex-none flex-col items-end text-black">
          <Typography size="paragraph" inheritColor>
            {formatCurrency(
              mapBrandToAdaptersBrand(getConfig().brand),
              props.unitPrice.amount,
              {
                includeSymbol: true,
                includeDecimals: true,
              },
            )}
          </Typography>
          {props.unitPrice.description && (
            <div className="text-neutral-700">
              <Typography size="small-text" inheritColor>
                {props.unitPrice.description}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
