export const routes = {
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  reset: '/reset',
  start: {
    root: '/start',
    name: '/start/name',
    signup: '/start/signup',
  },
  profile: '/profile',
  profileMedicare: '/profile/medicare',
  profileSubscriptions: '/profile/subscriptions',
  privacyPolicy: '/privacy',
  treatment: {
    dashboard: (id: string): string => `/profile/treatments/${id}`,
  },
  consultation: {
    paymentConsultation: (id: string): string =>
      `/consultation/${id}/paymentConsultation`,
    collectMedicare: (id: string): string =>
      `/consultation/${id}/collect-medicare`,
    collectAddress: (id: string): string =>
      `/consultation/${id}/collect-address`,
    notes: (id: string): string => `/consultation/${id}/notes`,
    schedule: (id: string): string => `/consultation/${id}/schedule`,
    quiz: (id: string): string => `/consultation/${id}/quiz`,
    quizSummary: (id: string): string => `/consultation/${id}/quiz-summary`,
    plan: (id: string): string => `/consultation/${id}/plan`,
    doctorsNote: (id: string): string => `/consultation/${id}/doctors-note`,
    pharmacyInfo: (id: string): string => `/consultation/${id}/pharmacyInfo`,
    add: (id: string): string => `/consultation/${id}/add`,
    shipping: (id: string): string => `/consultation/${id}/shipping`,
    results: (id: string): string => `/consultation/${id}/results`,
    review: (id: string): string => `/consultation/${id}/review`,
    complete: (id: string): string => `/consultation/${id}/complete`,
    followUp: (id: string): string => `/consultation/${id}/follow-up`,
    conceptionChecklist: (id: string): string =>
      `/consultation/${id}/conceptionChecklist`,
    pregnancyChecklist: (id: string): string =>
      `/consultation/${id}/pregnancyChecklist`,
    fertilityTest: {
      dashboard: (id: string): string =>
        `/consultation/${id}/fertilityTest/dashboard`,
      payment: (id: string): string =>
        `/consultation/${id}/fertilityTest/payment`,
      confirm: (id: string): string =>
        `/consultation/${id}/fertilityTest/confirm`,
      schedule: (id: string): string =>
        `/consultation/${id}/fertilityTest/schedule`,
    },
    chat: (id: string, loadAtTop?: boolean): string =>
      `/consultation/${id}/chat${loadAtTop ? '?load-at-top=true' : ''}`,
    phoneCall: (id: string): string => `/consultation/${id}/phone-call`,
    scheduleCall: (id: string): string => `/consultation/${id}/schedule-call`,
    phoneCallConfirmed: (id: string): string => `/call/${id}/confirmation`,
  },
  quiz: {
    root: (id: string): string => `/quiz/${id}`,
    complete: (id: string): string => `/quiz/${id}/complete`,
    summary: (id: string): string => `/quiz/${id}/summary`,
  },
  masterclass: {
    root: '/masterclass',
    home: '/masterclass/home',
    class: '/masterclass/class/:classId',
    payment: '/masterclass/payment',
  },
};

export function getLoginUrl(): string {
  const { pathname, search, hash } = window.location;
  const target = `${pathname}${search}${hash}`;

  return `${routes.login}?redirect=${encodeURIComponent(target)}`;
}
