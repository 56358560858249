import { IUiStorage } from './IUiStorage';
import { storageAvailable } from './storageAvailable';
import { UiStorageType } from './type';

export class SessionUiStorage implements IUiStorage {
  isSupported(): boolean {
    return storageAvailable('sessionStorage');
  }
  public getType(): UiStorageType {
    return 'session_storage';
  }
  public setValue(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
  }

  public getValue(key: string): string | undefined {
    const value = window.sessionStorage.getItem(key) ?? undefined;
    if (value && value.startsWith('"') && value.endsWith('"')) {
      return value.substring(1, value.length - 1);
    }
    return value;
  }

  public clearValue(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  public clear(): void {
    window.sessionStorage.clear();
  }
}

export const sessionUiStorage = new SessionUiStorage();
