import { Button, ButtonProps } from '@eucalyptusvc/design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router';
import { QuestionCopyProps, Copy } from '../question';
import { QuizSummary } from '@customer-frontend/page-templates';
import { useBackButtonBehaviour } from '@customer-frontend/services';
import { getPrimaryButtonPalette } from '../helpers';
import { getConfig } from '@customer-frontend/config';

/**
 * Intermediate review question component that allows a patient to review their answers to continue or restart the quiz.
 */
export function ReviewQuestion({
  questionCopyProps,
  NextButtonComponent = Button,
  isSubmitting,
  attributesCard,
  onRestart,
}: {
  questionCopyProps: QuestionCopyProps;
  NextButtonComponent?: React.FunctionComponent<ButtonProps>;
  isSubmitting: boolean;
  attributesCard?: React.ReactNode;
  onRestart?: () => void;
}): React.ReactElement {
  const history = useHistory();
  const [showReview, setShowReview] = React.useState<boolean>(false);

  const config = getConfig();

  const { quizApplicationId } = useParams<{ quizApplicationId: string }>();

  const reviewBackBehaviour = React.useCallback((): void => {
    if (showReview) {
      setShowReview(false);
    } else {
      history.goBack();
    }
  }, [showReview, history]);

  useBackButtonBehaviour(reviewBackBehaviour);

  if (showReview) {
    return (
      <QuizSummary
        quizUrl={`/quiz/${quizApplicationId}`}
        goBack={() => setShowReview(false)}
        onRestart={onRestart}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col items-center mb-6 text-center">
        <Copy {...questionCopyProps} />
      </div>
      {attributesCard && (
        <div className="bg-primary-100 p-4 rounded my-6 space-y-4">
          {attributesCard}
        </div>
      )}
      <div className="mb-3">
        <NextButtonComponent
          level="primary"
          palette={getPrimaryButtonPalette(config.brand)}
          isLoading={isSubmitting}
          isSubmit
          isFullWidth
        >
          <FormattedMessage
            defaultMessage="Confirm answers"
            description="Button label for confirming answers"
          />
        </NextButtonComponent>
      </div>
      <NextButtonComponent
        level="secondary"
        palette={config.brand === 'pilot' ? 'white' : 'default'}
        onClick={() => setShowReview(true)}
        isFullWidth
      >
        <FormattedMessage
          defaultMessage="Review all"
          description="Button label for review answers"
        />
      </NextButtonComponent>
    </>
  );
}
