import { ComponentStyleConfig } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

import { brandColors } from '../palette';
import { getImageUploadStyles, imageUploadParts as parts } from '../../shared';

export const ImageUpload: ComponentStyleConfig = {
  parts,
  baseStyle: (props: StyleFunctionProps) =>
    getImageUploadStyles({
      props,
      styleOverrides: {
        container: {
          borderRadius: '0.25rem',
          bg: brandColors.secondary.cream[200],
          border: props.hasError
            ? `1px solid ${brandColors.status.error[500]}`
            : 'none',
          color: brandColors.secondary.navy[300],
        },
        placeholder: {
          border: `1px dashed ${brandColors.primary[400]}`,
          backgroundColor: brandColors.secondary.cream[100],
        },
      },
    }),
};
