import { PrescriberType } from '@customer-frontend/doctor';
import { DefaultConfig } from '../type';
import { enAU } from 'date-fns/locale';

export const defaultConfig: DefaultConfig = {
  prescriberType: PrescriberType.DOCTOR,
  scalesProvided: true,
  moneyBackGuaranteeEnabled: false,
  clinicianMessagingSupported: true,
  consentRequired: false,
  healthCoachingEnabled: true,
  referAFriendEnabled: false,
  weekendSupportEnabled: true,
  healthlineEnabled: false,
  freeShippingEnabled: true,
  addressConfig: {
    numberBeforeStreet: true,
    showStateInput: true,
    validateShippingAddressWithinRange: false,
  },
  dateConfig: {
    placeholder: 'DD/MM/YYYY',
    format: 'dd/MM/yyyy',
    type: 'masked-date',
    datefnsLocale: enAU,
  },
  urlPaths: {
    privacyNotice: '/privacy-notice',
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
    termsOfSale: '/terms-of-sale',
    termsOfService: '/terms-of-service',
    cookieNotice: '/cookie-notice',
    about: '/about',
    contact: '/contact-page',
    waitList: '/wait-list',
    faqs: '/faqs',
  },
  otcSubscriptions: false,
  selfServiceCancelEnabled: true,
  enableMarketingConsent: false,
  enableMedicare: false,
  enableZip: false,
  trustPilotEnabled: false,
  coldStorageAddressValidation: false,
  showOnboardingQuiz: false,
  batchedOzempicEnabled: false,
  twoDayDelivery: false,
  pharmacyInvoicesEnabled: false,
  collectAddressPage: false,
  confirmAnswersPage: false,
  consultationCreateAccountPage: false,
  saxendaDrNote: false,
  upfrontPaymentStoryTellingRoute: 'programme',
  hasMobileApp: false,
  verifyIdentity: false,
  shouldPresentPhoneFieldAtCreateAccount: true,

  // The hardcoded content for GLP1 medications has been deprecated following the introduction of sync consults, and should not be shown going forward.
  // It's too high risk to remove for existing patients, therefore the code/content will remain for 12 months, until patients will have fininshed their current treatment
  // and either be prescribed a new doctor letter or no longer active
  glp1TreatmentBreakdownDeprecatedDate: new Date('2023-10-07T22:04:43Z'),
  flexiBrand: false,
  purchasePrompts: {
    initialPurchaseFaqs: 'hide',
    initialPurchaseContents: 'condensed',
    initialPurchaseAddresses: new Set(['residential'] as const),
    initialPurchaseShowSimplifiedShippingAddressForm: false,
    initialPurchaseConfirmPersonalDetails: true,
    supportShippingAddressValidation: false,
    collectDeliveryInstructions: false,
    collectDeliveryInstructionsUpfront: false,
    showCheckoutReassurance: true,
    showDeliveryConstraintCard: false,
    showMarketingOptInCheckbox: false,
    showTermsAndConditionsCheckbox: false,
    showZendeskSupportCard: false,
  },
};
