import { usePathologyLocationHealiusQuery } from '@customer-frontend/graphql-types';
import { Button, Divider, Typography } from '@eucalyptusvc/design-system';

import { ReactComponent as DownloadIcon } from 'assets/icons/download-bold.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/status-icon/status-tick.svg';
import { PaymentInfoCard } from 'pages/consultation/payment/common/payment-info-card';
import { EmbeddedMap } from './embedded-map';

export const FertilityTestConfirmCard = ({
  handleDownloadReferral,
  postcode,
}: {
  handleDownloadReferral: () => void;
  postcode: string;
}): JSX.Element => {
  const { data } = usePathologyLocationHealiusQuery({
    variables: { postcode: postcode },
  });
  const pathologyLocation = data?.pathologyLocationHealius;
  const pathologyClinic = pathologyLocation?.display;
  const mapsSearchText =
    pathologyClinic && postcode && `${pathologyClinic} near ${postcode}`;
  return (
    <PaymentInfoCard
      icon={<CheckCircleIcon className="w-8 h-8 my-1 text-success-500" />}
      title="Your Fertility Test has been confirmed"
      titleSize="lg"
    >
      <Divider palette="alternate" />
      <div className="space-y-6">
        <Typography size="md" isBold>
          Next Steps
        </Typography>
        <div className="space-y-4">
          <Typography size="medium-paragraph">
            1. Download and print your referral form.
          </Typography>
          <Button onClick={handleDownloadReferral} isFullWidth>
            <DownloadIcon className="h-6  text-white" />
            Download Form
          </Button>
          <hr />
        </div>
        <div className="space-y-4">
          <Typography size="medium-paragraph">
            2. Take to your nearest pathology collection center to get your
            hormones tested.
          </Typography>
          <EmbeddedMap searchQueryString={mapsSearchText ?? undefined} />
          <Typography size="medium-paragraph">
            If you&rsquo;re having problems finding a center please&nbsp;
            {pathologyLocation?.url && (
              <span>
                <a
                  href={pathologyLocation?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-secondary-electric-purple-200 font-semibold"
                >
                  search for your nearest {pathologyClinic} clinic
                </a>
              </span>
            )}
            &nbsp;or email us at&nbsp;
            <a
              href="mailto:hello@kinfertility.com.au"
              target="_blank"
              rel="noreferrer"
              className="text-secondary-electric-purple-200 font-semibold"
            >
              hello@kinfertility.com.au.
            </a>
          </Typography>
          <hr />
          <Typography size="medium-paragraph">
            3. We&rsquo;ll send you a secure link to book in a consult with your
            Kin doctor to discuss your results.
          </Typography>
        </div>
      </div>
    </PaymentInfoCard>
  );
};
