import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@eucalyptusvc/design-system';
import { white } from '@eucalyptusvc/design-system/src/theme/shared';
import { getConfig } from '@customer-frontend/config';
import { Brand } from '@customer-frontend/types';

interface OfflineProps {
  brandName: string;
  image?: string;
}

const getTextColor = (brand: Brand): string | undefined => {
  switch (brand) {
    case 'pilot':
      return white;
    default:
      return;
  }
};

export const OfflinePage: React.FC<OfflineProps> = ({ brandName, image }) => {
  const { brand } = getConfig();

  const textColor = getTextColor(brand);

  return (
    <div className="h-screen flex max-w-screen-lg py-10 md:py-12 px-4 mx-auto">
      <div className="flex flex-col-reverse md:flex-row w-full items-center justify-center">
        <div className="flex-col mt-10 md:mr-10 w-3/5 space-y-2">
          <Typography element="h1" size="xl" color={textColor} isBold>
            <FormattedMessage
              defaultMessage="{brandName} is offline right now"
              values={{ brandName }}
            />
          </Typography>
          <Typography size="paragraph" color={textColor}>
            <FormattedMessage
              defaultMessage="Bear with us as we take care of a few issues to make your experience better."
              description="Message that colloquially requests users be patient, as our system is undergoing maintence to make their experience better."
            />
          </Typography>
        </div>
        {image && (
          <div className="w-1/8 md:w-2/5 md:h-1/2">
            <img src={image} className="w-full h-full object-contain" />
          </div>
        )}
      </div>
    </div>
  );
};
