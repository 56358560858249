import { ComponentStyleConfig } from '@chakra-ui/react';

import {
  error100,
  error500,
  getImageUploadStyles,
  imageUploadParts as parts,
} from '../../shared';
import { colors } from '../palette';

export const ImageUpload: ComponentStyleConfig = {
  parts,
  baseStyle: (props) =>
    getImageUploadStyles({
      props,
      styleOverrides: {
        container: {
          borderRadius: '0.25rem',
          bg: props.hasError ? error100 : colors.primary[100],
          border: props.hasError ? `1px solid ${error500}` : 'none',
        },
        placeholder: {
          border: `1px dashed ${colors.primary[500]}`,
          backgroundColor: colors.tertiary.cream,
        },
      },
    }),
};
