import { getWithFallbacks } from '../../shared/fonts';
import {
  getLinkButtonVariants,
  getListButtonVariants,
  getToggleTabsVariants,
} from '../../shared/components';
import colors from '../palette';

export const button = {
  baseStyle: {
    fontFamily: getWithFallbacks('Inter'),
    fontWeight: 600,
    borderRadius: '0.25rem', // 4px
    _focus: {
      outline: 'none',
      boxShadow: `0 0 0 0.188rem ${colors.primary[200]}`, // 3px
    },
    _disabled: {
      opacity: 0.3,
    },
  },
  sizes: {
    sm: {
      h: '2.125rem', // 34px
      px: '1rem', // 16px
      fontSize: '0.75rem', // 12px
    },
    md: {
      h: '3rem', // 48px
      px: '1.5rem', // 24px
      fontSize: '1rem', // 16px
    },
    lg: {
      h: '3.5rem', // 56px
      px: '1.5rem', // 24px
      fontSize: '1.125rem', // 18px
    },
  },
  variants: {
    primary: {
      color: 'white',
      bg: colors.primary[400],
      _hover: {
        bg: colors.primary[500],
        _disabled: {
          bg: colors.primary[400],
        },
      },
      _focus: {
        bg: colors.primary[500],
      },
    },
    'primary-alternate': {
      color: colors.secondary.navy[300],
      bg: colors.secondary.sky[300],
      _hover: {
        bg: colors.secondary.sky[200],
        _disabled: {
          bg: colors.secondary.sky[300],
        },
      },
      _focus: {
        bg: colors.secondary.sky[200],
        boxShadow: `0 0 0 0.188rem ${colors.secondary.sky[300]}`, // 3px
      },
    },
    'primary-white': {
      color: colors.primary[400],
      bg: 'white',
      _hover: {
        _disabled: {
          bg: 'white',
        },
      },
      _focus: {
        boxShadow: `0 0 0 0.188rem ${colors.primary[200]}`, // 3px
      },
    },
    secondary: {
      color: colors.primary[400],
      bg: 'transparent',
      border: `0.063rem solid ${colors.primary[400]}`, // 1px
      _focus: {
        boxShadow: `0 0 0 0.188rem ${colors.primary[200]}`, // 3px
      },
    },
    'secondary-alternate': {
      color: colors.secondary.navy[300],
      bg: 'transparent',
      border: `0.063rem solid ${colors.secondary.navy[300]}`, // 1px
      _focus: {
        boxShadow: `0 0 0 0.188rem ${colors.primary[200]}`, // 3px
      },
    },
    'secondary-white': {
      color: 'white',
      bg: 'transparent',
      border: `0.063rem solid white`, // 1px
      _hover: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
      _focus: {
        bg: 'rgba(255, 255, 255, 0.3)',
        boxShadow: `0 0 0 0.188rem ${colors.primary[200]}`, // 3px
      },
      _disabled: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
    },
    ...getLinkButtonVariants({
      default: {
        color: colors.primary[400],
        hoverColor: colors.primary[400],
        focusColor: colors.primary[200],
        textDecoration: 'underline',
      },
      alternate: {
        color: colors.primary[500],
        hoverColor: colors.primary[600],
        focusColor: colors.primary[200],
        textDecoration: 'underline',
      },
      white: {
        color: colors.neutral.white,
        hoverColor: colors.secondary.sky[200],
        focusColor: colors.secondary.sky[200],
        textDecoration: 'underline',
      },
    }),
    ...getListButtonVariants({
      textColor: colors.primary[600],
      focusColor: colors.primary[200],
      focusBackground: colors.primary[100],
      border: {
        default: colors.primary[300],
        hover: colors.primary[500],
        active: colors.primary[400],
        error: colors.status.error[500],
      },
      background: colors.neutral.white,
    }),
    ...getToggleTabsVariants({
      focusColor: colors.primary[200],
      fontColor: colors.primary[400],
      selectedColor: colors.primary[100],
      fontWeight: 600,
      borderColor: {
        default: colors.primary[300],
        selected: colors.primary[400],
      },
    }),
  },
};
