import { IEucalyptusEvent } from '../types';

/**
 * The type of element that is being interacted with.
 *
 * This should usually map to the type of component the event is fired from.
 */
export enum UIInteractionElementType {
  BUTTON = 'button',
  LIST_BUTTON = 'listButton',
  LINK = 'link',
  ACCORDION = 'accordion',
}

/**
 * The interaction that occured on the specified element.
 *
 * This should be past tense to indicate the interaction has occured.
 */
export enum UIInteractionInteraction {
  CLICKED = 'clicked',
  OPENED = 'opened',
  CLOSED = 'closed',
}

/**
 * The unique identifier for your element. This should be unique so we can accurately analyse the usage of each
 *
 * Recommended format (camelcase): <context_or_screen><identifier><element_type>
 *
 * e.g homePageDiscoverAccordion, accountPageChangePasswordButton, signupPagePrivacyPolicyLink
 *
 * NOTE: If you need to change this value for your element, please instead create anew.
 */
export type UIInteractionElementName =
  | 'accountPageBillingDetailsCancelButton'
  | 'accountPageBillingDetailsSaveButton'
  | 'accountPageCancelButton'
  | 'accountPageChangePasswordButton'
  | 'accountPageChangePasswordCancelButton'
  | 'accountPageChangePasswordSubmitButton'
  | 'accountPageEditButton'
  | 'accountPagePersonalisedOptInSaveButton'
  | 'accountPageSaveButton'
  | 'accountPageShippingDetailsCancelButton'
  | 'accountPageShippingDetailsEditButton'
  | 'accountPageShippingDetailsSubmitButton'
  | 'consultationReminderCardButton'
  | 'consultationSendMessageButton'
  | 'consultationSendQuestionButton'
  | 'faqsGeneralHelpUrlButton'
  | 'logoutButton'
  | 'logoutButton'
  | 'opConfirmationAppleDownloadButton'
  | 'opConfirmationGoogleDownloadButton'
  | 'otcProductCardAddButton'
  | 'otcProductCardBuyNowButton'
  | 'otcProductCardRemoveButton'
  | 'profileProgressInfoCardButton'
  | 'progressChecklistButton'
  | 'progressSummaryPageAddProgressButton'
  | 'progressSummaryPageCompareButton'
  | 'safetyInfoDownloadButton'
  | 'skinTrackerWhatIsThisModalBackButton'
  | 'skinTrackerWhatIsThisModalButton'
  | 'treatmentPageAwaitingAnswersCardButton'
  | 'treatmentPageAwaitingPaymentButton'
  | 'treatmentPageContinueQuizButton'
  | 'treatmentPageCreatedTreatmentCardButton'
  | 'treatmentPageCreatedTreatmentPrompt'
  | 'treatmentPageDelayButton'
  | 'treatmentPageExploreProductsButton'
  | 'treatmentPageExploreProductsButton'
  | 'treatmentPageGetSupportButton'
  | 'treatmentPageProcessingOrderButton'
  | 'treatmentPageRefillButton'
  | 'treatmentPageRejectedTreatmentButton'
  | 'treatmentPageResumeTreatmentButton'
  | 'treatmentPageReviewConsultationButton'
  | 'treatmentPageReviewTreatmentButton'
  | 'treatmentPageStartFollowUpButton'
  | 'treatmentPageStartQuizButton'
  | 'treatmentPageTriggerReviewButton'
  | 'treatmentPageViewConsultButton'
  | 'treatmentPageViewTreatmentButton'
  | 'treatmentPageViewTreatmentProductsButton'
  | 'treatmentProductsPageBackButton'
  | 'insidersJoinFacebookButton'
  | 'insidersDismissReasonAlreadyJoined'
  | 'insidersDismissReasonDontHaveFacebook'
  | 'insidersDismissReasonNotInterested'
  | 'insidersDismissChangedMind'
  | 'schedulePracBookingImReady'
  | 'purchaseActivationFlowConfirmOrderUpdateShippingButton'
  | 'purchaseActivationFlowConfirmOrderUpdateContactButton'
  | 'purchaseActivationFlowConfirmOrderChangeShippingButton'
  | 'purchaseActivationFlowConfirmOrderChangeContactButton'
  | 'purchaseActivationFlowConfirmOrderUpdateShippingCancelButton'
  | 'purchaseActivationFlowConfirmOrderUpdateContactCancelButton'
  | 'purchaseActivationFlowConfirmOrderButton'
  | 'purchaseActivationFlowReviewContinueButton'
  | 'purchaseActivationFlowPractitionersLetterButton'
  | 'purchaseActivationFlowPharmacyInformationButton'
  | 'purchaseActivationFlowTreatmentPlanScheduleAccordion'
  | 'purchaseActivationFlowWhatsIncludedAccordion'
  | 'purchaseActivationFlowSelectPlanButton'
  | 'purchaseActivationFlowPlanContinueButton'
  | 'purchaseActivationFlowAddDetailsContinueButton'
  | 'purchaseActivationFlowPlanBackButton'
  | 'purchaseActivationFlowAddDetailsBackButton'
  | 'purchaseActivationFlowConfirmOrderBackButton'
  | 'switchOfferingPageContinueButton'
  | 'switchOfferingPageBackButton'
  | 'purchaseActivationFlowAppleDownloadButton'
  | 'purchaseActivationFlowGoogleDownloadButton'
  | 'sunsetBpAppleDownloadButton'
  | 'sunsetBpGoogleDownloadButton'
  | 'profileAppleDownloadButton'
  | 'profileGoogleDownloadButton'
  | 'consultationCompleteAppleDownloadButton'
  | 'consultationCompleteGoogleDownloadButton'
  | 'trackerConfirmationAppleDownloadButton'
  | 'trackerConfirmationGoogleDownloadButton'
  | 'initialPurchaseConfirmUpdateAccountDetails'
  | 'initialPurchaseConfirmUpdateAddress'
  | 'initialPurchaseConfirmPayment'
  | 'initialPurchaseConfirmExpressCheckoutPayment';

export interface UIInteractionElementAttributes {
  consultationId?: string;
}

export interface IUIInteractionTracked extends IEucalyptusEvent {
  interaction: string;
  elementName: UIInteractionElementName;
  elementType: UIInteractionElementType;
  pageUrl: string;
}
