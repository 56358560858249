import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  TextInput,
  Button,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  useEmailValidation,
  useRequiredValidation,
} from '@customer-frontend/utils';
import { useTriggerPasswordResetMutation } from '@customer-frontend/services';

import { ForgotPasswordTemplateProps } from './types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

const fieldMessages = defineMessages({
  email: {
    defaultMessage: 'Email',
  },
  submit: {
    defaultMessage: 'Reset my password',
  },
});

export const ForgotPasswordTemplate = ({
  title,
  description,
  buttonPalette,
  onComplete,
  submitText: submitTextProp,
  linkToLogin = '/login',
}: ForgotPasswordTemplateProps): ReactElement => {
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const submitText = submitTextProp || formatMessage(fieldMessages.submit);

  const [triggerResetPasswordMutation, { loading }] =
    useTriggerPasswordResetMutation({
      onCompleted: () => {
        // For security regardless of results, show the user that the instructions were sent
        notify.success({
          message: formatMessage({
            defaultMessage:
              'Instructions to reset your password have been sent to your email address.',
          }),
        });

        if (onComplete) {
          onComplete();
        }
      },
    });

  const { register, handleSubmit, errors } = useForm<{
    email: string;
  }>();

  const emailValidation = {
    ...useEmailValidation(),
    ...useRequiredValidation(formatMessage(fieldMessages.email)),
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        triggerResetPasswordMutation({
          variables: {
            email: data.email,
          },
        });
      })}
      noValidate
    >
      {(title || description) && (
        <div className="text-center space-y-2 mb-8">
          {title && (
            <Typography element="h1" size="xl" isBold>
              {title}
            </Typography>
          )}
          {description && (
            <Typography size="large-paragraph">{description}</Typography>
          )}
        </div>
      )}
      <div className="mb-8">
        <TextInput
          autoFocus
          ref={register(emailValidation)}
          label={formatMessage(fieldMessages.email)}
          name="email"
          type="email"
          errorMessage={errors?.email?.message}
          required
          palette={buttonPalette}
        />
      </div>
      <Button
        level="primary"
        palette={buttonPalette}
        isLoading={loading}
        isSubmit
        isFullWidth
      >
        {submitText}
      </Button>
      <div className="mt-6 text-center">
        <Typography size="paragraph">
          <Link className="text-link font-semibold" to={linkToLogin}>
            <FormattedMessage defaultMessage="Back to login" />
          </Link>
        </Typography>
      </div>
    </form>
  );
};

export default ForgotPasswordTemplate;
