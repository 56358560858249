import { gql } from '@apollo/client';
import { OfferingHighlightsSequenceFragment } from '@customer-frontend/graphql-types';
import { Typography, TypographySize } from '@eucalyptusvc/design-system';
import { ReactComponent as Snowflake } from '../../assets/snowflake.svg';
import { ReactComponent as DollarCoin } from '../../assets/dollar-coin.svg';
import { ReactComponent as DeleteCross } from '../../assets/delete-cross.svg';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { getConfig } from '@customer-frontend/config';

function OfferingHighlights({
  sequence,
  styles,
}: {
  sequence: OfferingHighlightsSequenceFragment | null;
  styles: {
    textSize: TypographySize;
    iconDimension: number;
    textClassName: string;
  };
}) {
  const config = getConfig();

  let containsRefrigeratedContents = false;
  if (sequence?.__typename === 'PrescribableSequence') {
    containsRefrigeratedContents =
      sequence.containsRefrigeratedContents ?? false;
  }

  const iconClassNames = clsx('flex-shrink-0', {
    'mt-1.5': styles.textSize === 'large-paragraph',
    'mt-1': styles.textSize === 'paragraph',
  });

  return (
    <div className={clsx('space-y-4', styles.textClassName)}>
      {containsRefrigeratedContents && (
        <div className="flex flex-row space-x-3 items-start">
          <Snowflake
            className={iconClassNames}
            width={styles.iconDimension}
            height={styles.iconDimension}
          />
          <Typography size={styles.textSize}>
            <FormattedMessage
              defaultMessage="Medication requires cold storage."
              description="Review treatment page"
            />
          </Typography>
        </div>
      )}
      <div className="flex flex-row space-x-3 items-start">
        <DollarCoin
          className={iconClassNames}
          width={styles.iconDimension}
          height={styles.iconDimension}
        />
        {config.purchaseActivationFlow?.moneyBackGuaranteeDays && (
          <Typography size={styles.textSize}>
            <FormattedMessage
              defaultMessage="{days}-day money-back guarantee."
              description="Review treatment page"
              values={{
                days: config.purchaseActivationFlow.moneyBackGuaranteeDays,
              }}
            />
          </Typography>
        )}
      </div>
      <div className="flex flex-row space-x-3 items-start">
        <DeleteCross
          className={iconClassNames}
          width={styles.iconDimension}
          height={styles.iconDimension}
        />
        <Typography size={styles.textSize}>
          <FormattedMessage
            defaultMessage="Pause or cancel your treatment anytime."
            description="Review treatment page"
          />
        </Typography>
      </div>
    </div>
  );
}

OfferingHighlights.sequenceFragment = gql`
  fragment OfferingHighlightsSequence on Sequence {
    id
    ... on PrescribableSequence {
      containsRefrigeratedContents
    }
  }
`;

export { OfferingHighlights };
