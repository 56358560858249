import {
  AccordionButton,
  AccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { Typography } from '../typography';
import { AccordionItemProps } from './types';
import { getTextSizeProp } from './utils';

export const AccordionItem = ({
  size,
  title,
  content,
  headingLevel = 3,
  trimBottom,
}: AccordionItemProps): React.ReactElement => {
  const textSize = getTextSizeProp(size);

  return (
    <ChakraAccordionItem
      borderBottom={trimBottom ? 0 : undefined}
      paddingBottom={trimBottom ? 0 : undefined}
    >
      <Box role="heading" aria-level={headingLevel}>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {typeof title === 'string' ? (
              <Typography element={`h${headingLevel}`} size="xs" isBold>
                {title}
              </Typography>
            ) : (
              title
            )}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Box>
      <AccordionPanel>
        {typeof content === 'string' ? (
          <Typography size={textSize} inheritColor>
            {/* FIXME: temporary workaround to ensure v2 Markdown has correct colour */}
            <Box
              sx={{
                '& .prose, & .prose *': {
                  color: 'inherit',
                },
              }}
            >
              {content}
            </Box>
          </Typography>
        ) : (
          content
        )}
      </AccordionPanel>
    </ChakraAccordionItem>
  );
};
