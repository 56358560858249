export const sansFallbacks = [
  'ui-sans-serif',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
];

export const getWithFallbacks = (font: string): string =>
  [font, ...sansFallbacks].join(', ');
