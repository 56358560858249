import { Button as ChakraButton } from '@chakra-ui/react';
import { useThemeExtension } from '../../../theme/shared';
import { ButtonPalette, LinkButtonProps } from '../types';
import {
  UIInteractionElementType,
  UIInteractionInteraction,
  useEventServiceDirectContext,
} from '@customer-frontend/events';

/* Maps props for LinkButton to an underlying Chakra Button variant name */
const getLinkButtonVariant = (palette: ButtonPalette): string => {
  return `link${palette !== 'default' ? `-${palette}` : ''}`;
};

/**
 * Sometimes, a regular `Button` is too big or loud for an action. In these
 * scenarios, a **LinkButton** is useful; for example:
 * * There's already a button on the page with the highest priority,
 * * There's no space to fit a button,
 * * The action needs to be clickable, but it's not important enough to be eye-catching
 */
export const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  size = 'md',
  palette,
  iconLeft,
  iconRight,
  isDisabled = false,
  eventElementName,
  eventElementAttributes,
  onClick = (): void => {
    // noop
  },
}) => {
  const eventService = useEventServiceDirectContext();
  // If component explicitly has `palette` passed, use that. Otherwise, use
  // the value from the brand's theme extension config, or default if none.
  const colorProfile = useThemeExtension<ButtonPalette>(
    ['global.defaultColorProfile', 'button.defaultColorProfile'],
    'default',
  );
  const variant = getLinkButtonVariant(palette || colorProfile);

  const handleClick: LinkButtonProps['onClick'] = (e) => {
    const currentPageUrl = window.location.toString();

    onClick(e);

    if (eventElementName && eventService) {
      eventService.uiInteractionTracked({
        ...eventElementAttributes,
        elementName: eventElementName,
        elementType: UIInteractionElementType.BUTTON,
        interaction: UIInteractionInteraction.CLICKED,
        pageUrl: currentPageUrl,
      });
    }
  };

  return (
    <ChakraButton
      colorScheme="brand"
      size={size}
      variant={variant}
      leftIcon={iconLeft}
      rightIcon={iconRight}
      isDisabled={isDisabled}
      onClick={handleClick}
      h="initial" // Ignore sizes config
      px="initial" // Ignore sizes config
    >
      {children}
    </ChakraButton>
  );
};
