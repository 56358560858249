import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  Typography,
  useResponsive,
} from '@eucalyptusvc/design-system';
import * as palette from '@eucalyptusvc/design-system/src/theme/shared/palette';

const FormattedListItem: React.FC<{
  children?: React.ReactNode;
}> = ({ children }): React.ReactElement => {
  return (
    <li>
      <div className="flex gap-2">
        <div className="w-2">
          {/* eslint-disable-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
          •
        </div>
        <div className="flex-1">
          <Typography inheritColor size="medium-paragraph">
            {children}
          </Typography>
        </div>
      </div>
    </li>
  );
};

export const UnassignedDoctorBanner: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isMobile } = useResponsive();

  const triggerModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={triggerModal}
        title={
          <Typography size="md" isBold color="black">
            <FormattedMessage
              defaultMessage="Requiring urgent medical assistance"
              description="Modal title for unassigned consultation banner in the consultation chat page"
            />
          </Typography>
        }
      >
        <div className="space-y-4 text-black">
          <Typography
            element="span"
            size="medium-paragraph"
            isBold
            inheritColor
          >
            <FormattedMessage
              defaultMessage="If you are experiencing a medical emergency, please call (000) or attend your closest Emergency Department immediately."
              description="Boldened disclaimer prompting the user to call emergency services in case of an emergency"
            />
          </Typography>
          {/* eslint-disable-line formatjs/no-literal-string-in-jsx */}{' '}
          <Typography element="span" size="medium-paragraph" inheritColor>
            <FormattedMessage
              defaultMessage="This may include but is not limited to, severe abdominal pain, chest pain, breathing difficulties, loss of consciousness, numbness or circulation issues to the face, arm or leg or any sign of a severe allergic reaction, like swelling of the tongue, throat or lips."
              description="List of potentially life treatening symptoms"
            />
          </Typography>
          <Typography size="medium-paragraph" inheritColor>
            <FormattedMessage defaultMessage="If you need an immediate answer to any medical concerns, please refer to the resources below for support:" />
          </Typography>
          <ul>
            <FormattedListItem>
              <FormattedMessage defaultMessage="HealthDirect on 1800 022 222 - for health information & advice" />
            </FormattedListItem>
            <FormattedListItem>
              <FormattedMessage defaultMessage="LifeLine on 13 11 14 or Beyond Blue on 1300 22 4636 - If you need immediate attention to your mental health concerns" />
            </FormattedListItem>
          </ul>
        </div>
        <div className="mt-10">
          <Button isFullWidth palette="alternate" onClick={triggerModal}>
            <FormattedMessage defaultMessage="Got it" />
          </Button>
        </div>
      </Modal>
      <div className="bg-primary-100 w-full border-b border-primary-300 px-4 py-2">
        <div className="px-2 flex-col text-center">
          <Typography color={palette.black} size="paragraph">
            <FormattedMessage
              defaultMessage="If you require urgent medical assistance, <a>please {isMobile, select, true {tap} other {click}} here</a>."
              values={{
                isMobile,
                a: (chunks) => (
                  <a
                    className="underline cursor-pointer"
                    onClick={triggerModal}
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
        </div>
      </div>
    </>
  );
};
