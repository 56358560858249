import { Brand } from '@customer-frontend/types';

export type ReadableBrandName = 'Juniper' | 'Kin' | 'Pilot' | 'Software';

export const getReadableBrandName = (brand: Brand): ReadableBrandName => {
  switch (brand) {
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
    case 'juniper':
      return 'Juniper';
    case 'kin':
      return 'Kin';
    case 'pilot':
      return 'Pilot';
    case 'software':
      return 'Software';
  }
};
