import { Typography } from '@eucalyptusvc/design-system';
import type { Maybe } from '@customer-frontend/graphql-types';
import { OTCShippingAddress } from './types';

type OTCShippingDetailsDisplayData = {
  fullName?: Maybe<string>;
  email?: Maybe<string>;
  phone?: Maybe<string>;
  address?: Maybe<OTCShippingAddress>;
};

export type OTCShippingDetailsDisplayProps = {
  data: OTCShippingDetailsDisplayData;
};

export const OTCShippingDetailsDisplay = ({
  data,
}: OTCShippingDetailsDisplayProps): JSX.Element => {
  const { fullName, email, phone, address } = data;

  return (
    <div className="space-y-1">
      {fullName && <Typography size="medium-paragraph">{fullName}</Typography>}
      {(address?.line1 || address?.line2 || address?.city) && (
        <Typography size="medium-paragraph">
          {address.line1} {address.line2} {address.city}
        </Typography>
      )}
      {(address?.state || address?.postalCode || address?.country) && (
        <Typography size="medium-paragraph">
          {address.state} {address.postalCode} {address.country}
        </Typography>
      )}
      {email && <Typography size="medium-paragraph">{email}</Typography>}
      {phone && <Typography size="medium-paragraph">{phone}</Typography>}
    </div>
  );
};
