import React from 'react';
import clsx from 'clsx';

import {
  DiscountCodeFormFragment,
  useProfileOtcPurchaseDetailsQuery,
} from '@customer-frontend/graphql-types';
import {
  Button,
  LoadingSpinner,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  calculateDiscountedPrice,
  DiscountCodeForm,
  formatCentsToCurrency,
  getDefaultPaymentMethod,
  useOTCCart,
} from '@customer-frontend/order';

import { OTCOrderDetails, OTCOrderDetailsList } from './otc-order-details';
import { OTCShippingDetails } from './otc-shipping-details';
import { OTCPaymentDetails } from './otc-payment-details';

import { hasShippingDetailsComplete, calculateTotalOrderPrice } from './utils';
import { FeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';

type OTCPurchaseProps = {
  className?: string;
  onCompleted?: () => void;
  enableCart: boolean;
};

export const OTCPurchase = ({
  className,
  onCompleted,
  enableCart,
}: OTCPurchaseProps): JSX.Element => {
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const { loading: loadingProfileDetails, data: profileDetailsData } =
    useProfileOtcPurchaseDetailsQuery({ fetchPolicy: 'cache-first' });

  const { cartItems, purchaseCart, purchaseLoading } = useOTCCart();
  const [discount, setDiscount] = React.useState<DiscountCodeFormFragment>();

  const products = React.useMemo(() => {
    return cartItems.map(({ variant, quantity }) => {
      return { id: variant.product.id, price: variant.price, quantity };
    });
  }, [cartItems]);

  const subtotal = calculateTotalOrderPrice(cartItems);
  const discountedTotal = React.useMemo(() => {
    const { totalPrice } = calculateDiscountedPrice({
      subtotal,
      discount,
      products,
    });
    return totalPrice;
  }, [subtotal, discount, products]);

  const handleConfirmPayment = async (): Promise<void> => {
    try {
      await purchaseCart(discount?.code);

      notify.success({ message: 'Purchase successful 🎉' });

      onCompleted?.();
    } catch (err) {
      const descriptions = getErrorMessageDescriptorsFromError(err);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    }
  };

  if (loadingProfileDetails) {
    return (
      <div className="flex justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  const profile = profileDetailsData?.profile;

  const paymentDetailsComplete = !!getDefaultPaymentMethod(
    profile?.savedPaymentMethods,
  );
  const shippingDetailsComplete = hasShippingDetailsComplete(profile);

  return (
    <div className={clsx('flex flex-col min-h-0', className)}>
      <div className="flex-shrink overflow-auto space-y-5 p-6">
        <section className="space-y-4">
          <div className="space-y-4">
            <Typography element="h3" size="md" isBold>
              <FormattedMessage defaultMessage="Order details" />
            </Typography>
            {enableCart ? <OTCOrderDetailsList /> : <OTCOrderDetails />}
          </div>
        </section>
        {profile && (
          <section className="border border-primary-600 rounded-md p-5">
            <OTCShippingDetails
              defaultIsEditing={!shippingDetailsComplete}
              hideCancel={!shippingDetailsComplete}
              data={profile}
            />
          </section>
        )}
        <section className="border border-primary-600 rounded-md p-5">
          <OTCPaymentDetails
            defaultPaymentGateway={profile?.defaultPaymentGateway}
            savedPaymentMethods={profile?.savedPaymentMethods}
            hasZip={!!profile?.zip}
          />
        </section>
        <FeatureFlagBoolean flagKey="OTC_CART_DISCOUNTS">
          <DiscountCodeForm
            code={discount}
            stage="OTC_CART"
            subtotal={subtotal}
            onChange={(discount) => setDiscount(discount)}
            products={products}
          />
        </FeatureFlagBoolean>
      </div>
      <div className="mt-auto space-y-4 p-6 shadow-footer">
        <div className="space-y-2">
          <div className="flex justify-between">
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Shipping"
                description="Label for the word shipping next to what the shipping type will be"
              />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Free & Express"
                description="Refers to the type of shipping the order will have"
              />
            </Typography>
          </div>
          {discount && (
            <div className="flex justify-between">
              <Typography size="medium-paragraph">
                <FormattedMessage defaultMessage="Discount" />
              </Typography>
              <div className="flex gap-2">
                <Typography size="medium-paragraph">
                  {/* eslint-disable react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
                  -{formatCentsToCurrency(subtotal - discountedTotal)}
                </Typography>
              </div>
            </div>
          )}
          <div className="flex justify-between">
            <Typography size="medium-paragraph" isBold>
              <FormattedMessage
                defaultMessage="Total"
                description="Total cost of the order"
              />
            </Typography>
            <Typography size="medium-paragraph" isBold>
              {formatCentsToCurrency(discountedTotal)}
            </Typography>
          </div>
        </div>
        <Button
          isLoading={purchaseLoading}
          isDisabled={!paymentDetailsComplete || !shippingDetailsComplete}
          isFullWidth
          onClick={handleConfirmPayment}
        >
          <FormattedMessage
            defaultMessage="Confirm payment"
            description="Button text for the user to confirm a payment"
          />
        </Button>
      </div>
    </div>
  );
};
