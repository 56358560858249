import { useLocation } from 'react-router-dom';

export const useQueryParam = (key: string): string | null => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(key);
};

export const useQueryParams = (): Map<string, string> => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return new Map(queryParams);
};
