import {
  gql,
  QueryTuple,
  useLazyQuery,
  OperationVariables,
  LazyQueryHookOptions,
} from '@apollo/client';

const refreshUserPaymentDataDocument = gql`
  query RefreshUserPaymentDataProfile {
    profile {
      id
      zip {
        valid
      }
      defaultPaymentGateway
    }
  }
`;

export const useRefreshPaymentDataQuery = (
  options?: LazyQueryHookOptions<Response, OperationVariables>,
): QueryTuple<Response, OperationVariables> =>
  useLazyQuery<Response, OperationVariables>(
    refreshUserPaymentDataDocument,
    options,
  );
