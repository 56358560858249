import { Button as ChakraButton } from '@chakra-ui/react';
import { ButtonPalette, ButtonProps } from './types';
import { getButtonVariant } from './utils';
import { useThemeExtension } from '../../theme/shared';
import {
  UIInteractionElementType,
  UIInteractionInteraction,
  useEventServiceDirectContext,
} from '@customer-frontend/events';

/** A **Button** represents available actions that a user can take to influence
 * the overall state of a system or complete an objective.
 */
export const Button: React.FC<ButtonProps> = ({
  children,
  level = 'primary',
  size = 'md',
  palette,
  iconLeft,
  iconRight,
  role,
  eventElementName,
  eventElementAttributes,
  isSubmit = false,
  isDisabled = false,
  isFullWidth = false,
  isLoading = false,
  onClick = (): void => {
    // noop
  },
  ...buttonProps
}) => {
  const eventService = useEventServiceDirectContext();

  // If component explicitly has `palette` passed, use that. Otherwise, use
  // the value from the brand's theme extension config, or default if none.
  const colorProfile = useThemeExtension<ButtonPalette>(
    ['global.defaultColorProfile', 'button.defaultColorProfile'],
    'default',
  );
  const variant = getButtonVariant(level, palette || colorProfile);

  const handleClick: ButtonProps['onClick'] = (e) => {
    const currentPageUrl = window.location.toString();

    onClick(e);

    if (eventElementName && eventService) {
      eventService.uiInteractionTracked({
        ...eventElementAttributes,
        elementName: eventElementName,
        elementType: UIInteractionElementType.BUTTON,
        interaction: UIInteractionInteraction.CLICKED,
        pageUrl: currentPageUrl,
      });
    }
  };

  return (
    <ChakraButton
      type={isSubmit ? 'submit' : 'button'}
      colorScheme="brand"
      size={size}
      variant={variant}
      leftIcon={iconLeft}
      rightIcon={iconRight}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      isLoading={isLoading}
      onClick={handleClick}
      role={role}
      {...buttonProps}
    >
      {children}
    </ChakraButton>
  );
};
