import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import {
  DiscountCodeFormFragment,
  Maybe,
} from '@customer-frontend/graphql-types';
import {
  useConfirmOrder,
  useEnablePaymentIntent,
  useUpdateProfileWithAddressMutation,
  useZipCheckout,
} from '@customer-frontend/services';
import { useNotification } from '@eucalyptusvc/design-system';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { saveDiscountToLocalStorage } from '../../discounts';
import { UserAddressPayload } from '../../shared-types';
import { useGetStripeToken } from '../use-get-stripe-token';
import {
  PaymentPayload,
  ReturnType,
  StripePaymentData,
  UsePaymentHandlerProps,
} from './types';

const redirectToZip = (url: string): void => {
  window.location.assign(url);
};

export const usePaymentHandler = ({
  user,
  gateway,
  discount,
  consultation,
  detailsChanged,
  onPaymentSuccess,
  paymentIntentReturnUrl,
  isUpfrontPayment,
}: UsePaymentHandlerProps): ReturnType => {
  const events = useEventService();
  const config = getConfig();
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const [updateProfileMutation] = useUpdateProfileWithAddressMutation();

  const stripe = useStripe();
  const stripeElements = useElements();

  const [loading, setLoading] = useState<boolean>(false);

  const [confirmOrder] = useConfirmOrder();
  const [initiateZipCheckoutMutation] = useZipCheckout();

  const enableStripePaymentIntent = useEnablePaymentIntent();
  const getStripeToken = useGetStripeToken();

  const initiateZipCheckout = useCallback(
    async (
      orderId: Maybe<string> | null,
      paymentPayload: {
        address: UserAddressPayload;
        discount?: DiscountCodeFormFragment;
      },
      phone?: string | null,
    ) => {
      const { address, discount } = paymentPayload;

      if (!orderId) {
        throw new Error('Unable to process zip for this order');
      }

      if (discount) {
        saveDiscountToLocalStorage('ORDER_PAID', discount.code);
      }

      const response = await initiateZipCheckoutMutation({
        variables: {
          address, // shippingAddress
          couponCode: discount?.code,
          redirectUrl: window.location.href,
          orderId: orderId,
          phone,
        },
      });

      if (!response?.data) {
        throw new Error('Unable to initiate zip checkout');
      }

      return response.data.initiateZipCheckout.redirectUrl;
    },
    [initiateZipCheckoutMutation],
  );

  const handleZipPayment = async (payload: PaymentPayload): Promise<void> => {
    await confirmOrder({
      variables: {
        gateway: 'ZIP',
        consultationId: consultation.id,
        ...payload,
      },
    });
  };

  const handleStripePayment = async (
    payload: PaymentPayload,
  ): Promise<void> => {
    let stripePaymentData: Maybe<StripePaymentData>;
    if (detailsChanged && !isUpfrontPayment) {
      if (!stripe || !stripeElements) {
        return;
      }

      if (!enableStripePaymentIntent) {
        const { token, error } = await getStripeToken(payload.stripeEvent);

        if (error || !token?.card) {
          throw new Error(
            error?.message ??
              formatMessage({
                defaultMessage: 'Payment method is invalid.',
              }),
          );
        }

        stripePaymentData = {
          token: token.id,
        };
      } else {
        if (paymentIntentReturnUrl) {
          await updateProfileMutation({
            variables: {
              ...payload,
            },
          });

          const returnUrl = new URL(paymentIntentReturnUrl);
          if (payload.couponCode) {
            returnUrl.searchParams.set('couponCode', payload.couponCode);
          }
          const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements: stripeElements,
            confirmParams: {
              return_url: returnUrl.toString(),
            },
          });
          if (error) {
            throw new Error(
              error?.message ??
                formatMessage({
                  defaultMessage: 'Failed to confirm your order',
                  description: 'Error message for failing to confirm order',
                }),
            );
          }
        } else {
          throw new Error(
            formatMessage({
              defaultMessage: 'Missing returnUrl',
              description: 'Error message for failing to handle payment',
            }),
          );
        }
      }
    }

    try {
      await confirmOrder({
        variables: {
          consultationId: consultation.id,
          gateway: 'STRIPE',
          ...payload,
          ...stripePaymentData,
        },
      });
      payload.stripeEvent?.complete('success');
    } catch (e) {
      payload.stripeEvent?.complete('fail');
      throw e;
    }
  };

  const handlePayment = async (payload: PaymentPayload): Promise<void> => {
    try {
      setLoading(true);
      if (gateway === 'ZIP') {
        if (user.zip?.valid || payload.zipCheckoutId) {
          await handleZipPayment(payload);
        } else {
          const zipUrl = await initiateZipCheckout(
            consultation?.order?.id,
            {
              address: payload.address,
              discount,
            },
            payload.phone,
          );
          return redirectToZip(zipUrl);
        }
      }

      if (gateway === 'STRIPE') {
        await handleStripePayment(payload);
      }

      events.order.paid({
        ...payload,
        consultationId: consultation.id,
        country: config.country,
        currency: config.currency,
        orderId: consultation.order?.id ?? '',
        problemType: consultation.type,
        userId: user.id,
        value: payload.value ? (payload.value / 100).toFixed(2) : '0.00',
      });
      onPaymentSuccess();
    } catch (error) {
      const descriptions = getErrorMessageDescriptorsFromError(error);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    handlePayment,
    loading,
  };
};
