import { getConfig } from '@customer-frontend/config';
import { Brand } from '@customer-frontend/types';
import React from 'react';
import { useIntl } from 'react-intl';

const brandToConfigMap: Record<Brand, { site: string; seal: string }> = {
  pilot: {
    site: 'pilot.com.au',
    seal: '4044843',
  },
  software: {
    site: 'skin.software',
    seal: '7433514',
  },
  kin: {
    site: 'kinfertility.com.au',
    seal: '4598364',
  },
  juniper: {
    site: 'myjuniper.com',
    seal: '11758472',
  },
  'juniper-uk': {
    site: 'myjuniper.co.uk',
    seal: '13343242',
  },
  'juniper-de': {
    site: 'myjuniper.com/de',
    seal: '11758472',
  },
  'juniper-jp': {
    site: 'myjuniper.jp',
    seal: '11758472',
  },
};

export const LegitScriptSeal = (): React.ReactElement | null => {
  const { formatMessage } = useIntl();
  const { brand } = getConfig();
  const { site, seal } = brandToConfigMap[brand];

  if (!site || !seal) {
    return null;
  }

  return (
    <a
      href={`https://www.legitscript.com/websites/?checker_keywords=${site}`}
      target="_blank"
      rel="noopener noreferrer"
      title={formatMessage({
        defaultMessage: 'Verify LegitScript Approval',
        description: "Link to verify the product's LegitScript approval",
      })}
    >
      <img
        src={`https://static.legitscript.com/seals/${seal}.png`}
        alt={formatMessage({
          defaultMessage: 'LegitScript approved',
        })}
        width="60"
        height="40"
      />
    </a>
  );
};
