import React from 'react';

import { ReactComponent as KinLogo } from 'assets/kin-logo-v2.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';

import { BackButtonBehaviour } from '@customer-frontend/services';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/routes';

export interface SimpleHeaderProps {
  onBackClick: BackButtonBehaviour | null;
}

export const SimpleHeader = ({
  onBackClick,
}: SimpleHeaderProps): React.ReactElement => {
  return (
    <header className="bg-beige-500 text-white fixed left-0 right-0 top-0 z-20 h-20 shadow-header">
      <nav
        className="px-4 py-3 lg:py-0 shadow-nav lg:px-8 relative z-10 h-full flex items-center justify-between max-w-8xl mx-auto"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="flex-1" role="presentation">
          {onBackClick !== null && (
            <button
              className="h-10 w-10 flex justify-center items-center hover:bg-white text-secondary-electric-purple-200 border border-secondary-electric-purple-200 transition-colors transform rounded cursor-pointer"
              aria-label="Back"
              onClick={onBackClick.callback}
            >
              <ArrowLeft className="w-5" />
            </button>
          )}
        </div>

        <Link
          className="text-primary-purple-500 block p-1 w-14"
          aria-label="Navigate home"
          to={routes.profile}
        >
          <KinLogo aria-label="Kin logo" />
        </Link>
        <div className="flex-1" role="presentation">
          {/* empty element for spacing */}
        </div>
      </nav>
    </header>
  );
};
