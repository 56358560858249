import { SystemStyleObject } from '@chakra-ui/react';
import {
  success100,
  success500,
  error100,
  error500,
  warning100,
  warning500,
  info100,
  info500,
} from '../../shared/palette';

interface ToastThemeProps {
  borderRadius: string;
}

export const getToastComponent = (
  props: ToastThemeProps,
): SystemStyleObject => ({
  Toast: {
    baseStyle: {
      borderRadius: props.borderRadius,
      px: '1rem',
      py: '0.5rem',
      display: 'inline-flex',
      gap: '0.5rem',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0px 8px 8px rgba(0,0,0,0.1)',
    },
    variants: {
      success: {
        color: success100,
        bg: success500,
      },
      error: {
        color: error100,
        bg: error500,
      },
      warning: {
        color: '#000000',
        bg: warning500,
      },
      info: {
        color: info100,
        bg: info500,
      },
      'success-alternate': {
        color: success500,
        bg: success100,
      },
      'error-alternate': {
        color: error500,
        bg: error100,
      },
      'warning-alternate': {
        color: '#000000',
        bg: warning100,
      },
      'info-alternate': {
        color: info500,
        bg: info100,
      },
    },
  },
});
