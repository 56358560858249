import { useState } from 'react';
import {
  FormattedMessage,
  defineMessages,
  defineMessage,
  useIntl,
} from 'react-intl';
import { Typography, Modal } from '@eucalyptusvc/design-system';
import { useEnvironment } from '@customer-frontend/environment';
import { getConfig } from '@customer-frontend/config';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

// This component is currently only used for an Innovation Week experiment in Juniper AU.
// If we want to roll it out more broadly, we'll need to do proper German translations.

const listItemMessages = defineMessages({
  sellData: {
    defaultMessage: 'We do not and will never sell any data to third parties',
  },
  encrypted: {
    defaultMessage: 'All data received remains encrypted at all times',
  },
  restricted: {
    defaultMessage:
      'Data access is restricted to a must-know basis among our internal medical team',
  },
  cybersecurity: {
    defaultMessage:
      'Our Cybersecurity team maintains the strictest standards of patient data privacy',
  },
});

const modalTitle = defineMessage({
  defaultMessage: 'How we protect your data',
});

const PrivacyDataListItem: React.FC<{ content: string }> = ({ content }) => {
  return (
    <li>
      <Typography size="paragraph">{content}</Typography>
    </li>
  );
};

export const DataProtectionModal: React.FC = () => {
  const environment = useEnvironment();
  const config = getConfig();
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <div
        className="flex gap-2 cursor-pointer flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <LockIcon />
        <Typography size="paragraph" decoration="underline">
          <FormattedMessage
            description="The clickable text displayed to open the data privacy modal"
            defaultMessage="How we protect your data"
          />
        </Typography>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={formatMessage(modalTitle)}
      >
        <Typography size="medium-paragraph">
          <FormattedMessage
            description="The header text in the modal"
            defaultMessage={`{title} is committed to protecting your data in accordance with the Privacy Act 1988 (Cth).`}
            values={{
              title: config.title,
            }}
          />
        </Typography>
        <div className="m-4">
          <ul className="list-disc">
            {Object.values(listItemMessages).map((item) => {
              const content = formatMessage(item);
              return <PrivacyDataListItem key={content} content={content} />;
            })}
          </ul>
        </div>
        <Typography size="paragraph">
          <FormattedMessage
            description="Text to inform confidentiality of information provided by patient in questionnaire"
            defaultMessage="For more information, see our <a>Privacy Policy</a>."
            values={{
              a: (chunks) => {
                return (
                  <a
                    href={`${environment.landingPageUrl}/privacy`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link"
                  >
                    {chunks}
                  </a>
                );
              },
            }}
          />
        </Typography>
      </Modal>
    </>
  );
};
