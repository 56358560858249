import { sharedColors } from '../shared/palette';

// primary
export const primary100 = '#FFFFFF';
export const primary200 = '#E6E6E6';
export const primary300 = '#CCCCCC';
export const primary400 = '#737373';
export const primary500 = '#404040';
export const primary600 = '#000000';

// secondary
export const beige100 = '#F8F7F3';
export const beige200 = '#DDD0C0';
export const beige300 = '#C7B49C';
export const yellow100 = '#FFFCEC';
export const yellow200 = '#FFF5B8';
export const yellow300 = '#FFEF8D';

// tertiary (skin goals)
export const blue = '#7CABDA';
export const red = '#ED7575';
export const mauve = '#C88099';
export const green = '#7FC187';
export const yellow = '#FFCB44';

export const colors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
    600: primary600,
  },
  secondary: {
    biege: {
      100: beige100,
      200: beige200,
      300: beige300,
    },
    yellow: {
      100: yellow100,
      200: yellow200,
      300: yellow300,
    },
  },
  tertiary: {
    blue,
    red,
    mauve,
    green,
    yellow,
  },
  reserved: {},
};
