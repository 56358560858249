import { gql, useQuery } from '@apollo/client';
import { useEventService } from '@customer-frontend/events';
import { ProfilePractitionerPageQuery } from '@customer-frontend/graphql-types';
import {
  Accordion,
  Card,
  LoadingSpinner,
  Markdown,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

import { capitaliseString } from '@customer-frontend/utils';
import { isBefore } from 'date-fns';
import { useParams } from 'react-router-dom';
import { DosageContent } from './dosage-content';
import { MedicationContent } from './medication-content';
import { OverviewContent } from './overview-content';
import { SideEffectsContent } from './side-effects-content';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { DoctorBio } from '@customer-frontend/doctor';

const practionerLetterDocument = gql`
  query ProfilePractitionerPage($treatmentId: String!) {
    profile {
      id
      weightTreatmentCategory
    }
    treatment(id: $treatmentId) {
      id
      updatedAt
      product {
        id
        faqs {
          id
          markdown
          title
        }
        variants {
          id
          inventory {
            id
            sku
          }
        }
      }
      weightTreatmentCategory
      consultation {
        id
        status
        type
        completedAt
        prescribedSequence {
          id
          createdAt
          sequence {
            id
            faqs {
              id
              question
              videoUrl
              answerMarkdown
              rank
            }
            products {
              id
              variants {
                id
                inventory {
                  id
                  sku
                }
              }
            }
          }
        }
        doctor {
          id
          firstName
          avatar {
            id
            url
          }
          lastName
          provider {
            id
            clinicianTitle
            qualifications
          }
          shortClinicianName
          fullName
          fullClinicianName
          bio
        }
        patientNotes
      }
    }
    customerAttributes {
      id
      weight
    }
  }
`;
export const PractitionerLetterPageTemplate: React.FC = () => {
  const { treatmentId } = useParams<{ treatmentId: string }>();
  const eventService = useEventService();
  const config = getConfig();
  const { data, loading } = useQuery<ProfilePractitionerPageQuery>(
    practionerLetterDocument,
    { variables: { treatmentId } },
  );

  const weightTreatmentCategory = data?.profile?.weightTreatmentCategory;

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const doctor = data?.treatment?.consultation?.doctor;

  const consultation = data?.treatment?.consultation;

  const productSku = data?.treatment?.product.variants?.[0]?.inventory?.sku;

  const prescribedDate: string | undefined =
    data?.treatment?.consultation?.prescribedSequence?.createdAt ||
    data?.treatment?.updatedAt;

  const handleVideoPlayed = (id: string, videoName: string): void => {
    eventService.dispatchVideoPlayedEvent({
      wistiaVideoId: id,
      videoName,
      consultationStatus: consultation?.status,
      consultationId: consultation?.id,
      problemType: consultation?.type,
      stage: 'ORDER_PAID',
    });
  };

  const showHardcodedDoctorLetterContent =
    isBefore(
      consultation?.completedAt,
      config.glp1TreatmentBreakdownDeprecatedDate,
    ) &&
    (weightTreatmentCategory === 'ozempic' ||
      weightTreatmentCategory === 'saxenda');

  return (
    <div className="space-y-8 pb-28 md:pb-10">
      <div>
        <Typography isBold size="lg">
          <FormattedMessage
            defaultMessage="A letter from {doctorName}"
            values={{
              doctorName: doctor?.shortClinicianName,
            }}
          />
        </Typography>
        <div
          className={`mt-2 ${
            config.brand === 'juniper' ? 'text-primary-500' : 'text-white'
          }`}
        >
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Prescribed by <strong>{clinicianName}</strong>"
              values={{
                clinicianName: doctor?.fullClinicianName,
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="on <strong>{prescribedDate}</strong>"
              values={{
                prescribedDate:
                  prescribedDate &&
                  formatDate(
                    mapBrandToAdaptersBrand(config.brand),
                    prescribedDate,
                    { dateStyle: 'medium' },
                  ),
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </div>
      </div>
      {doctor && (
        <>
          <DoctorBio doctor={doctor} palette="alternate" />

          {!showHardcodedDoctorLetterContent && (
            <Card>
              <div className="text-black">
                {consultation?.patientNotes ? (
                  <Markdown formatWhitespace src={consultation.patientNotes} />
                ) : (
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="No notes are available for this treatment"
                      description="Info message that there aren't any clinician notes for the current treatment"
                    />
                  </Typography>
                )}
              </div>
            </Card>
          )}
          {showHardcodedDoctorLetterContent && (
            <>
              <Card>
                <Accordion
                  title={
                    <Typography size="md" isBold>
                      <FormattedMessage
                        defaultMessage="Overview"
                        description="Overview on practitioner letter page"
                      />
                    </Typography>
                  }
                  palette="light"
                  openByDefault={true}
                  content={
                    <>
                      {consultation?.patientNotes && (
                        <OverviewContent
                          markdown={consultation?.patientNotes}
                          handleVideoPlayed={handleVideoPlayed}
                          weightTreatmentCategory={weightTreatmentCategory}
                        />
                      )}
                    </>
                  }
                />
              </Card>
              <Card>
                <Accordion
                  title={
                    <Typography size="md" isBold>
                      <FormattedMessage
                        defaultMessage="Prescribed medication: {medicationName}"
                        values={{
                          medicationName: capitaliseString(
                            weightTreatmentCategory,
                          ),
                        }}
                      />
                    </Typography>
                  }
                  palette="light"
                  openByDefault={false}
                  content={
                    <MedicationContent
                      initialWeight={data?.customerAttributes?.weight}
                      handleVideoPlayed={handleVideoPlayed}
                      weightTreatmentCategory={weightTreatmentCategory}
                    />
                  }
                />
              </Card>
              <Card>
                <Accordion
                  title={
                    <Typography size="md" isBold>
                      <FormattedMessage defaultMessage="Dosage and administration" />
                    </Typography>
                  }
                  palette="light"
                  openByDefault={false}
                  content={
                    <DosageContent
                      productSku={productSku || ''}
                      handleVideoPlayed={handleVideoPlayed}
                      weightTreatmentCategory={weightTreatmentCategory}
                    />
                  }
                />
              </Card>
              <Card>
                <Accordion
                  title={
                    <Typography size="md" isBold>
                      <FormattedMessage defaultMessage="Managing side effects" />
                    </Typography>
                  }
                  palette="light"
                  openByDefault={false}
                  content={
                    <>
                      <SideEffectsContent
                        handleVideoPlayed={handleVideoPlayed}
                        weightTreatmentCategory={weightTreatmentCategory}
                      />
                    </>
                  }
                />
              </Card>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PractitionerLetterPageTemplate;
