import { Typography, BrandTheme } from '@eucalyptusvc/design-system';
import React, { useState } from 'react';
import { useTheme } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { CustomCheckbox } from './custom-checkbox';
import {
  Maybe,
  PaymentMethodsFragment,
} from '@customer-frontend/graphql-types';
import {
  DefaultCardPaymentBanner,
  DefaultPaypalPaymentBanner,
} from './defaults';
import Visa from '../../assets/payment-methods/visa.webp';
import Amex from '../../assets/payment-methods/amex.webp';
import MasterCard from '../../assets/payment-methods/mastercard.webp';
import clsx from 'clsx';

function StripeCardHeader(props: {
  active: boolean;
  onCheckboxClick: () => void;
  checkboxHidden: boolean;
}) {
  const { active, onCheckboxClick, checkboxHidden } = props;

  return (
    <button
      className={clsx(
        'py-5 px-4 md:px-5 w-full flex justify-between',
        !active && 'cursor-pointer',
      )}
      onClick={!active ? onCheckboxClick : undefined}
      tabIndex={!active ? undefined : -1}
      type="button"
    >
      <div className="flex items-center">
        <CustomCheckbox checked={active} hidden={checkboxHidden} />
        <div className="ml-3">
          <Typography size="paragraph" isBold color="inherit">
            <FormattedMessage defaultMessage="Credit or debit" />
          </Typography>
        </div>
      </div>
      <div className="flex flex-row space-x-1">
        <img className="w-8 h-5" src={Visa} />
        <img className="w-8 h-5" src={MasterCard} />
        <img className="w-8 h-5" src={Amex} />
      </div>
    </button>
  );
}

export function StripePaymentCardContainer(props: {
  singlePaymentProvider: boolean;
  active: boolean;
  setActive: () => void;
  children: React.ReactElement;
}) {
  const { singlePaymentProvider, active, setActive, children } = props;
  const theme = useTheme<BrandTheme>();

  return (
    <div className="flex flex-col">
      {!singlePaymentProvider && (
        <StripeCardHeader
          active={active}
          onCheckboxClick={setActive}
          checkboxHidden={singlePaymentProvider}
        />
      )}

      <div
        className={clsx(
          'flex flex-col space-y-3',
          !singlePaymentProvider && 'px-4 md:px-5 pb-5',
        )}
      >
        <Typography size="paragraph" isBold color={theme.colors.neutral['700']}>
          <FormattedMessage defaultMessage="Saved payment method" />
        </Typography>
        {children}
      </div>
    </div>
  );
}

export function StripeCardInput(props: {
  control: Control<{ payment: { paymentDetailsCompleted: boolean } }>;
  errors: FieldErrors<{ payment: { paymentDetailsCompleted: boolean } }>;
  singlePaymentProvider: boolean;
  active: boolean;
  setActive: () => void;
  defaultPaymentMethod: Maybe<
    NonNullable<PaymentMethodsFragment['savedPaymentMethods']>[number]
  >;
}): React.ReactElement {
  const { formatMessage } = useIntl();

  const { singlePaymentProvider, active, setActive, defaultPaymentMethod } =
    props;
  const [editing, setEditing] = useState<boolean>(!defaultPaymentMethod);

  if (
    !editing &&
    defaultPaymentMethod?.__typename === 'SavedCardPaymentMethod'
  ) {
    return (
      <StripePaymentCardContainer
        singlePaymentProvider={singlePaymentProvider}
        active={active}
        setActive={setActive}
      >
        <DefaultCardPaymentBanner
          setEditing={(editing) => {
            setActive();
            setEditing(editing);
          }}
          defaultPaymentMethod={defaultPaymentMethod}
        />
      </StripePaymentCardContainer>
    );
  } else if (
    !editing &&
    defaultPaymentMethod?.__typename === 'SavedPayPalPaymentMethod'
  ) {
    return (
      <StripePaymentCardContainer
        singlePaymentProvider={singlePaymentProvider}
        active={active}
        setActive={setActive}
      >
        <DefaultPaypalPaymentBanner
          setEditing={(editing) => {
            setActive();
            setEditing(editing);
          }}
          defaultPaymentMethod={defaultPaymentMethod}
        />
      </StripePaymentCardContainer>
    );
  }

  if (!active) {
    return (
      <StripeCardHeader
        active={active}
        onCheckboxClick={setActive}
        checkboxHidden={singlePaymentProvider}
      />
    );
  }

  return (
    <div className="flex flex-col">
      {!singlePaymentProvider && (
        <StripeCardHeader
          active={active}
          onCheckboxClick={setActive}
          checkboxHidden={singlePaymentProvider}
        />
      )}
      <Controller
        name="payment.paymentDetailsCompleted"
        defaultValue={false}
        control={props.control}
        rules={{
          validate(value: unknown) {
            if (value === true) {
              return value;
            }
            return formatMessage({
              defaultMessage: 'Please enter card details',
              description:
                'Pay for consultation page card details required error message',
            });
          },
        }}
        render={(field) => (
          <PaymentElement
            className={
              singlePaymentProvider ? 'border-none md:p-0' : 'px-4 md:px-5 pb-5'
            }
            options={{
              terms: { card: 'never' },
              wallets: { applePay: 'never' },
            }}
            onBlur={() => field.onBlur()}
            onChange={(evt) => {
              field.onChange(evt.complete);
            }}
          />
        )}
      />
      {props.errors.payment?.paymentDetailsCompleted?.message && (
        <div className="text-status-error-500">
          <Typography size="small-text" isBold inheritColor>
            {props.errors.payment?.paymentDetailsCompleted.message}
          </Typography>
        </div>
      )}
    </div>
  );
}
