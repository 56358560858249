import debounce from 'lodash.debounce';
import { ApolloClient, gql, useApolloClient, useQuery } from '@apollo/client';
import {
  InitialConfirmInitiateZipCheckoutMutation,
  InitialConfirmInitiateZipCheckoutMutationVariables,
  InitialPurchaseConfirmAddressRangeQuery,
  InitialPurchaseConfirmAddressRangeQueryVariables,
  InitialPurchaseConfirmQuery,
  InitialPurchaseConfirmQueryVariables,
  InitialPurchaseConfirmUpdateUserMutation,
  InitialPurchaseConfirmUpdateUserMutationVariables,
  OfferingSelectionInput,
  SequenceSelectionInput,
} from '@customer-frontend/graphql-types';
import {
  DeliveryInstructions,
  DeliveryInstructionsFields,
  deliveryInstructionsToFields,
  fieldsToDeliveryInstructions,
  formatCentsToCurrency,
  ShippingValidationStatus,
} from '@customer-frontend/order';
import { ReactComponent as CircleTickOutline } from '../assets/circle-tick-outline.svg';
import { ReactComponent as Dollar } from '../assets/dollar.svg';
import { ReactComponent as Stop } from '../assets/stop.svg';
import { ReactComponent as ThirtyDay } from '../assets/30-day-money-back.svg';
import { ReactComponent as ShippingTruck } from '../assets/shipping-truck-outline.svg';
import {
  Accordion,
  AccordionPalette,
  Button,
  ButtonPalette,
  Card,
  CardPalette,
  Checkbox,
  Divider,
  LoadingSpinner,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { useForm } from 'react-hook-form';
import {
  formatISODateToLocale,
  formatLocaleDateToISO,
  matchesValue,
  mustBeTrue,
  useMinLengthValidation,
  useRequiredValidation,
  unicodeToBase64,
  getZendeskRequestUrl,
  useTitle,
} from '@customer-frontend/utils';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  getConfig,
  useConsultationFlowConfig,
} from '@customer-frontend/config';
import clsx from 'clsx';
import { useEnvironment } from '@customer-frontend/environment';
import {
  IntlAddressInput,
  IntlDOBInput,
  IntlMobileNumberInput,
  AddressFields as AddressFieldsIntl,
} from '@customer-frontend/intl';
import { uiStorages } from '@customer-frontend/ui-storage';
import { UpfrontConsultPayFAQs } from '@customer-frontend/consultation';
import {
  FormPaymentFields,
  PaymentMethods,
  PurchaseOfferingsIntentHandlerInput,
  usePreselectedOfferingSelection,
} from '@customer-frontend/page-templates';
import {
  StripeProvider,
  useBackButtonBehaviour,
} from '@customer-frontend/services';
import { Logger } from '@customer-frontend/logger';
import { EmailConsentCheckbox } from './email-consent-checkbox';
import { PrescriberType } from '@customer-frontend/doctor';
import {
  useFeatureFlagBoolean,
  useFeatureFlagClient,
} from '@customer-frontend/feature-flags';
import { appBlurbMessages } from './app-blurb';

type AddressFields = {
  line1?: string;
  line2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  prefecture?: string;
  municipality?: string;
  townArea?: string;
  country?: string;
};

type FormFields = {
  email?: string;
  password?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  confirmPassword?: string;
  residentialAddress?: AddressFields;
  shippingAddress?: AddressFields;
  couponCode?: string;
  agreedToTerms: boolean;
  shippingAddressProximity?: 'inRange' | 'outOfRange';
  deliveryInstructions?: DeliveryInstructionsFields;
  payment: FormPaymentFields;
};

const validateShippingAddressRange = debounce(
  async (
    shippingAddress: AddressFieldsIntl,
    apollo: ApolloClient<unknown>,
    onComplete: (p: FormFields['shippingAddressProximity']) => void,
  ) => {
    const line1 = shippingAddress?.line1;
    const line2 = shippingAddress?.line2;
    const suburb = shippingAddress?.city;
    const postcode = shippingAddress?.postalCode;

    if (!(line1 && postcode && suburb)) {
      return;
    }

    const query = await apollo.query<
      InitialPurchaseConfirmAddressRangeQuery,
      InitialPurchaseConfirmAddressRangeQueryVariables
    >({
      query: gql`
        query InitialPurchaseConfirmAddressRange(
          $line1: String!
          $line2: String
          $suburb: String!
          $postcode: String!
        ) {
          shippingAddressWithinRange(
            line1: $line1
            line2: $line2
            postcode: $postcode
            suburb: $suburb
          )
        }
      `,
      variables: {
        line1,
        line2,
        suburb,
        postcode,
      },
    });

    onComplete(
      query.data.shippingAddressWithinRange ? 'inRange' : 'outOfRange',
    );
  },
  1000,
);

function Inner({
  consultationId,
  logger,
  routes,
  palette,
}: InitialPurchaseConfirmProps): React.ReactElement {
  const notify = useNotification();
  const environment = useEnvironment();
  const apollo = useApolloClient();
  const form = useForm<FormFields>();
  const { formatMessage } = useIntl();
  const [pricingSessionId] = useState(v4());
  const [purchaseGroupId] = useState(v4());
  const [selections, setSelections] = useState<OfferingSelectionInput[]>();
  const history = useHistory();
  const [addressValidating, setAddressValidating] = useState(false);
  const [appliedCouponCode, setAppliedCouponCode] = useState<string>();
  useBackButtonBehaviour(() => history.push(routes.profile));
  const marketingConsentUpdatedUI = useFeatureFlagBoolean(
    'FF_MARKETING_CONSENT_UI_UPDATES',
  );

  useTitle(
    formatMessage({
      defaultMessage: 'Complete your payment',
      description: 'Page title for the Initial purchase confirm page',
    }),
  );

  const config = getConfig();

  const featureFlagClient = useFeatureFlagClient();
  // evaluated in router but we want to fire the tracking event here
  featureFlagClient.getMultivariate('INITIAL_PURCHASE_CONFIRM_V2');

  const {
    initialPurchaseFaqs,
    initialPurchaseContents,
    initialPurchaseAddresses,
    supportShippingAddressValidation,
    collectDeliveryInstructionsUpfront,
    showMarketingOptInCheckbox,
    showTermsAndConditionsCheckbox,
    initialPurchaseConfirmPersonalDetails,
    showDeliveryConstraintCard,
    initialPurchaseShowSimplifiedShippingAddressForm,
  } = config.purchasePrompts;

  const collectShippingAddress = initialPurchaseAddresses.has('shipping');
  const collectResidentialAddress = initialPurchaseAddresses.has('residential');

  const [showSimplifiedShippingAddress, setShowSimplifiedShippingAddress] =
    useState(initialPurchaseShowSimplifiedShippingAddressForm);
  const stripe = useStripe();
  const stripeElements = useElements();

  const emailLabel = formatMessage({ defaultMessage: 'Email' });
  const emailValidation = useRequiredValidation(emailLabel);

  const confirmPasswordLabel = formatMessage({
    defaultMessage: 'Confirm Password',
    description: 'Pay for consultation page confirm password field label',
  });
  const confirmPasswordValidation = {
    ...useRequiredValidation(confirmPasswordLabel),
    ...matchesValue(form.watch('password') || '', {
      name: 'confirmPassword',
      message: formatMessage({
        defaultMessage: 'Passwords do not match',
        description:
          'Pay for consultation page confirm password does not match password error message',
      }),
    }),
  };

  const passwordLabel = formatMessage({
    defaultMessage: 'Password',
    description: 'Pay for consultation page password field label',
  });
  const passwordValidation = {
    ...useRequiredValidation(passwordLabel),
    ...useMinLengthValidation(passwordLabel, 8),
  };

  const firstNameLabel = formatMessage({
    defaultMessage: 'First name',
    description: 'Pay for consultation page first name field label',
  });
  const firstNameValidation = useRequiredValidation(firstNameLabel);

  const lastNameLabel = formatMessage({ defaultMessage: 'Last name' });
  const lastNameValidation = useRequiredValidation(lastNameLabel);

  const preselectedOfferingSelection = usePreselectedOfferingSelection(logger);

  const query = useQuery<
    InitialPurchaseConfirmQuery,
    InitialPurchaseConfirmQueryVariables
  >(
    gql`
      query InitialPurchaseConfirm(
        $consultationId: String!
        $pricingSessionId: ID!
        $offeringSelections: [OfferingSelectionInput!]!
        $noSelections: Boolean!
        $couponCodes: [String!]
        $proposedOfferingSelectionInput: InitialPurchaseProposedOfferingSelectionInput
      ) {
        initialPurchasePrice(
          consultationId: $consultationId
          pricingSessionId: $pricingSessionId
          offeringSelections: $offeringSelections
          couponCodes: $couponCodes
        ) @skip(if: $noSelections) {
          id
          amount
          originalAmount
          coupons {
            id
            code
            outcome
          }
        }
        validPhoneRegions {
          id
          countryCode
        }
        profile {
          id
          email
          phone
          isPasswordSet
          birthday
          lastName
          firstName
          address {
            id
            city
            line1
            line2
            state
            country
            postalCode
            prefecture
            municipality
            deliveryInstructions
            townArea
          }
          residentialAddress {
            id
            city
            line1
            line2
            state
            country
            postalCode
            prefecture
            municipality
            townArea
          }
          ...PaymentMethods
        }
        consultation(id: $consultationId) {
          id
          type
          purchasePrompt {
            id
            ... on InitialPurchasePrompt {
              proposedOfferingSelection(
                input: $proposedOfferingSelectionInput
              ) {
                id
                offering {
                  id
                  friendlyName
                  advertisedShippingCadence
                  photoUrl
                  tags
                  contents {
                    id
                    rank
                    description
                  }
                }
                sequenceSelections {
                  id
                  sequence {
                    id
                    ... on PrescribableSequence {
                      addressValidationExempt
                    }
                  }
                  sequenceSet {
                    id
                  }
                }
              }
            }
          }
        }
      }
      ${PaymentMethods.fragment}
    `,
    {
      errorPolicy: 'all',
      variables: {
        consultationId,
        pricingSessionId,
        noSelections: !selections,
        offeringSelections: selections ?? [],
        couponCodes: appliedCouponCode ? [appliedCouponCode] : undefined,
        proposedOfferingSelectionInput: preselectedOfferingSelection
          ? {
              selection: preselectedOfferingSelection,
            }
          : undefined,
      },
      onCompleted(data) {
        if (
          data.initialPurchasePrice?.coupons &&
          data.initialPurchasePrice.coupons.length > 1
        ) {
          logger.error('expected none or one coupon');
        }

        if (!form.formState.isDirty) {
          const fields: Partial<FormFields> = {
            email: data.profile?.email,
            phone: data.profile?.phone ?? undefined,
            birthday: data.profile?.birthday
              ? formatISODateToLocale(data.profile.birthday)
              : undefined,
            lastName: data.profile?.lastName ?? undefined,
            firstName: data.profile?.firstName ?? undefined,
          };

          if (collectResidentialAddress) {
            fields.residentialAddress = {
              city: data.profile?.residentialAddress?.city,
              line1: data.profile?.residentialAddress?.line1,
              line2: data.profile?.residentialAddress?.line2 ?? undefined,
              state: data.profile?.residentialAddress?.state ?? undefined,
              country:
                data.profile?.residentialAddress?.country ?? config.country,
              postalCode: data.profile?.residentialAddress?.postalCode,
              prefecture:
                data.profile?.residentialAddress?.prefecture ?? undefined,
              municipality:
                data.profile?.residentialAddress?.municipality ?? undefined,
              townArea: data.profile?.residentialAddress?.townArea ?? undefined,
            };
          }

          if (collectShippingAddress) {
            fields.shippingAddress = {
              city: data.profile?.address?.city ?? undefined,
              line1: data.profile?.address?.line1 ?? undefined,
              line2: data.profile?.address?.line2 ?? undefined,
              state: data.profile?.address?.state ?? undefined,
              country: data.profile?.address?.country ?? config.country,
              postalCode: data.profile?.address?.postalCode ?? undefined,
              prefecture: data.profile?.address?.prefecture ?? undefined,
              municipality: data.profile?.address?.municipality ?? undefined,
              townArea: data.profile?.address?.townArea ?? undefined,
            };
          }

          if (collectDeliveryInstructionsUpfront) {
            fields.deliveryInstructions = deliveryInstructionsToFields(
              data.profile?.address?.deliveryInstructions,
            );
          }

          form.reset(fields);

          if (supportShippingAddressValidation && fields.shippingAddress) {
            validateShippingAddressRange(fields.shippingAddress, apollo, (p) =>
              form.setValue('shippingAddressProximity', p),
            );
          }
        }
      },
    },
  );

  const data = query.data ?? query.previousData;
  const consultation = data?.consultation;
  const profile = data?.profile;
  const purchasePrompt = consultation?.purchasePrompt;
  const ipp = data?.initialPurchasePrice;
  const consultationConfig = useConsultationFlowConfig(consultation?.type);
  const zendeskRequestUrl = getZendeskRequestUrl({
    problemType: consultation?.type,
  });

  const coupon = ipp?.coupons?.[0];

  useEffect(() => {
    setSelections((s) => {
      if (s) {
        // We don't expect this to change after the page has already loaded.
        return s;
      }

      if (purchasePrompt?.__typename !== 'InitialPurchasePrompt') {
        return s;
      }

      const selection = purchasePrompt.proposedOfferingSelection;
      if (!selection?.offering) {
        return s;
      }

      if (!selection.sequenceSelections) {
        return s;
      }

      const sequenceSelections: SequenceSelectionInput[] = [];
      for (const ss of selection.sequenceSelections) {
        if (ss.sequence?.id && ss.sequenceSet?.id) {
          sequenceSelections.push({
            sequenceId: ss.sequence.id,
            sequenceSetId: ss.sequenceSet.id,
          });
        }
      }

      return [{ offeringId: selection.offering.id, sequenceSelections }];
    });
  }, [purchasePrompt]);

  const ffAddressValidationExemption = useFeatureFlagBoolean(
    'FF_ADDRESS_VALIDATION_EXEMPTION',
  );

  if (query.loading && data?.initialPurchasePrice == null) {
    return (
      <div className="flex pt-6 flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!purchasePrompt) {
    logger.error(
      `no purchase prompt for consultation id "${consultationId}", redirecting to profile`,
    );
    return <Redirect to={routes.profile} />;
  }

  if (purchasePrompt.__typename !== 'InitialPurchasePrompt') {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" type was expected to be InitialPurchasePrompt but is "${purchasePrompt.__typename}"`,
    );
    return <Redirect to={routes.profile} />;
  }

  const offeringSelection = purchasePrompt.proposedOfferingSelection;
  if (!offeringSelection) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering selection`,
    );
    return <Redirect to={routes.profile} />;
  }

  const exemptFromShippingValidation =
    offeringSelection?.sequenceSelections?.every((ss) => {
      if (ffAddressValidationExemption === true) {
        return true;
      }

      if (ss.sequence?.__typename !== 'PrescribableSequence') {
        return true;
      }

      if (ss.sequence.addressValidationExempt) {
        return true;
      }

      return false;
    });

  const offering = offeringSelection?.offering;
  if (!offering) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering`,
    );
    return <Redirect to={routes.profile} />;
  }

  if (!stripe) {
    logger.error(`stripe is not set`);
    return <Redirect to={routes.profile} />;
  }

  if (!stripeElements) {
    logger.error(`stripeElements is not set`);
    return <Redirect to={routes.profile} />;
  }

  if (!uiStorages.local.isSupported()) {
    logger.error('local storage is not available');
    return <Redirect to={routes.profile} />;
  }

  let originalAmount: string | undefined;
  let localisedAmount = '—';
  if (typeof ipp?.amount === 'number') {
    localisedAmount = formatCentsToCurrency(ipp.amount);

    if (ipp.originalAmount > ipp.amount) {
      originalAmount = formatCentsToCurrency(ipp.originalAmount);
    }
  }

  const formCouponCode = form.watch('couponCode');

  const onSubmit = form.handleSubmit(async (fields) => {
    if (selections === undefined) {
      return logger.error('selections is undefined');
    }

    if (typeof ipp?.amount !== 'number') {
      return logger.error('ipp is not a number');
    }

    const errMsg = formatMessage({
      defaultMessage: 'Failed to confirm your payment',
      description: 'Pay for consultation page error message copy',
    });

    if (fields.shippingAddress) {
      fields.shippingAddress.country = config.country;
    }

    if (fields.residentialAddress) {
      fields.residentialAddress.country = config.country;
    }

    if (fields.birthday) {
      fields.birthday = formatLocaleDateToISO(fields.birthday);
    }

    try {
      await apollo.mutate<
        InitialPurchaseConfirmUpdateUserMutation,
        InitialPurchaseConfirmUpdateUserMutationVariables
      >({
        mutation: updateUserMutation,
        variables: {
          birthday: fields.birthday,
          firstName: fields.firstName,
          password: fields.password,
          residentialAddress: fields.residentialAddress ?? {},
          updateResidentialAddress: collectResidentialAddress,
          confirmPersonalDetails: initialPurchaseConfirmPersonalDetails,
          phone: fields.phone,
          lastName: fields.lastName,
          shippingAddress: fields.shippingAddress ?? {},
          updateShippingAddress: collectShippingAddress,
        },
        context: {
          skipErrorNotification: true,
        },
        errorPolicy: 'all',
      });
    } catch {
      notify.error({ message: errMsg });
      return;
    }

    const purchaseOfferingsInput: PurchaseOfferingsIntentHandlerInput = {
      gateway: fields.payment.method,
      offerings: selections,
      pricingSessionId,
      consultationId,
      expectedChargeAmount: ipp.amount,
      purchaseGroupId,
      source: 'CP',
      consent: false,
      onCompleteRoute: consultationConfig?.requiresScreeningQuiz
        ? routes.confirmation
        : routes.orderConfirmed,
    };

    if (coupon?.outcome === 'SUCCESS') {
      purchaseOfferingsInput.couponCode = appliedCouponCode;
    } else {
      purchaseOfferingsInput.couponCode = null;
    }

    // The user may not yet have given us a shipping address at this point
    // but we require a shipping address to purchase offerings, even if we
    // collect this at later stages.
    if (!profile?.address) {
      purchaseOfferingsInput.shippingAddress = fields.residentialAddress;
    }

    if (fields.shippingAddress) {
      purchaseOfferingsInput.shippingAddress = fields.shippingAddress;
    }

    if (fields.deliveryInstructions && purchaseOfferingsInput.shippingAddress) {
      purchaseOfferingsInput.shippingAddress.deliveryInstructions =
        fieldsToDeliveryInstructions(fields.deliveryInstructions);
    }

    const localStorageKeyForPurchaseOfferingsInput = 'purchaseOfferingsInput';
    uiStorages.local.setValue(
      localStorageKeyForPurchaseOfferingsInput,
      unicodeToBase64(JSON.stringify(purchaseOfferingsInput)),
    );

    const url = new URL(
      routes.handlePurchaseOfferingsIntent,
      window.location.origin,
    );

    url.searchParams.set(
      'purchaseOfferingsInputStorageKey',
      encodeURIComponent(localStorageKeyForPurchaseOfferingsInput),
    );

    if (
      fields.payment.method === 'STRIPE' &&
      fields.payment.paymentDetailsCompleted
    ) {
      const { error } = await stripe.confirmSetup({
        elements: stripeElements,
        confirmParams: {
          return_url: url.toString(),
        },
      });

      if (error) {
        logger.error(
          'confirmStripe handler failed during initial purchase confirm',
          {
            error,
          },
        );
        notify.error({
          message: error.message ?? errMsg,
        });
      }
    } else if (fields.payment.method === 'ZIP' && !data?.profile?.zip?.valid) {
      try {
        const resp = await apollo.mutate<
          InitialConfirmInitiateZipCheckoutMutation,
          InitialConfirmInitiateZipCheckoutMutationVariables
        >({
          mutation: initiateZipCheckoutMutation,
          variables: {
            input: {
              offeringSelections: selections,
              pricingSessionId: pricingSessionId,
              couponCodes: appliedCouponCode ? [appliedCouponCode] : undefined,
              redirectUrl: url.toString(),
            },
          },
          errorPolicy: 'all',
          context: {
            skipErrorNotification: true,
          },
        });

        if (!resp?.data?.initiateFlexiZipCheckout?.redirectUrl) {
          throw new Error('Unable to initiate zip checkout');
        }

        window.location.assign(resp.data.initiateFlexiZipCheckout.redirectUrl);
      } catch {
        notify.error({ message: errMsg });
        return;
      }
    } else if (
      (fields.payment.method === 'STRIPE' &&
        !fields.payment.paymentDetailsCompleted) ||
      (fields.payment.method === 'ZIP' && data?.profile?.zip?.valid)
    ) {
      // We're using prefilled details here so we just redirect without hitting Stripe/Zip
      history.push({
        pathname: url.pathname,
        search: url.search,
      });
      return;
    }
  });

  const shippingAddress = form.watch('shippingAddress');
  const shippingAddressFilled =
    !!shippingAddress?.postalCode &&
    !!shippingAddress?.city &&
    !!shippingAddress?.line1;
  const shippingAddressDirty = !!form.formState.dirtyFields.shippingAddress;
  const shippingAddressInRange =
    form.watch('shippingAddressProximity') === 'inRange';

  return (
    <form
      className="max-w-screen-md px-4 pb-10 md:pb-24 pt-10 md:pt-14 gap-8 mx-auto flex flex-col"
      onSubmit={onSubmit}
    >
      <Typography isBold size="lg" element="h1">
        <FormattedMessage
          defaultMessage="Complete your payment"
          description="Pay for consultation page title"
        />
      </Typography>

      {initialPurchaseContents === 'condensed' ? (
        <Card palette={palette?.programCard}>
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Your treatment"
              description="Pay for consultation page order details card title"
            />
          </Typography>

          <Divider variant="separator" />

          <div className="mb-4">
            <Typography isBold size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Prescription"
                description="Pay for consultation page RX sequence information title"
              />
            </Typography>
          </div>
          <div className="flex justify-between mb-5">
            <div className="flex gap-4">
              <img src={offering.photoUrl} className="rounded-md w-16 h-16" />
              <div>
                <div className="mb-2">
                  <Typography isBold size="sm">
                    {offering.friendlyName}
                  </Typography>
                </div>
                <div className="mb-4">
                  <Typography isBold size="medium-paragraph">
                    {offering.advertisedShippingCadence}
                  </Typography>
                </div>
                <Typography size="paragraph">
                  <FormattedMessage
                    defaultMessage="The price of your order may differ based on the treatment plan your clinician prescribes. If this is the case, we’ll organise a refund or additional payment to correct any discrepancies."
                    description="Pay for consultation page cadence disclaimer"
                  />
                </Typography>
              </div>
            </div>
            <Typography size="large-paragraph">
              <span className="whitespace-nowrap">
                {originalAmount ? (
                  <span className="text-neutral-500 mr-4 line-through">
                    {originalAmount}
                  </span>
                ) : null}
                {localisedAmount}
              </span>
            </Typography>
          </div>

          <Accordion
            palette={palette?.accordion}
            trimBottom
            title={
              <Typography isBold size="medium-paragraph">
                <FormattedMessage
                  defaultMessage="Free cancellation anytime"
                  description="Pay for consultation page accordion title"
                />
              </Typography>
            }
            content={formatMessage({
              defaultMessage:
                'You can cancel your membership at any time during the programme, no questions asked.',
              description: 'Pay for consultation page accordion body',
            })}
          />
        </Card>
      ) : (
        <Card palette={palette?.programCard}>
          <div className="space-y-4">
            <Typography isBold size="md">
              <FormattedMessage
                defaultMessage="Your program"
                description="Pay for consultation page order details card title"
              />
            </Typography>
            <div className="flex justify-between">
              <div className="flex sm:flex-row flex-col gap-4">
                <img
                  src={offering.photoUrl}
                  className="rounded-md sm:max-w-32 object-cover"
                />
                <div>
                  <div className="mb-2">
                    <Typography isBold size="sm">
                      {offering.friendlyName}
                    </Typography>
                  </div>
                  <div className="flex gap-1 items-end mb-2">
                    <Typography isBold size="xs">
                      {localisedAmount}
                    </Typography>
                    <Typography size="paragraph">
                      <FormattedMessage defaultMessage="for your first order*" />
                    </Typography>
                  </div>
                  <Typography size="paragraph">
                    <FormattedMessage defaultMessage="*If the prescribed medication is different to this amount, we'll organise a refund or additional payment to correct any discrepancies." />
                  </Typography>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              {[
                ...(offering.contents
                  ?.slice()
                  .sort((a, b) => a.rank - b.rank) || []),
                ...(offering.tags.includes('show-app-blurb')
                  ? [
                      appBlurbMessages.support.title,
                      appBlurbMessages.tools.title,
                      appBlurbMessages.content.title,
                    ]
                  : []),
              ].map((c, idx) => {
                const text =
                  'description' in c ? c.description : formatMessage(c);
                return (
                  <div key={text} className="flex flex-row items-center">
                    <div className="bg-primary-500 rounded-full w-6 h-6 flex items-center justify-center">
                      <Typography isBold size="small-text" color="#DDE2D3">
                        {idx + 1}
                      </Typography>
                    </div>
                    <div className="ml-2">
                      <Typography size="large-paragraph">{text}</Typography>
                    </div>
                  </div>
                );
              })}
            </div>
            <Card palette={palette?.nestedCard} size="sm">
              <div className="mb-2">
                <Typography size="medium-paragraph" isBold>
                  <FormattedMessage defaultMessage="A message about suitability" />
                </Typography>
              </div>
              <Typography size="paragraph">
                <FormattedMessage
                  defaultMessage="After payment, your {isGb, select, true {prescriber} other {practitioner}} will assess whether you're suitable for medication. If you're not suitable, you'll be fully refunded."
                  values={{ isGb: config.countryCode === 'GB' }}
                />
              </Typography>
            </Card>
          </div>
        </Card>
      )}

      {initialPurchaseContents === 'expanded' && (
        <div className="flex text-center justify-around gap-2 sm:gap-0">
          <div className="flex flex-col items-center space-y-2 basis-1/3 sm:basis-1/6">
            <Dollar className="stroke-current" />
            <Typography size="paragraph">
              <FormattedMessage defaultMessage="Full refund if unsuitable" />
            </Typography>
          </div>

          <div className="flex flex-col items-center space-y-2 basis-1/3 sm:basis-1/6">
            <Stop className="stroke-current" />
            <Typography size="paragraph">
              <FormattedMessage defaultMessage="No lock in contract" />
            </Typography>
          </div>
          <div className="flex flex-col items-center space-y-2 basis-1/3 sm:basis-1/6">
            <ThirtyDay className="stroke-current" />
            <Typography size="paragraph">
              <FormattedMessage defaultMessage="30-day money-back guarantee" />
            </Typography>
          </div>
        </div>
      )}

      {initialPurchaseConfirmPersonalDetails && (
        <>
          <Card palette={palette?.primaryCard}>
            <Typography isBold size="md">
              <FormattedMessage
                defaultMessage="Account details"
                description="Pay for consultation page account details card title"
              />
            </Typography>
            <Divider variant="separator" mt="sm" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <TextInput
                  ref={form.register(emailValidation)}
                  label={emailLabel}
                  name="email"
                  disabled
                />
              </div>
              <div>
                <IntlMobileNumberInput
                  countryCodes={
                    data?.validPhoneRegions?.map(
                      ({ countryCode }) => countryCode,
                    ) ?? []
                  }
                  register={form.register}
                  errorMessage={form.errors?.phone?.message}
                />
              </div>
              {!data?.profile?.isPasswordSet && (
                <>
                  <div className="col-span-1 md:col-span-2">
                    <TextInput
                      name="password"
                      ref={form.register(passwordValidation)}
                      label={passwordLabel}
                      type="password"
                      errorMessage={form.errors?.password?.message}
                    />
                  </div>
                  <div className="col-span-1 md:col-span-2">
                    <TextInput
                      name="confirmPassword"
                      ref={form.register(confirmPasswordValidation)}
                      label={confirmPasswordLabel}
                      type="password"
                      errorMessage={form.errors?.confirmPassword?.message}
                    />
                  </div>
                </>
              )}
            </div>
          </Card>

          <Card palette={palette?.primaryCard}>
            <Typography isBold size="md">
              <FormattedMessage
                defaultMessage="Personal details"
                description="Pay for consultation page personal details form title"
              />
            </Typography>
            <Divider variant="separator" mt="sm" />
            <div>
              <Typography size="medium-paragraph">
                <FormattedMessage
                  defaultMessage="To create your medical record, your prescriber will need to confirm your details and residential address is correct. <strong> Please ensure all your details below are truthful.</strong>"
                  description="Pay for consultation page personal details disclaimer"
                  values={{
                    strong: (chunks) => (
                      <strong className="font-medium">{chunks}</strong>
                    ),
                  }}
                />
              </Typography>
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <TextInput
                    ref={form.register(firstNameValidation)}
                    label={firstNameLabel}
                    name="firstName"
                    errorMessage={form.errors?.firstName?.message}
                  />
                </div>
                <div>
                  <TextInput
                    ref={form.register(lastNameValidation)}
                    label={lastNameLabel}
                    name="lastName"
                    errorMessage={form.errors?.lastName?.message}
                  />
                </div>
                <div className="col-span-1 md:col-span-2">
                  <IntlDOBInput
                    register={form.register}
                    errorMessage={form.errors?.birthday?.message}
                  />
                </div>
              </div>
            </div>
          </Card>
        </>
      )}

      {collectResidentialAddress && (
        <Card palette={palette?.primaryCard}>
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Provide your residential address"
              description="Pay for consultation page residential address form"
            />
          </Typography>
          <Divider variant="separator" mt="sm" />
          <IntlAddressInput
            name="residentialAddress"
            registerField={form.register}
            errors={form.errors.residentialAddress}
            useAutoComplete
          />
        </Card>
      )}

      {collectShippingAddress && (
        <Card palette={palette?.primaryCard}>
          <div className="space-y-3">
            <Typography isBold size="md">
              <FormattedMessage
                defaultMessage="Provide your delivery address"
                description="Pay for consultation page delivery address form"
              />
            </Typography>
            {showDeliveryConstraintCard ? (
              <div className="bg-status-warning-100 p-3 text-black flex items-start">
                <ShippingTruck className="w-8 mr-2 mt-1 min-w-4" />
                <div className="space-y-2">
                  <Typography size="paragraph" isBold inheritColor>
                    <FormattedMessage defaultMessage="Make sure we can deliver to you" />
                  </Typography>
                  <Typography size="paragraph" inheritColor>
                    <FormattedMessage defaultMessage="We can only deliver to residential addresses and easily-located business addresses. We can’t deliver to PO boxes, parcel lockers, or post offices." />
                  </Typography>
                </div>
              </div>
            ) : (
              <Divider variant="separator" mt="sm" />
            )}
            {supportShippingAddressValidation &&
              !exemptFromShippingValidation && (
                <input
                  type="hidden"
                  name="shippingAddressProximity"
                  ref={form.register({
                    validate: (v) => v === 'inRange',
                  })}
                />
              )}
            <IntlAddressInput
              className="mt-3"
              name="shippingAddress"
              onChange={(address) => {
                if (supportShippingAddressValidation) {
                  setAddressValidating(true);
                  validateShippingAddressRange(address, apollo, (p) => {
                    form.setValue('shippingAddressProximity', p);
                    setAddressValidating(false);
                  });
                }
              }}
              registerField={form.register}
              errors={form.errors.shippingAddress}
              useAutoComplete
              showSimplifiedForm={showSimplifiedShippingAddress}
            />
            {supportShippingAddressValidation &&
              !exemptFromShippingValidation &&
              shippingAddressDirty && (
                <ShippingValidationStatus
                  isResidential={false}
                  isValidatingShippingAddress={addressValidating}
                  isShippingAddressCompleted={shippingAddressFilled}
                  isShippingAddressWithinRange={shippingAddressInRange}
                />
              )}
            {showSimplifiedShippingAddress && (
              <Button
                isFullWidth
                palette={'default'}
                level="secondary"
                onClick={(): void => setShowSimplifiedShippingAddress(false)}
              >
                <FormattedMessage
                  description="Label for entering address manually"
                  defaultMessage="Enter manually"
                />
              </Button>
            )}
            {collectDeliveryInstructionsUpfront && (
              <div>
                <Divider variant="separator" />
                <DeliveryInstructions
                  registerField={form.register}
                  watch={form.watch}
                  name="deliveryInstructions"
                  errors={form.errors.deliveryInstructions ?? {}}
                />
              </div>
            )}
          </div>
        </Card>
      )}

      <Card palette={palette?.primaryCard}>
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Choose a payment method"
            description="Pay for consultation page card details form title"
          />
        </Typography>
        <Divider variant="separator" mt="sm" />
        <div className="mb-4">
          <div className="flex gap-4">
            <div className="md:w-72">
              <TextInput
                ref={form.register({
                  maxLength: {
                    value: 100,
                    message: formatMessage({
                      defaultMessage:
                        'Discount code must be less than 100 characters',
                      description:
                        'UK initial purchase page discount code validation message',
                    }),
                  },
                })}
                name="couponCode"
                label={formatMessage({
                  defaultMessage: 'Discount code',
                  description:
                    'Pay for consultation page discount code field label',
                })}
              />
            </div>
            <div className="pt-6">
              <Button
                level="secondary"
                isLoading={query.loading}
                isDisabled={
                  !formCouponCode || formCouponCode === appliedCouponCode
                }
                onClick={async () => {
                  const isValid = await form.trigger('couponCode');
                  if (!isValid) {
                    return;
                  }
                  setAppliedCouponCode(formCouponCode);
                }}
              >
                <FormattedMessage
                  defaultMessage="Apply"
                  description="Pay for consultation page apply coupon code button copy"
                />
              </Button>
            </div>
          </div>
          {(() => {
            if (query.loading) {
              return null;
            }

            let couponMessage: string | undefined;
            let showSuccess = false;
            switch (coupon?.outcome) {
              case undefined:
                break;
              case 'EXPIRED':
                couponMessage = formatMessage(
                  { defaultMessage: 'Discount code {coupon} has expired' },
                  { coupon: appliedCouponCode },
                );
                break;
              case 'GENERIC_FAILURE':
                couponMessage = formatMessage(
                  {
                    defaultMessage:
                      'Discount code {coupon} could not be applied',
                  },
                  { coupon: appliedCouponCode },
                );
                break;
              case 'NOT_FOUND':
                couponMessage = formatMessage(
                  { defaultMessage: 'Discount code {coupon} not found' },
                  { coupon: appliedCouponCode },
                );
                break;
              case 'SUCCESS':
                showSuccess = true;
                couponMessage = formatMessage(
                  { defaultMessage: 'Discount code {coupon} applied' },
                  { coupon: appliedCouponCode },
                );
                break;
            }

            if (!couponMessage) {
              return null;
            }

            return (
              <div
                className={clsx('flex gap-1 items-center mt-2', {
                  'text-status-success-500': showSuccess,
                  'text-status-error-500': !showSuccess,
                })}
              >
                {showSuccess && (
                  <CircleTickOutline className="fill-current w-4 h-4" />
                )}
                <Typography inheritColor size="small-text" isBold>
                  {couponMessage}
                </Typography>
              </div>
            );
          })()}
        </div>
        {profile && (
          <PaymentMethods
            control={form.control}
            errors={form.errors}
            fragment={profile}
            name="payment"
          />
        )}
        <div className="space-y-6 mt-6">
          {(showMarketingOptInCheckbox || showTermsAndConditionsCheckbox) && (
            <div className="space-y-4">
              {showMarketingOptInCheckbox && !marketingConsentUpdatedUI && (
                <EmailConsentCheckbox
                  profileRoute={routes.profile}
                  logger={logger}
                />
              )}
              {showTermsAndConditionsCheckbox && (
                <Checkbox
                  isChecked={false}
                  ref={form.register({
                    ...mustBeTrue(
                      formatMessage({
                        defaultMessage:
                          'To continue, you must agree to the terms of service',
                        description:
                          'Pay for consultation page terms of service required error message',
                      }),
                    ),
                  })}
                  errorMessage={form.errors?.agreedToTerms?.message}
                  name="agreedToTerms"
                  label={
                    <Typography size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="I have read and understand the <a>Terms of Service</a>, <b>Terms of Sale</b>, and <c>Privacy Notice</c>"
                        description="Pay for consultation page terms of service"
                        values={{
                          a: (chunks) => {
                            return (
                              <a
                                className="underline"
                                href={`${environment.landingPageUrl}${config.urlPaths.termsOfService}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {chunks}
                              </a>
                            );
                          },
                          b: (chunks) => (
                            <a
                              className="underline"
                              href={`${environment.landingPageUrl}${config.urlPaths.termsOfSale}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {chunks}
                            </a>
                          ),
                          c: (chunks) => (
                            <a
                              className="underline"
                              href={`${environment.landingPageUrl}${config.urlPaths.privacyNotice}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    </Typography>
                  }
                />
              )}
            </div>
          )}
          <Button
            palette={palette?.confirmButton}
            isSubmit
            isFullWidth
            isDisabled={typeof ipp?.amount !== 'number'}
            isLoading={form.formState.isSubmitting}
          >
            <FormattedMessage
              defaultMessage="{hasAmount, select, true {Confirm {amount} payment} other {Confirm payment}}"
              description="Pay for consultation page button copy"
              values={{
                amount: localisedAmount,
                hasAmount: !!localisedAmount,
              }}
            />
          </Button>
          {config.purchasePrompts.showCheckoutReassurance && (
            <div className="flex flex-col gap-2">
              {Object.entries({
                unsuitable: (
                  <FormattedMessage
                    defaultMessage="If you're found unsuitable during your consult or decide not to proceed with our program after your {isPrescriber, select, true {prescriber} other {practitioner}} call, we will refund you the amount in full"
                    values={{
                      isPrescriber:
                        config.prescriberType === PrescriberType.PRESCRIBER,
                    }}
                  />
                ),
                cancellation: (
                  <FormattedMessage defaultMessage="Free cancellation anytime" />
                ),
                guarantee: (
                  <FormattedMessage defaultMessage="30-day money-back guarantee" />
                ),
              }).map(([key, message]) => {
                return (
                  <div key={key} className="flex gap-2 text-neutral-900">
                    <CircleTickOutline className="w-4 h-4 mt-1" />
                    <div className="flex-1">
                      <Typography size="paragraph" inheritColor>
                        {message}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Card>
      {initialPurchaseFaqs === 'show' && (
        <div className="space-y-4">
          <Typography isBold size="lg">
            <FormattedMessage
              defaultMessage="FAQs"
              description="Acronym describing frequently asked questions"
            />
          </Typography>
          <UpfrontConsultPayFAQs palette={palette?.accordion} />
        </div>
      )}
      {config.purchasePrompts.showZendeskSupportCard && (
        <div className="w-full">
          <Card palette={palette?.stillHaveQuestionsCard}>
            <div className="flex flex-col space-y-6">
              <div className="flex items-center space-x-4">
                {config.localisedAssets?.zendeskMessageUsLogo && (
                  <img src={config.localisedAssets?.zendeskMessageUsLogo} />
                )}
                <Typography isBold size="md">
                  <FormattedMessage
                    defaultMessage="Still have questions?"
                    description="Initial confirm purchase page zendesk button copy"
                  />
                </Typography>
              </div>

              <Button
                onClick={() => {
                  window.open(zendeskRequestUrl, '_blank');
                }}
                isFullWidth
                palette="alternate"
              >
                <FormattedMessage defaultMessage="Message us" />
              </Button>
            </div>
          </Card>
        </div>
      )}
    </form>
  );
}

type InitialPurchaseConfirmProps = {
  consultationId: string;
  routes: {
    profile: string;
    confirmation: string;
    handlePurchaseOfferingsIntent: string;
    orderConfirmed: string;
  };
  logger: Logger;
  palette?: {
    primaryCard?: CardPalette;
    programCard?: CardPalette;
    nestedCard?: CardPalette;
    stillHaveQuestionsCard?: CardPalette;
    confirmButton?: ButtonPalette;
    accordion?: AccordionPalette;
  };
};

export function InitialPurchaseConfirm(
  props: InitialPurchaseConfirmProps,
): React.ReactElement {
  return (
    <StripeProvider api="paymentIntents" logger={props.logger}>
      <Inner {...props} />
    </StripeProvider>
  );
}

const updateUserMutation = gql`
  mutation InitialPurchaseConfirmUpdateUser(
    $firstName: String
    $lastName: String
    $phone: String
    $password: String
    $birthday: String
    $residentialAddress: ResidentialAddressCreateInput!
    $updateResidentialAddress: Boolean!
    $confirmPersonalDetails: Boolean!
    $shippingAddress: AddressCreateWithoutUserInput!
    $updateShippingAddress: Boolean!
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      password: $password
      birthday: $birthday
    ) @include(if: $confirmPersonalDetails) {
      id
      firstName
      phone
      birthday
    }
    updateResidentialAddress(residentialAddress: $residentialAddress)
      @include(if: $updateResidentialAddress) {
      id
      line1
      line2
      state
      country
      company
      building
      postalCode
      city
    }
    updateShipping(address: $shippingAddress)
      @include(if: $updateShippingAddress) {
      id
      address {
        id
        line1
        line2
        state
        country
        company
        building
        postalCode
        city
      }
    }
  }
`;

const initiateZipCheckoutMutation = gql`
  mutation InitialConfirmInitiateZipCheckout(
    $input: InitiateFlexiZipCheckoutInput!
  ) {
    initiateFlexiZipCheckout(input: $input) {
      redirectUrl
    }
  }
`;
